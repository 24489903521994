import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function CircleToggleCheckedIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={theme.palette.primary.main}
        stroke="#99B4FF"
        strokeWidth="2"
      />
      <path
        d="M16 10L10.6667 15L8 12.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
