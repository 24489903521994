import { Box, List, makeStyles, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { SIDE_BAR } from '../../../contents/DynamicConstant';
import CustomMenuItemNested from './CustomMenuItemNested';

const useStyles = makeStyles(() => ({
  listItem: {
    padding: '12px 24px'
  }
}));

export default function SideBar() {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedMenuItem, setSelectedMenuItem] = useState(() => {
    const { location } = window;
    const asPath = `${location.pathname}${location.search}`;

    if (SIDE_BAR[0].children.map(item => item.href).includes(asPath)) {
      return SIDE_BAR[0].href;
    }

    return asPath;
  });

  const handleSelectMenuItem = value => {
    setSelectedMenuItem(prevState => (prevState === value ? '' : value));
  };

  return (
    <Box style={{ borderRadius: 8, backgroundColor: theme.palette.common.white }}>
      <List component="nav" dense>
        {SIDE_BAR.map((item, index) => (
          <CustomMenuItemNested
            key={index}
            routeProps={item}
            openCollapse={selectedMenuItem === item.href}
            callback={() => {
              handleSelectMenuItem(item.href);
            }}
            listItemProps={{ className: classes.listItem }}
          />
        ))}
      </List>
    </Box>
  );
}
