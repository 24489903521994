import { ALERT_MESSAGE_DURATION_TIME } from '../../contents/Constants';
import TYPE from './TYPE';

export default {
  reorderSaleOrder: payload => ({
    type: TYPE.REORDER_SALE_ORDER,
    payload
  }),

  getListChecker: payload => ({
    type: TYPE.GET_LIST_CHECKER,
    payload
  }),

  alertMessageError: ({ message, duration = ALERT_MESSAGE_DURATION_TIME }) => ({
    type: TYPE.ALERT_MESSAGE_ERROR,
    message,
    duration
  }),

  referralCode: referral_code => ({
    type: TYPE.REFERRAL_CODE,
    referral_code
  }),

  alertMessageSuccess: ({ message, duration = ALERT_MESSAGE_DURATION_TIME }) => ({
    type: TYPE.ALERT_MESSAGE_SUCCESS,
    message,
    duration
  }),

  disableScraper: () => ({
    type: TYPE.POST_SCRAPER_COMPLETED
  }),

  alertMessageClear: () => ({
    type: TYPE.ALERT_MESSAGE_CLEAR
  }),

  exchangePoint: amount => ({
    type: TYPE.EXCHANGE_POINT,
    amount
  }),

  enableLoading: () => ({
    type: TYPE.ENABLE_LOADING
  }),

  disableLoading: () => ({
    type: TYPE.DISABLE_LOADING
  }),

  getUserProfile: () => ({
    type: TYPE.GET_USER_PROFILE
  }),

  pushToQuotationList: payload => ({
    type: TYPE.PUSH_TO_QUOTATION_LIST,
    payload
  }),

  resetQuotationList: payload => ({
    type: TYPE.RESET_QUOTATION_LIST,
    payload
  }),

  replaceQuotationList: payload => ({
    type: TYPE.REPLACE_QUOTATION_LIST,
    payload
  }),

  updateQuotationList: payload => ({
    type: TYPE.UPDATE_QUOTATION_LIST,
    payload
  }),

  checkAuth: payload => ({
    type: TYPE.CHECK_AUTH,
    payload
  }),

  blockLoginInapp: ({ block }) => ({
    type: TYPE.BLOCK_LOGIN_INAPP,
    block
  }),

  signIn: ({ access_token }) => ({
    type: TYPE.SIGN_IN,
    access_token
  }),

  getReferral: () => ({
    type: TYPE.GET_REFERRAL
  }),

  signInWithOAuth2: ({ token, provider }) => ({
    type: TYPE.SIGN_IN_WITH_OAUTH_2,
    token,
    provider
  }),

  signInOneTab: profile => ({
    type: TYPE.SIGN_IN_ONE_TAB,
    profile
  }),

  signInManual: ({ username, password }) => ({
    type: TYPE.SIGN_IN_MANUAL,
    username,
    password
  }),

  signUpManual: payload => ({
    type: TYPE.SIGN_UP_MANUAL,
    payload
  }),

  login: () => ({
    type: TYPE.LOGIN
  }),

  logout: () => ({
    type: TYPE.LOGOUT
  }),

  getCurrentSaleOrder: () => ({
    type: TYPE.GET_CURRENT_SALE_ORDER
  }),

  createSaleOrder: () => ({
    type: TYPE.CREATE_SALE_ORDER
  }),

  updateSaleOrder: quotationList => ({
    type: TYPE.UPDATE_SALE_ORDER,
    quotationList
  }),

  buynow: payload => ({
    type: TYPE.BUYNOW,
    payload
  }),

  buynowAnonymous: payload => ({
    type: TYPE.BUYNOW_ANONYMOUS,
    payload
  }),

  confirmSaleOrder: payload => ({
    type: TYPE.CONFIRM_SALE_ORDER,
    payload
  }),

  getProvince: () => ({
    type: TYPE.GET_PROVINCE
  }),

  getDistrict: provinceId => ({
    type: TYPE.GET_DISTRICT,
    provinceId
  }),

  getWard: (provinceId, districtId) => ({
    type: TYPE.GET_WARD,
    provinceId,
    districtId
  }),

  deleteQuotation: id => ({
    type: TYPE.DELETE_QUOTATION,
    id
  }),

  deleteQuotationAnonymous: id => ({
    type: TYPE.DELETE_QUOTATION_ANONYMOUS,
    id
  }),

  createNewAddress: deliveryAddress => ({
    type: TYPE.POST_CREATE_NEW_ADDRESS,
    deliveryAddress
  }),

  createNewAddressImprovement: data => ({
    type: TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT,
    data
  }),

  createDeliveryAddress: address => ({
    type: TYPE.CREATE_DELIVERY_ADDRESS,
    address
  }),

  newEditDeliveryAddress: address => ({
    type: TYPE.NEW_EDIT_DELIVERY_ADDRESS,
    address
  }),

  selectAddress: addressId => ({
    type: TYPE.PUT_SELECT_ADDRESS,
    addressId
  }),

  deleteAddress: addressId => ({
    type: TYPE.DELETE_ADDRESS,
    addressId
  }),

  getListSaleOrder: params => ({
    type: TYPE.GET_LIST_SALE_ORDER,
    params
  }),

  getSaleOrder: (saleOrderID, saleOrderType) => ({
    type: TYPE.GET_SALE_ORDER,
    saleOrderID,
    saleOrderType
  }),

  updateUserProfile: userProfile => ({
    type: TYPE.UPDATE_USER_PROFILE,
    userProfile
  }),

  selectAddressToEdit: address => ({
    type: TYPE.SELECT_ADDRESS_TO_EDIT,
    address
  }),

  notificationSaleOrder: (saleOrderId, saleOrderOdooId) => ({
    type: TYPE.NOTIFICATION_SALE_ORDER,
    saleOrderId,
    saleOrderOdooId
  }),

  notificationDeposit: data => ({
    type: TYPE.NOTIFICATION_DEPOSIT,
    data
  }),

  notificationTracking: (saleOrderOdooId, trackingId) => ({
    type: TYPE.NOTIFICATION_TRACKING,
    saleOrderOdooId,
    trackingId
  }),

  getSaleOrderDetail: id => ({
    type: TYPE.GET_SALE_ORDER_DETAIL,
    id
  }),

  searchListSaleOrder: params => ({
    type: TYPE.SEARCH_LIST_SALE_ORDER,
    params
  }),

  handleChangeLocation: (name, provinceId, districtId = '') => ({
    type: TYPE.CHANGE_LOCATION,
    name,
    provinceId,
    districtId
  }),

  updateFCMToken: token => ({
    type: TYPE.UPDATE_FMC_TOKEN,
    token
  }),

  getTrackingList: (typeTracking = 'us') => ({
    type: TYPE.TRACKING_LIST,
    typeTracking
  }),

  filterTrackingList: filter => ({
    type: TYPE.TRACKING_LIST_FILTER,
    filter
  }),

  resetDeliveryAddress: payload => ({
    type: TYPE.RESET_DELIVERY_ADDRESS,
    payload
  }),

  getTrackingDetail: (trackingId, typeTracking = 'us') => ({
    type: TYPE.TRACKING_DETAIL,
    trackingId,
    typeTracking
  }),

  validateForm: errors => ({
    type: TYPE.VALIDATE_FORM,
    errors
  }),

  clearValidate: () => ({
    type: TYPE.CLEAR_VALIDATE
  }),

  setting: payload => ({ type: TYPE.SETTING, ...payload }),

  getConfiguration: () => ({
    type: TYPE.GET_CONFIGURATION
  }),

  uploadFileQuotation: file => ({
    type: TYPE.UPLOAD_FILE_QUOTATION,
    file
  }),

  editQuotation: data => ({
    type: TYPE.EDIT_QUOTATION,
    data
  }),

  updateQuotation: (id, data) => ({
    type: TYPE.UPDATE_QUOTATION,
    id,
    data
  }),

  togglePopupDebt: payment_id => ({
    type: TYPE.TOGGLE_POPUP_DEBT,
    payment_id
  }),

  updateUserProfilePreFix: data => ({
    type: TYPE.UPDATE_USER_PROFILE_PREFIX,
    data
  }),

  verifyEmail: token => ({
    type: TYPE.VERIFY_EMAIL,
    token
  }),

  changeStep: stepLogin => ({
    type: TYPE.STEP_LOGIN,
    stepLogin
  }),

  checkSaleOrderDraft: data => ({
    type: TYPE.CHECK_SALE_ORDER_DRAFT,
    data
  }),

  // Improvement
  confirmShoppingCart: (ids, coupons) => ({
    type: TYPE.CONFIRM_SHOPPING_CART,
    ids,
    coupons
  }),

  getSelectedSaleOrder: () => ({
    type: TYPE.GET_SELECTED_SALE_ORDER
  }),

  addShoppingCartAnonymous: payload => ({
    type: TYPE.ADD_SHOPPING_CART_ANONYMOUS,
    payload
  }),

  addShoppingCart: payload => ({
    type: TYPE.ADD_SHOPPING_CART,
    payload
  }),

  editSaleOrderImprovement: (id, data, isLoading = false) => ({
    type: TYPE.EDIT_SALE_ORDER_IMPROVEMENT,
    id,
    data,
    isLoading
  }),

  editSaleOrderAnonymous: (id, data, isLoading) => ({
    type: TYPE.EDIT_SALE_ORDER_ANONYMOUS,
    id,
    data,
    isLoading
  }),

  editQuotationImprovement: (id, isGift) => ({
    type: TYPE.EDIT_QUOTATION_IMPROVEMENT,
    id,
    isGift
  }),

  isTransaction: isTrue => ({
    type: TYPE.IS_TRANSACTION,
    isTrue
  }),

  setAddressToEdit: addressID => ({
    type: TYPE.SET_ADDRESS_TO_EDIT,
    id: addressID
  }),

  editDeliveryAddress: (data, id) => ({
    type: TYPE.EDIT_DELIVERY_ADDRESS,
    data,
    id
  }),

  clearLocation: () => ({
    type: TYPE.CLEAR_LOCATION
  }),

  editAddressImprovement: data => ({
    type: TYPE.EDIT_ADDRESS_IMPROVEMENT,
    data
  }),

  updateConfiguration: () => ({
    type: TYPE.UPDATE_CONFIGURATION
  }),

  unCheckIsUserCreateNewAddress: () => ({
    type: TYPE.UN_CHECK_IS_USER_CREATE_NEW_ADDRESS
  }),

  getListWebsiteDisableUS: () => ({
    type: TYPE.GET_LIST_WEBSITE_DISABLE_US
  }),

  getListWebsiteCrawler: () => ({
    type: TYPE.GET_LIST_WEBSITE_CRAWLER
  }),

  setIdListQuotationImport: id => ({
    type: TYPE.SET_LIST_QUOTATION_IMPORT,
    id
  }),

  forgotPassword: body => ({
    type: TYPE.FORGOT_PASSWORD,
    body
  }),

  changePassword: body => ({
    type: TYPE.CHANGE_PASSWORD,
    body
  }),

  verifyEmailAndAutoLogin: payload => ({
    type: TYPE.VERIFY_EMAIL_AND_AUTO_LOGIN,
    payload
  }),

  ignoreVerifyPhone: () => ({
    type: TYPE.IGNORE_VERIFY_PHONE
  }),

  verifyPhoneSuccess: phone => ({
    type: TYPE.VERIFY_PHONE_SUCCESS,
    phone
  }),

  handleVerifyPhone: phone => ({
    type: TYPE.HANDLE_VERIFY_PHONE,
    phone
  }),

  handleVerifyPhoneFromReferral: isVerify => ({
    type: TYPE.HANDLE_VERIFY_PHONE_FROM_REFERRAL,
    isVerify
  }),

  closeExtension: () => ({
    type: TYPE.CLOSE_EXTENSION
  }),

  importQuotationFile: payload => ({
    type: TYPE.IMPORT_QUOTATION_FILE,
    payload
  }),

  getShippingCategories: () => ({
    type: TYPE.GET_SHIPPING_CATEGORIES
  }),

  clearQuotationFile: payload => ({
    type: TYPE.CLEAR_QUOTATION_FILE,
    payload
  }),

  getListPromotion: () => ({
    type: TYPE.GET_LIST_PROMOTION
  }),

  onChoosePromotion: ({ coupon, isAdd }) => ({
    type: TYPE.CHOOSE_PROMOTION,
    coupon,
    isAdd
  }),

  registerShippingService: payload => ({
    type: TYPE.REGISTER_SHIPPING_SERVICE,
    payload
  }),

  inputPromotion: code => ({
    type: TYPE.INPUT_PROMOTION,
    code
  }),

  handleChangePageDeposit: payload => ({
    type: TYPE.CHANGE_PAGE_DEPOSIT,
    payload
  }),

  handleSearchDeposit: params => ({
    type: TYPE.SEARCH_DEPOSIT,
    params
  }),

  handlePostScraper: link => ({
    type: TYPE.POST_SCRAPER,
    link
  }),

  handleCrawlData: payload => ({
    type: TYPE.CRAWL_DATA,
    payload
  }),

  couponVerifyPhone: coupon => ({
    type: TYPE.COUPON_VERIFY_PHONE,
    coupon
  }),

  getListCatalogLooking: payload => ({
    type: TYPE.GET_LIST_CATALOG_LOOKING,
    payload
  }),

  getListForYou: params => ({
    type: TYPE.GET_LIST_FOR_YOU,
    payload: params
  }),

  getListCatalogAwsPagination: page => ({
    type: TYPE.GET_LIST_CATALOG_AWS_PAGINATION,
    page
  }),

  getDetailCatalogAws: payload => ({
    type: TYPE.GET_DETAIL_CATALOG_AWS,
    payload
  }),

  clearDetailCatalog: () => ({
    type: TYPE.CLEAR_CATALOG_DETAIL
  }),

  clearDetailDeal: () => ({
    type: TYPE.CLEAR_DEAL_DETAIL
  }),

  catalogSearching: search => ({
    type: TYPE.CATALOG_SEARCHING,
    payload: search
  }),

  filterCategoryCatalog: payload => ({
    type: TYPE.FILTER_CATEGORY_CATALOG,
    payload
  }),

  clearCategoryCatalogMobile: () => ({
    type: TYPE.CLEAR_CATEGORY_CATALOG_MOBILE
  }),

  clearCategoryCatalog: () => ({
    type: TYPE.CLEAR_CATEGORY_CATALOG
  }),

  getListFlashSale: params => ({
    type: TYPE.GET_LIST_FLASH_SALE,
    payload: params
  }),

  getShoppingCartAnonymous: () => ({
    type: TYPE.GET_SHOPPING_CART_ANONYMOUS
  }),

  getCategories: params => ({
    type: TYPE.GET_CATEGORIES,
    payload: params
  }),

  getProductByCategory: params => ({
    type: TYPE.GET_PRODUCT_BY_CATEGORY,
    payload: params
  }),

  getListFavorite: payload => ({
    type: TYPE.GET_LIST_FAVORITE,
    payload
  }),

  activeFilter: () => ({
    type: TYPE.ACTIVE_FILTER
  }),

  clearFilter: () => ({
    type: TYPE.CLEAR_FILTER
  }),

  getFirstProduct: (color, sizeProduct) => ({
    type: TYPE.GET_FIRST_PRODUCT,
    color,
    sizeProduct
  }),

  accessFromMobileApp: payload => ({
    type: TYPE.ACCESS_FROM_MOBILE_APP,
    payload
  }),

  accessFromMobileAppDN: payload => ({
    type: TYPE.ACCESS_FROM_MOBILE_APP_DN,
    payload
  }),

  changeVariantProduct: (color, size) => ({
    type: TYPE.CHANGE_VARIANT_PRODUCT,
    color,
    size
  }),

  filterOrderDealManagement: data => ({
    type: TYPE.FILTER_ORDER_DEAL_MANAGEMENT,
    data
  }),

  socketUpdateShoppingCartAnonymous: payload => ({
    type: TYPE.SOCKET_UPDATE_SHOPPING_CART_ANONYMOUS,
    payload
  }),

  putToLoggedCart: anonymous_id => ({
    type: TYPE.PUT_TO_LOGGED_CART,
    anonymous_id
  }),

  getDetailOrderMangement: id => ({
    type: TYPE.GET_DETAIL_ORDER_MANAGEMENT,
    id
  }),

  getOrderPurchaseReview: payload => ({
    type: TYPE.GET_ORDER_PURCHASE_REVIEW,
    payload
  }),

  clearDetailOrderManagement: () => ({ type: TYPE.CLEAR_DETAIL_ORDER_MANAGEMENT }),

  getDetailTracking: SOId => ({
    type: TYPE.GET_DETAIL_TRACKING,
    id: SOId
  }),

  getTrackingJourney: (id, type) => ({
    type: TYPE.GET_TRACKING_JOURNEY,
    payload: { id, type }
  }),

  getTrackingJourneyByOrder: payload => ({
    type: TYPE.GET_TRACKING_JOURNEY_BY_ORDER,
    payload
  }),

  getListVNTracking: () => ({
    type: TYPE.GET_LIST_VN_TRACKING
  }),

  getDetailVNTracking: id => ({
    type: TYPE.GET_DETAIL_VN_TRACKING,
    payload: id
  }),

  handlePaymentVNTracking: payload => ({
    type: TYPE.PAYMENT_VN_TRACKING,
    payload
  }),

  confirmVNTracking: payload => ({
    type: TYPE.CONFIRM_VN_TRACKING,
    payload
  }),

  detailConfirmVNTracking: payload => ({
    type: TYPE.DETAIL_CONFIRM_VN_TRACKING,
    payload
  }),

  getTransferRequest: () => ({
    type: TYPE.GET_TRANSFER_REQUEST
  }),

  getNotification: () => ({
    type: TYPE.GET_NOTIFICATION
  }),

  getListBank: (option, callback) => ({
    type: TYPE.GET_LIST_BANK,
    payload: option,
    callback
  }),

  getPromotion: payload => ({
    type: TYPE.GET_PROMOTION,
    payload
  }),

  createNewPromotion: (payload, callback) => ({
    type: TYPE.CREATE_NEW_PROMOTION,
    payload,
    callback
  }),

  deletePromotion: (payload, callback) => ({
    type: TYPE.DELETE_PROMOTION,
    payload,
    callback
  }),

  getCategoryList: () => ({
    type: TYPE.GET_CATEGORY_LIST
  }),

  syncListBank: option => ({
    type: TYPE.SYNC_LIST_BANK,
    payload: option
  }),

  markAsReadNotification: payload => ({
    type: TYPE.MARK_AS_READ_NOTIFICATION,
    payload
  }),

  receiveNotification: payload => ({
    type: TYPE.RECEIVE_NOTIFICATION,
    payload
  }),

  clearReceiveNotification: () => ({
    type: TYPE.CLEAR_RECEIVE_NOTIFICATION
  }),

  getCategoryDetect: payload => ({
    type: TYPE.GET_CATEGORY_DETECT,
    payload
  }),

  getListHistoryMoneyRequest: payload => ({
    type: TYPE.GET_LIST_HISTORY_MONEY_REQUEST,
    payload
  }),

  sendMoneyTranferRequest: payload => ({
    type: TYPE.SEND_MONEY_TRANFER_REQUEST,
    payload
  }),

  resetBank: payload => ({
    type: TYPE.RESET_BANK,
    payload
  }),

  sendMoneyWithdrawRequest: payload => ({
    type: TYPE.SEND_MONEY_WITHDRAW_REQUEST,
    payload
  }),

  clearMoneyRequest: () => ({
    type: TYPE.CLEAR_MONEY_REQUEST
  }),

  changePaymentOption: payload => ({
    type: TYPE.CHANGE_PAYMENT_OPTION,
    payload
  }),

  resetPaymentOption: () => ({
    type: TYPE.RESET_PAYMENT_OPTION
  }),

  getListProductDealHome: payload => ({
    type: TYPE.GET_LIST_PRODUCT_DEAL_HOME,
    payload
  }),

  saveBackupProductCatalog: payload => ({
    type: TYPE.SAVE_BACKUP_PRODUCT_CATALOG,
    payload
  }),

  saveBackupDealCatalog: payload => ({
    type: TYPE.SAVE_BACKUP_DEAL_CATALOG,
    payload
  }),

  handleChangeFilter: payload => ({
    type: TYPE.HANDLE_CHANGE_FILTER,
    payload
  }),

  handleFilterCatalog: payload => ({
    type: TYPE.HANDLE_FILTER_CATALOG,
    payload
  }),

  clearDetailDealOrder: () => ({
    type: TYPE.CLEAR_DETAIL_DEAL_ORDER
  }),

  AddDealQuotation: data => ({
    type: TYPE.ADD_DEAL_QUOTATION,
    data
  }),

  clearDealQuotation: () => ({
    type: TYPE.CLEAR_DEAL_QUOTATION
  }),

  getListTransaction: () => ({
    type: TYPE.GET_LIST_TRANSACTION
  }),

  getDetailTransaction: payload => ({
    type: TYPE.GET_DETAIL_TRANSACTION,
    payload
  }),

  clearDetailTransaction: () => ({
    type: TYPE.CLEAR_DETAIL_TRANSACTION
  }),

  clearGiftCardUpdate: () => ({
    type: TYPE.CLEAR_GIFT_CARD_UPDATE
  }),

  ClearDealPurchaseReject: () => ({
    type: TYPE.CLEAR_DEAL_PURCHASE_REJECT
  }),

  updateWaitingSaleOrder: payload => ({
    type: TYPE.UPDATE_WAITING_SALE_ORDER,
    payload
  }),

  clearFindPromotion: () => ({
    type: TYPE.CLEAR_FIND_PROMOTION
  }),

  checkLoginProvider: () => ({
    type: TYPE.CHECK_LOGIN_PROVIDER
  }),

  changeUserPassword: payload => ({
    type: TYPE.CHANGE_USER_PASSWORD,
    payload
  }),

  getDebtRecovery: payload => ({
    type: TYPE.DEBT_RECOVERY,
    payload
  }),

  deleteSelectedCartItems: payload => ({
    type: TYPE.DELETE_SELECTED_CART_ITEMS,
    payload
  }),

  deleteSelectedAnonymousCartItems: payload => ({
    type: TYPE.DELETE_SELECTED_ANONYMOUS_CART_ITEMS,
    payload
  }),

  getDealDetail: payload => ({
    type: TYPE.GET_DEAL_DETAIL,
    payload
  })
};
