import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function OrderingIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={theme.palette.primary.main} />
      <path
        d="M12.3819 7.66406C11.718 7.66406 11.1122 8.06336 10.8503 8.67318L8.78329 13.4974H7.66838C7.40754 13.4974 7.16265 13.6187 7.00431 13.8262C6.84765 14.0328 6.79438 14.3012 6.86271 14.5521L8.9965 22.2734C9.19567 22.9934 9.85628 23.4974 10.6029 23.4974H21.3972C22.1439 23.4974 22.8037 22.9942 23.002 22.2751L25.1358 14.5537C25.205 14.3029 25.1525 14.0328 24.9959 13.8262C24.8375 13.6187 24.5918 13.4974 24.3318 13.4974H23.2169L21.1482 8.6748H21.1498C20.8884 8.06216 20.2805 7.66406 19.6166 7.66406H12.3819ZM12.3819 9.33073H19.6166L21.4021 13.4974H10.5981L12.3819 9.33073ZM12.6667 15.9974C13.1267 15.9974 13.5001 16.3707 13.5001 16.8307V20.1641C13.5001 20.6241 13.1267 20.9974 12.6667 20.9974C12.2067 20.9974 11.8334 20.6241 11.8334 20.1641V16.8307C11.8334 16.3707 12.2067 15.9974 12.6667 15.9974ZM16.0001 15.9974C16.4601 15.9974 16.8334 16.3707 16.8334 16.8307V20.1641C16.8334 20.6241 16.4601 20.9974 16.0001 20.9974C15.5401 20.9974 15.1667 20.6241 15.1667 20.1641V16.8307C15.1667 16.3707 15.5401 15.9974 16.0001 15.9974ZM19.3334 15.9974C19.7934 15.9974 20.1667 16.3707 20.1667 16.8307V20.1641C20.1667 20.6241 19.7934 20.9974 19.3334 20.9974C18.8734 20.9974 18.5001 20.6241 18.5001 20.1641V16.8307C18.5001 16.3707 18.8734 15.9974 19.3334 15.9974Z"
        fill="white"
      />
    </SvgIcon>
  );
}
