import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useGetParamsLookingCatalog from '../../../hooks/2.2.0/useGetParamsLookingCatalog';

const useStyles = makeStyles(theme => ({
  search: {
    '& .MuiInputBase-root': {
      borderRadius: 100,
      minWidth: 280,
      backgroundColor: '#F1F5F9',

      '& input': {
        fontSize: theme.spacing(1.5),
        fontWeight: theme.spacing(50),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(1.75)
        }
      },
      '& fieldset': {
        border: 'none',
        color: '#86878F'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-root': {
        minWidth: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      left: 0,
      top: 0,
      width: '100%',
      transform: 'none'
    }
  }
}));

export default function SearchInput() {
  const classes = useStyles();
  const { keyword } = useGetParamsLookingCatalog();
  const [searchStr, setSearchStr] = useState(keyword);

  const handleChangeInput = event => setSearchStr(event.target.value);
  const handleSearch = () => {
    if (!searchStr) return;
    window.location.href = `/search?q=${searchStr}`;
  };
  const onKeywordChange = event => {
    const ENTER_KEY = 13;
    if (event.charCode !== ENTER_KEY) return;
    handleSearch();
  };

  useEffect(() => {
    setSearchStr(keyword);
  }, [keyword]);

  return (
    <TextField
      className={classes.search}
      variant="outlined"
      placeholder="Tìm kiếm"
      size="small"
      onKeyPress={onKeywordChange}
      fullWidth
      onChange={handleChangeInput}
      value={searchStr}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="primary"
              component="span"
              onClick={handleSearch}
              style={{ padding: '0' }}
            >
              <SearchIcon color="action" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
