/* eslint-disable react/prop-types */
// import defaultAvatar from '@/assets/images/default-avatar.png';
// import ChevronRightIcon from '@/components/icons/ChevronRightIcon';
// import { PATHS } from '@/contents/constants';
// import useCheckAuth from '@/hooks/useCheckAuth';
// import { useAppSelector } from '@/store/hooks';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Path } from '../../../../contents/Constants';
import useCheckAuth from '../../../../hooks/useCheckAuth';
import ChevronRightIcon from '../../Icons/ChevronRightIcon';

const useStyles = makeStyles({
  button: {
    padding: '12px 16px',
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(222, 222, 222, 0.16) 0px 2px 16px',
    '& .MuiButton-startIcon': {
      marginLeft: 0
    }
  }
});

export default function UserInfo({ onClose }) {
  const theme = useTheme();
  const classes = useStyles();
  const isLogged = useCheckAuth();
  const profile = useSelector(state => state.profile);
  const avatar = profile?.avatar_url;
  const name = profile?.name;
  const label1 = isLogged ? 'Xin chào,' : 'Nhận nhiều ưu đãi hơn khi';
  const label2 = name || 'Đăng nhập/Đăng ký';

  const handleClick = event => {
    onClose(event);
    window.location.href = isLogged ? Path.USER_PROFILE : Path.SIGN_IN;
  };

  return (
    <Button
      variant="outlined"
      fullWidth
      style={{
        background: isLogged
          ? 'linear-gradient(122.15deg, rgb(240, 241, 245) 41.49%, rgb(248, 248, 250) 86.43%)'
          : 'linear-gradient(rgb(29, 80, 255) 0%, rgb(29, 146, 255) 100%)'
      }}
      onClick={handleClick}
      className={classes.button}
      startIcon={
        isLogged && avatar ? (
          <Avatar src={avatar} />
        ) : (
          <img
            src="/images/gif/avatar.gif"
            style={{
              height: 40,
              width: 40,
              backgroundColor: theme.palette.common.white,
              borderRadius: '50%'
            }}
            alt="AVT"
          />
        )
      }
      endIcon={
        <ChevronRightIcon
          {...(!isLogged && { htmlColor: theme.palette.common.white })}
        />
      }
    >
      <Grid container direction="column">
        <Grid item>
          <Typography
            style={{
              fontSize: 10,
              color: isLogged
                ? theme.palette.common.black
                : theme.palette.common.white
            }}
            align="left"
          >
            {label1}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 700,
              color: isLogged
                ? theme.palette.common.black
                : theme.palette.common.white
            }}
            align="left"
          >
            {label2}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
}
