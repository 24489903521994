import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function OrderIconContained(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M11 15V19C11 20.1 10.1 21 9 21H5C3.9 21 3 20.1 3 19V15C3 13.9 3.9 13 5 13H9C10.1 13 11 13.9 11 15Z" />
      <path d="M11 5V9C11 10.1 10.1 11 9 11H5C3.9 11 3 10.1 3 9V5C3 3.9 3.9 3 5 3H9C10.1 3 11 3.9 11 5Z" />
      <path d="M21 5V9C21 10.1 20.1 11 19 11H15C13.9 11 13 10.1 13 9V5C13 3.9 13.9 3 15 3H19C20.1 3 21 3.9 21 5Z" />
      <path d="M21 17C21 17.55 20.55 18 20 18H18V20C18 20.55 17.55 21 17 21C16.45 21 16 20.55 16 20V18H14C13.45 18 13 17.55 13 17C13 16.45 13.45 16 14 16H16V14C16 13.45 16.45 13 17 13C17.55 13 18 13.45 18 14V16H20C20.55 16 21 16.45 21 17Z" />
    </SvgIcon>
  );
}
