import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { LOCATION_STATE } from '../../contents/Constants';
import services from '../../services';
import { generateCheckPriceLoadingType } from '../../utils/helpers';
import TYPE from '../actions/TYPE';
import { allSettled } from '../../utils/customSagaEffects';

function* preventActionWhenInvalidUser() {
  const isVerifyEmail = yield select(state => state.profile?.is_email_verify);
  return isVerifyEmail === false;
}

function* getLastPrice({ type, payload }) {
  const isPreventAction = yield preventActionWhenInvalidUser();
  if (isPreventAction) return payload;

  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: generateCheckPriceLoadingType(type)
    });
    const requestData = (payload || []).map(item => ({
      link: item.url,
      image: item?.image,
      name: item.name,
      color: item?.attributes?.color,
      size: item?.attributes?.size,
      location: Object.values(LOCATION_STATE).find(
        i => i.currencyInfo.value === item?.currency
      ).value,
      quantity: 1,
      price: item.price,
      sale_price: 0,
      sale_price_type: '%',
      note: '',
      notice: '',
      weight: item?.category_obj?.min_weight || 0,
      category: item?.category_obj
    }));
    const { data } = yield call(services.catalog.checkPriceProduct, {
      items: requestData
    });
    yield put({
      type: TYPE.CHECK_PRICE_PRODUCT_SUCCESS,
      data
    });
    const newData = (payload || []).map((item, index) => ({
      ...item,
      price_vnd: data[index].price_after_sale_VND
    }));
    return newData;
  } catch (error) {
    yield put({
      type: TYPE.CHECK_PRICE_PRODUCT_FAILED,
      error
    });
    return payload;
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: generateCheckPriceLoadingType(type)
    });
  }
}

function* checkPriceDetailProduct() {
  const listVariant = yield select(state => state.catalog.detail.original);
  try {
    const dataWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_DETAIL_PRODUCT,
      payload: listVariant
    });
    const defaultDetail = yield select(state => state.catalog.detail.defaultDetail);
    yield put({
      type: TYPE.GET_DETAIL_CATALOG_AWS_SUCCESS,
      payload: { ...defaultDetail, variants: dataWithLastPrice }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceRelativeProduct() {
  try {
    const relativeProduct = yield select(state =>
      (state.catalog.detail.selected?.related_products || []).slice(0, 8)
    );
    const dataWithLastPriceRelativeProduct = yield getLastPrice({
      type: TYPE.CHECK_PRICE_RELATIVE_PRODUCT,
      payload: relativeProduct
    });
    const selectedVariant = yield select(state => state.catalog.detail.selected);
    yield put({
      type: TYPE.GET_DETAIL_CATALOG_AWS_SUCCESS,
      payload: {
        ...selectedVariant,
        related_products: dataWithLastPriceRelativeProduct
      }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceFavoriteProduct() {
  try {
    const favoriteProduct = yield select(state =>
      (state.catalog.detail.selected?.favorite_products || []).slice(0, 10)
    );
    const dataWithLastPriceFavoriteProduct = yield getLastPrice({
      type: TYPE.CHECK_PRICE_FAVORITE_PRODUCT,
      payload: favoriteProduct
    });
    const selectedVariant = yield select(state => state.catalog.detail.selected);
    yield put({
      type: TYPE.GET_DETAIL_CATALOG_AWS_SUCCESS,
      payload: {
        ...selectedVariant,
        favorite_products: dataWithLastPriceFavoriteProduct
      }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceListFavoriteProduct() {
  try {
    const favoriteProduct = yield select(state => state.catalog.favorite.list);
    const dataWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_LIST_FAVORITE_PRODUCT,
      payload: favoriteProduct
    });
    const pagination = yield select(state => state.catalog.favorite.page);
    yield put({
      type: TYPE.GET_LIST_FAVORITE_SUCCESS,
      payload: {
        ...pagination,
        list: dataWithLastPrice
      }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceFlashSaleProduct() {
  try {
    const listFlashSale = yield select(state => state.catalog.flashSale.list);
    const listProductWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_FLASH_SALE_PRODUCT,
      payload: listFlashSale
    });
    const paginationFlashSale = yield select(state => state.catalog.flashSale.page);
    yield put({
      type: TYPE.GET_LIST_FLASH_SALE_SUCCESS,
      payload: { ...paginationFlashSale, list: listProductWithLastPrice }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceForYouProduct() {
  try {
    const listForYou = yield select(state => state.catalog.forYou.list);
    const listProductWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_FOR_YOU_PRODUCT,
      payload: listForYou
    });
    const paginationForYou = yield select(state => state.catalog.forYou.page);
    yield put({
      type: TYPE.GET_LIST_FOR_YOU_SUCCESS,
      payload: listProductWithLastPrice,
      page: paginationForYou
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceLookingProduct() {
  try {
    const listLooking = yield select(state => state.catalog.looking.list);
    const listProductWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_LOOKING_PRODUCT,
      payload: listLooking
    });
    const paginationLooking = yield select(state => state.catalog.looking.page);
    yield put({
      type: TYPE.GET_LIST_CATALOG_LOOKING_SUCCESS,
      payload: { ...paginationLooking, list: listProductWithLastPrice }
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceCategoryProduct() {
  try {
    const listCategory = yield select(state => state.catalog.category.list);
    const listProductWithLastPrice = yield getLastPrice({
      type: TYPE.CHECK_PRICE_CATEGORY_PRODUCT,
      payload: listCategory
    });
    const paginationCategory = yield select(state => state.catalog.category?.page);
    const categoryId = yield select(state => state.catalog.category?.category_id);
    yield put({
      type: TYPE.GET_PRODUCT_BY_CATEGORY_SUCCESS,
      data: listProductWithLastPrice,
      category_id: categoryId,
      page: paginationCategory
    });
  } catch (error) {
    throw new Error(error);
  }
}

function* checkPriceHomeProduct() {
  try {
    const listCategoryHome = yield select(
      state => state.catalog.productDealHome || []
    );
    const listCheckPrice = listCategoryHome.map(category => {
      return getLastPrice({
        type: TYPE.CHECK_PRICE_CATEGORY_PRODUCT,
        payload: category.list
      });
    });
    const listProductWithLastPrice = yield allSettled(listCheckPrice);
    const payload = listProductWithLastPrice.map((item, index) => {
      const isError = Boolean(item?.reason);
      const originalCategoryId = listCategoryHome?.[index]?.category_id;
      const originalList = listCategoryHome?.[index]?.list;
      const newList = isError ? originalList : item?.value;
      return {
        category_id: originalCategoryId,
        list: newList,
        filter: newList
      };
    });
    yield put({ type: TYPE.GET_LIST_PRODUCT_DEAL_HOME_SUCCESS, payload });
  } catch (error) {
    throw new Error(error);
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.CHECK_PRICE_DETAIL_PRODUCT, checkPriceDetailProduct),
    takeLatest(
      TYPE.CHECK_PRICE_LIST_FAVORITE_PRODUCT,
      checkPriceListFavoriteProduct
    ),
    takeLatest(TYPE.CHECK_PRICE_RELATIVE_PRODUCT, checkPriceRelativeProduct),
    takeLatest(TYPE.CHECK_PRICE_FAVORITE_PRODUCT, checkPriceFavoriteProduct),
    takeLatest(TYPE.CHECK_PRICE_FLASH_SALE_PRODUCT, checkPriceFlashSaleProduct),
    takeLatest(TYPE.CHECK_PRICE_FOR_YOU_PRODUCT, checkPriceForYouProduct),
    takeLatest(TYPE.CHECK_PRICE_HOME_PRODUCT, checkPriceHomeProduct),
    takeLatest(TYPE.CHECK_PRICE_LOOKING_PRODUCT, checkPriceLookingProduct),
    takeLatest(TYPE.CHECK_PRICE_CATEGORY_PRODUCT, checkPriceCategoryProduct)
  ]);
}
