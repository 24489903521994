import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function AirplaneIcon(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M7.52503 18.0758L9.4667 16.4424C9.75837 16.1924 10.2417 16.1924 10.5334 16.4424L12.475 18.0758C12.925 18.3008 13.475 18.0758 13.6417 17.5925L14.0084 16.4841C14.1 16.2175 14.0084 15.8258 13.8084 15.6258L11.9167 13.7258C11.775 13.5924 11.6667 13.3258 11.6667 13.1341V10.7591C11.6667 10.4091 11.925 10.2424 12.25 10.3758L16.3417 12.1424C16.9834 12.4174 17.5084 12.0758 17.5084 11.3758V10.3008C17.5084 9.74245 17.0917 9.10078 16.575 8.88412L11.9167 6.87578C11.7834 6.81745 11.6667 6.64245 11.6667 6.49245V3.99245C11.6667 3.20911 11.0917 2.28411 10.3917 1.92578C10.1417 1.80078 9.85003 1.80078 9.60003 1.92578C8.90003 2.28411 8.32503 3.21745 8.32503 4.00078V6.50078C8.32503 6.65078 8.20837 6.82578 8.07503 6.88412L3.42503 8.89245C2.90837 9.10078 2.4917 9.74245 2.4917 10.3008V11.3758C2.4917 12.0758 3.0167 12.4174 3.65837 12.1424L7.75003 10.3758C8.0667 10.2341 8.33337 10.4091 8.33337 10.7591V13.1341C8.33337 13.3258 8.22503 13.5924 8.0917 13.7258L6.20003 15.6258C6.00003 15.8258 5.90837 16.2091 6.00003 16.4841L6.3667 17.5925C6.5167 18.0758 7.0667 18.3091 7.52503 18.0758Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
