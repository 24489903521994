import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { ACCESS_KEY, Path } from '../../../contents/Constants';
import PopupButton from '../Common/PopupButton';
import MenuLogin from './MenuLogin';
import PopupLogin from './PopupLogin';
import UserProfile from './UserProfile';

const useStyles = makeStyles(theme => ({
  mr2: {
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: 8
    }
  },
  button: {
    borderRadius: 24,
    fontWeight: 700,
    minWidth: 103
  },
  whiteButton: {
    backgroundColor: 'transparent',
    minWidth: 121,
    borderColor: 'rgba(0, 0, 0, 0.23)'
  },
  profile: {
    maxWidth: 155,
    '&:hover #expand-icon': {
      transform: 'rotate(180deg)'
    }
  }
}));

export default function ProfileButton() {
  const classes = useStyles();
  const isAuth = localStorage.getItem(ACCESS_KEY);
  const loading = useSelector(
    state => !!state.common.loadingImprove?.getUserProfile
  );
  const ProfileButtonBody = () => {
    if (loading) return null;
    if (isAuth) return <MenuLogin />;
    return <PopupLogin />;
  };

  if (!isAuth) {
    return (
      <>
        <Button
          href={Path.SIGN_UP}
          disableElevation
          color="primary"
          variant="contained"
          className={clsx(classes.mr2, classes.button)}
        >
          Đăng ký
        </Button>
        <Button
          href={Path.SIGN_IN}
          disableElevation
          variant="outlined"
          className={clsx(classes.button, classes.whiteButton)}
        >
          Đăng nhập
        </Button>
      </>
    );
  }

  return (
    <PopupButton className={classes.profile} button={<UserProfile />} popover>
      <ProfileButtonBody />
    </PopupButton>
  );
}
