import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Path, SHOPPING_STEPS } from '../../../contents/Constants';
import useResponsive from '../../../hooks/useResponsive';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { BuyNgonContainer } from '../Common';
import ArrowIcon from '../Icons/Improve/ArrowIcon';
import MainLogoImage from '../Icons/MainLogoImage';
import HeaderStepper from './HeaderStepper';
import NavTab from './NavTab';
import NotificationButton from './NotificationButton';
import ProfileButton from './ProfileButton';
import ProfileMenu from './ProfileMenu';
import SearchInput from './SearchInput';
import ShoppingCartButton from './ShoppingCartButton';

const useStyles = makeStyles(theme => ({
  sticky: {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    boxShadow: '0px 16px 48px 0px #BCC0CC52'
  },
  containerDesktop: {
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    height: 48
  },
  mr2: {
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: 8
    }
  },
  logo: {
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  nav: {
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0)
    }
  },
  boxLogo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center'
  },
  middleSection: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 387px - 277px - 80px)'
  },
  rightSection: {
    display: 'flex',
    height: 1,
    alignItems: 'center',
    width: 277,
    justifyContent: 'flex-end'
  },
  arrow: {
    position: 'absolute',
    left: 16,
    transform: 'translateX(-12px)'
  },
  stepper: {
    width: '70%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}));

function checkPathShopping(pathname) {
  return [Path.SHOPPING_CART, Path.SHOPPING_ORDER, Path.SHOPPING_COMPLETE].includes(
    pathname
  );
}

export default function Navigation() {
  const classes = useStyles();
  const matches = useResponsive();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const onBackHome = () => {
    if (pathname !== Path.HOME) {
      window.location.href = Path.HOME;
    }
  };

  const isAuth = useSelector(state => !!state.profile?._id);

  useEffect(() => {
    if (isAuth) dispatch(actions.getNotification());
  }, [isAuth]);

  const isShoppingTransactionRoute = checkPathShopping(pathname);

  const isEmptyRoute = [Path.SIGN_IN, Path.SIGN_UP].some(path =>
    pathname.startsWith(path)
  );

  const handleGoBack = () => {
    if (pathname.includes(Path.SHOPPING_ORDER)) {
      history.push(`${Path.SHOPPING_CART}${search}`);
      return;
    }
    if (pathname.includes(Path.SHOPPING_CART)) {
      window.location.href = Path.HOME;
      return;
    }
    history.goBack();
  };

  if (isEmptyRoute) return null;

  if (matches) {
    const isEmptyMobileRoute = [
      Path.SIGN_IN,
      Path.SIGN_UP,
      Path.NOTIFICATION,
      Path.VN_TRACKING_DEFAULT_PATH,
      Path.ORDER_MANAGEMENT_DEFAULT_PATH
    ].some(path => pathname.startsWith(path));

    if (isEmptyMobileRoute) return null;

    if (isShoppingTransactionRoute) {
      return (
        <Box className={clsx(classes.container, classes.sticky)}>
          <Box height={48} />
          <IconButton onClick={handleGoBack} className={classes.arrow}>
            <ArrowIcon />
          </IconButton>

          <Box className={classes.stepper}>
            <HeaderStepper steps={SHOPPING_STEPS} />
          </Box>
        </Box>
      );
    }

    return (
      <Box className={clsx(classes.container, classes.sticky)}>
        <Box
          display="flex"
          width={72}
          justifyContent="space-between"
          alignItems="center"
        >
          <ProfileMenu />

          <svg
            onClick={onBackHome}
            width={32}
            height={32}
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.953125" width="32" height="32" rx="16" fill="#1D50FF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.83403 10.4474C8.31176 11.1117 8.00024 11.9495 8.00024 12.8601V21.0462C8.00024 23.204 9.74946 24.9532 11.9072 24.9532H20.0933C22.2511 24.9532 24.0003 23.204 24.0003 21.0462V12.8601C24.0003 10.7023 22.2511 8.95312 20.0933 8.95312H11.9072C11.8484 8.95312 11.7899 8.95442 11.7318 8.95699C12.041 9.35243 12.2921 9.80013 12.4699 10.2895L14.1177 14.8246C14.454 15.7505 15.3338 16.3668 16.3189 16.3668C17.2219 16.3668 17.954 15.6348 17.954 14.7317V14.6665C17.954 13.8128 17.2619 13.1208 16.4082 13.1208V10.0293C18.9693 10.0293 21.0454 12.1055 21.0454 14.6665V14.7317C21.0454 17.3421 18.9293 19.4583 16.3189 19.4583C14.0335 19.4583 11.9925 18.0283 11.212 15.8803L9.5643 11.3453C9.42555 10.9634 9.16407 10.6514 8.83403 10.4474ZM14.4985 21.8351V22.4603C16.4416 22.8724 18.4809 22.5987 19.9842 21.6366C20.2636 21.9102 20.6462 22.0788 21.0681 22.0788C21.9239 22.0788 22.6177 21.385 22.6177 20.5292C22.6177 19.6733 21.9239 18.9796 21.0681 18.9796C20.2123 18.9796 19.5185 19.6733 19.5185 20.5292C19.5185 20.7425 19.5616 20.9457 19.6396 21.1307C18.2687 21.9999 16.3585 22.2608 14.4985 21.8351ZM10.9844 22.0788C11.8402 22.0788 12.534 21.385 12.534 20.5292C12.534 19.6733 11.8402 18.9796 10.9844 18.9796C10.1286 18.9796 9.43476 19.6733 9.43476 20.5292C9.43476 21.385 10.1286 22.0788 10.9844 22.0788Z"
              fill="#F2F4F7"
            />
          </svg>
        </Box>

        <Box width="calc(100% - 144px - 16px)">
          <SearchInput />
        </Box>

        <Box display="flex" width={72} justifyContent="space-between">
          <NotificationButton />

          <ShoppingCartButton />
        </Box>
      </Box>
    );
  }

  if (isShoppingTransactionRoute) {
    return (
      <Box className={classes.sticky}>
        <BuyNgonContainer className={classes.containerDesktop}>
          <a
            href={Path.HOME}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <MainLogoImage />
          </a>

          <Box display="flex" alignItems="center" width="40%">
            <HeaderStepper steps={SHOPPING_STEPS} />
          </Box>

          <Box display="flex" height={1} alignItems="center">
            <ProfileButton />
          </Box>
        </BuyNgonContainer>
      </Box>
    );
  }

  return (
    <Box className={classes.sticky}>
      <BuyNgonContainer className={classes.containerDesktop}>
        <Box display="flex" alignItems="center">
          <a
            href={Path.HOME}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <MainLogoImage />
          </a>

          <NavTab />
        </Box>

        <Box display="flex" alignItems="center">
          <div className={classes.mr2}>
            <SearchInput />
          </div>

          <div className={classes.mr2}>
            <NotificationButton />
          </div>
          <div className={classes.mr2}>
            <ShoppingCartButton />
          </div>

          <ProfileButton />
        </Box>
      </BuyNgonContainer>
    </Box>
  );
}
