import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BoxTimeIcon(props) {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M3.29785 6.19922L10.6562 10.4575L17.9645 6.22419"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6562 18.0076V10.4492"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6646 7.64102V12.3577C18.6646 12.3994 18.6646 12.4327 18.6563 12.4743C18.0729 11.966 17.3229 11.666 16.4896 11.666C15.7063 11.666 14.9813 11.941 14.4063 12.3994C13.6396 13.0077 13.1563 13.9494 13.1563 14.9994C13.1563 15.6244 13.3313 16.216 13.6396 16.716C13.7146 16.8494 13.8063 16.9744 13.9063 17.091L12.3813 17.9327C11.4313 18.466 9.88126 18.466 8.93126 17.9327L4.48127 15.466C3.47293 14.9077 2.64795 13.5077 2.64795 12.3577V7.64102C2.64795 6.49102 3.47293 5.09103 4.48127 4.5327L8.93126 2.06602C9.88126 1.53268 11.4313 1.53268 12.3813 2.06602L16.8313 4.5327C17.8396 5.09103 18.6646 6.49102 18.6646 7.64102Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8229 14.9993C19.8229 15.9993 19.3813 16.891 18.6896 17.4993C18.0979 18.016 17.3312 18.3327 16.4896 18.3327C14.6479 18.3327 13.1562 16.841 13.1562 14.9993C13.1562 13.9493 13.6396 13.0077 14.4062 12.3994C14.9812 11.941 15.7062 11.666 16.4896 11.666C18.3312 11.666 19.8229 13.1577 19.8229 14.9993Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6979 13.957V15.207L15.6562 15.832"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
