import React, { lazy, Suspense } from 'react';
import SkeletonUserInfoLayout from '../components/Skeleton/SkeletonUserInfoLayout';
import SkeletonVNTracking from '../components/Skeleton/SkeletonVNTracking';
import { Path } from '../contents/Constants';

import NothingLayout from '../layouts/NothingLayout';
import OutSideLayout from '../layouts/OutSideLayout';
import MainLayout from '../layouts/MainLayout';
import UserInfoLayout from '../layouts/UserInfoLayout';

const CatalogDetail = lazy(() => import('../pages/CatalogDetail'));
const Quotation = lazy(() => import('../pages/Quotation'));
const QuotationDeal = lazy(() => import('../pages/QuotationDeal'));
const Complete = lazy(() => import('../pages/Complete'));
const Cart = lazy(() => import('../pages/Cart'));
const Privacy = lazy(() => import('../pages/Privacy'));
const DebtRecovery = lazy(() => import('../pages/DebtRecovery'));

const OrderConfirm = lazy(() => import('../pages/OrderConfirm'));
const OrderManagement = lazy(() => import('../pages/OrderManagement'));
const OrderManagementDetail = lazy(() => import('../pages/OrderManagementDetail'));
const OrderManagementJourney = lazy(() => import('../pages/OrderManagementJourney'));
const VNTracking = lazy(() => import('../pages/VNTracking'));
const VNTrackingDetail = lazy(() => import('../pages/VNTrackingDetail'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const UserInfo = lazy(() => import('../pages/UserInfo'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const AddressManagement = lazy(() => import('../pages/AddressManagement'));
const Notification = lazy(() => import('../pages/Notification'));
const Referral = lazy(() => import('../pages/Referral'));
const Welcome = lazy(() => import('../pages/Welcome'));

const SignUp = lazy(() => import('../pages/SignUp'));
const SignIn = lazy(() => import('../pages/SignIn'));
const Identify = lazy(() => import('../pages/Identify'));
const Recovery = lazy(() => import('../pages/Recovery'));
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const AutoLogin = lazy(() => import('../pages/AutoLogin'));
const ValidateReferral = lazy(() => import('../pages/Referral/ValidateReferral'));

const RoutePages = {
  normal: [
    {
      path: Path.VERIFY_EMAIL,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VerifyEmail />
        </Suspense>
      )
    },
    {
      path: Path.QUOTATION,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Quotation />
        </Suspense>
      )
    },

    {
      path: Path.SHOPPING_CART,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Cart />
        </Suspense>
      )
    },
    {
      path: Path.PRIVACY_POLICY,
      Layout: ({ children }) => children,
      Page: () => (
        <Suspense fallback={<div />}>
          <Privacy />
        </Suspense>
      )
    },
    {
      path: Path.DEBT_RECOVERY,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <DebtRecovery />
        </Suspense>
      )
    },
    {
      path: Path.PRODUCT_DETAIL,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <CatalogDetail />
        </Suspense>
      )
    },
    {
      path: '/quotation/:dealId',
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <QuotationDeal />
        </Suspense>
      )
    }
  ],
  private: [
    {
      path: Path.SHOPPING_ORDER,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderConfirm />
        </Suspense>
      )
    },
    {
      path: Path.ORDER_MANAGEMENT,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderManagement />
        </Suspense>
      )
    },
    {
      path: Path.TRACKING_ORDER_DETAIL,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderManagementDetail />
        </Suspense>
      )
    },
    {
      path: Path.ORDER_MANAGEMENT_DETAIL_TRACKING,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderManagementJourney />
        </Suspense>
      )
    },
    {
      path: Path.LIST_VN_TRACKING,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonVNTracking />}>
          <VNTracking />
        </Suspense>
      )
    },
    {
      path: Path.DETAIL_VN_TRACKING,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VNTrackingDetail />
        </Suspense>
      )
    },
    {
      path: Path.USER_PROFILE,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <UserProfile />
        </Suspense>
      )
    },
    {
      path: Path.USER_INFO,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <UserInfo />
        </Suspense>
      )
    },
    {
      path: Path.CHANGE_PASSWORD,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <ChangePassword />
        </Suspense>
      )
    },
    {
      path: Path.USER_ADDRESS,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <AddressManagement />
        </Suspense>
      )
    },
    {
      path: Path.NOTIFICATION,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <Notification />
        </Suspense>
      )
    },
    {
      path: Path.USER_REFERRAL,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <Referral />
        </Suspense>
      )
    },
    {
      path: Path.WELCOME,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Welcome />
        </Suspense>
      )
    },
    {
      path: Path.SHOPPING_COMPLETE,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Complete />
        </Suspense>
      )
    }
  ],
  public: [
    {
      path: Path.SIGN_UP,
      Layout: OutSideLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <SignUp />
        </Suspense>
      )
    },
    {
      path: Path.SIGN_IN,
      Layout: OutSideLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <SignIn />
        </Suspense>
      )
    },
    {
      path: Path.IDENTIFY,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Identify />
        </Suspense>
      )
    },
    {
      path: Path.RECOVERY,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Recovery />
        </Suspense>
      )
    },
    {
      path: Path.RECOVERY_EMAIL,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VerifyEmail />
        </Suspense>
      )
    },
    {
      path: Path.VERIFY_EMAIL_CONFIRMED,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <AutoLogin />
        </Suspense>
      )
    },
    {
      path: Path.REFERRAL,
      Layout: ({ children }) => children,
      Page: () => (
        <Suspense fallback={<div />}>
          <ValidateReferral />
        </Suspense>
      )
    }
  ]
};

export default RoutePages;
