import isEmpty from 'lodash/isEmpty';
import io from 'socket.io-client';
import { ACCESS_KEY, ANONYMOUS_ID, SOCKET_MESSAGE } from '../contents/Constants';
import actions from '../redux/actions';
import dispatch from '../utils/dispatch';
import { HOST, NEST_SOCKET } from '../utils/domain';
import { isDevelopmentMode } from '../utils/helpers';

const setSocketCookie = (anonymous_id, ack) => {
  return ack || anonymous_id;
};

const forwardingAction = ({ type, payload }) => {
  if (isEmpty(type)) {
    return;
  }
  switch (type) {
    case SOCKET_MESSAGE.ANONYMOUS_SYNC_SHOPPING_CART:
      dispatch(actions.getShoppingCartAnonymous());
      break;
    case SOCKET_MESSAGE.LOGGED_SYNC_QUOTATION:
      dispatch(actions.getCurrentSaleOrder());
      break;
    case SOCKET_MESSAGE.LOGGED_NOTIFICATION:
      dispatch(actions.receiveNotification(payload));
      break;
    case SOCKET_MESSAGE.LOGGED_SYNC_BANK:
      dispatch(actions.syncListBank('buyngon'));
      break;
    default:
      break;
  }
};

const connectSocket = () => {
  const isDev = isDevelopmentMode();
  // if (isDev) return;

  const anonymous_id = localStorage.getItem(ANONYMOUS_ID);
  const extraHeaders = {
    'cookie-id': setSocketCookie(anonymous_id, localStorage.getItem(ACCESS_KEY)),
    token: setSocketCookie(anonymous_id, localStorage.getItem(ACCESS_KEY)),
    'send-by': 'webapp'
  };
  const socketExpressJs = io(HOST, {
    withCredentials: !isDev,
    extraHeaders
  });

  const socketNestJs = io(NEST_SOCKET, {
    withCredentials: !isDev,
    extraHeaders
  });

  socketExpressJs.on('connection', data => {
    if (!data) {
      return null;
    }
    return forwardingAction(data);
  });

  socketNestJs.on('connection', data => {
    if (!data) {
      return null;
    }
    return forwardingAction(data);
  });
};

export default connectSocket;
