import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function VNTrackingIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M21.44 12.23L21.43 12.22C21.41 12.16 21.38 12.09 21.36 12.03C21.35 12.02 21.35 12.01 21.34 12C19.93 8.21 16.28 5.5 12 5.5C12 5.5 12 5.5 11.99 5.5C7.66 5.51 3.98 8.26 2.6 12.11C2.58 12.15 2.57 12.19 2.56 12.23C2.19 13.25 2 14.35 2 15.5V18.25C2 18.66 2.33 19 2.75 19H21.25C21.66 19 22 18.66 22 18.25V15.5C22 14.36 21.8 13.26 21.44 12.23ZM20.5 17.5H3.5V15.5C3.5 14.75 3.6 14.02 3.78 13.33L4.81 13.6C4.87 13.62 4.94 13.63 5 13.63C5.33 13.63 5.63 13.41 5.72 13.07C5.83 12.67 5.59 12.26 5.19 12.16L4.29 11.92C5.55 9.23 8.16 7.3 11.25 7.03V8.32C11.26 8.73 11.59 9.06 12 9.06C12.01 9.06 12.01 9.06 12.01 9.06C12.42 9.06 12.76 8.72 12.75 8.31V7.03C15.83 7.3 18.45 9.23 19.71 11.92L18.81 12.16C18.41 12.26 18.17 12.67 18.28 13.07C18.37 13.41 18.67 13.63 19 13.63C19.06 13.63 19.13 13.62 19.19 13.6L20.22 13.33C20.4 14.02 20.5 14.75 20.5 15.5V17.5Z" />
      <path d="M13.25 14.75C13.25 15.44 12.7 16 12 16C11.47 16 11.02 15.67 10.84 15.21L8.49995 14.59C8.09995 14.48 7.85995 14.07 7.96995 13.67C8.07995 13.27 8.47996 13.03 8.88996 13.14L11.23 13.77C11.44 13.6 11.71 13.5 12 13.5C12.7 13.5 13.25 14.06 13.25 14.75Z" />
    </SvgIcon>
  );
}
