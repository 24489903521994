import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getStore } from '../../App';
import { Path } from '../../contents/Constants';
import { Labels } from '../../contents/Labels';
import services from '../../services';
import { messageError, messageSuccess } from '../../utils/alertMessage';
import { offLoading } from '../../utils/dispatch';
import { isIncludeHostName } from '../../utils/helpers';
import actions from '../actions';
import TYPE from '../actions/TYPE';

function* createNewAddress(action) {
  try {
    const { deliveryAddress } = action;
    const { data } = yield call(services.user.createNewAddress, {
      delivery_address: deliveryAddress
    });
    yield put({
      type: TYPE.POST_CREATE_NEW_ADDRESS_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.POST_CREATE_NEW_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* changeWeightWithoutWholeSale() {
  const pathName = window.location.pathname;
  const isWholeSale = yield select(state =>
    get(state, 'profile.deposit.is_wholesale', false)
  );

  if (pathName === Path.SHOPPING_ORDER && !isWholeSale) {
    messageSuccess(
      'Phí vận chuyển và phí phụ đã được cập nhật theo địa chỉ mới của bạn.'
    );
  }
}

function* hasChangeAddress(newData) {
  const oldAddress = yield select(state =>
    get(state, 'profile.address_management', []).find(item => item.selected)
  );
  const newAddress = (newData.address_management || []).find(item => item.selected);
  return oldAddress?.type_addr !== newAddress?.type_addr;
}

function* convertDeliveryAddress(action) {
  const {
    address: { city, district, ward, ...address }
  } = action;
  const isUS = address.type_addr.value === 'usa';

  const disableUSList = yield select(state => state.website.disableUS);
  const quotationList = yield select(
    state => state.saleOrder.draft?.quotation_list || []
  );
  const listSelected = quotationList.filter(item => item.status === 'selected');
  const websiteList = listSelected.filter(item =>
    isIncludeHostName(disableUSList, item?.website || '')
  );

  const { pathname } = window.location;
  if (isUS && size(websiteList) > 0 && pathname !== Path.USER_ADDRESS) {
    messageError(Labels.USWarning);
    return;
  }

  const VNAddress = {
    ...address,
    city,
    district,
    ward,
    type_addr: address.type_addr.value
  };
  const USAddress = {
    ...address,
    type_addr: address.type_addr.value
  };

  // eslint-disable-next-line consistent-return
  return {
    requestData: isUS ? USAddress : VNAddress
  };
}

function* selectAddress(action) {
  try {
    const { addressId } = action;
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
    const { data } = yield call(services.user.selectAddress, addressId);
    const isChangeAddress = yield hasChangeAddress(data);
    if (isChangeAddress) yield changeWeightWithoutWholeSale();
    yield put({
      type: TYPE.PUT_SELECT_ADDRESS_SUCCESS,
      addressId,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.PUT_SELECT_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
  }
}

function* resetDeliveryAddress() {
  try {
    const defaultAddressId = yield select(state => {
      const defaultAddress = (state.profile?.address_management || []).find(
        item => item.default_address
      );
      return defaultAddress?._id;
    });
    if (!defaultAddressId) throw new Error('Chưa có địa chỉ mặc định');
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.RESET_DELIVERY_ADDRESS
    });
    const { data } = yield call(
      services.user.resetDeliveryAddress,
      defaultAddressId
    );
    yield put({
      type: TYPE.RESET_DELIVERY_ADDRESS_SUCCESS,
      addressId: defaultAddressId,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.RESET_DELIVERY_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.RESET_DELIVERY_ADDRESS
    });
  }
}

function* deleteAddress(action) {
  try {
    const { addressId } = action;
    yield put(actions.enableLoading());
    const { data } = yield call(services.user.deleteAddress, addressId);
    yield put({ type: TYPE.UPDATE_USER_PROFILE_SUCCESS, data });
    yield put({
      type: TYPE.DELETE_ADDRESS_SUCCESS,
      addressId
    });
  } catch (error) {
    yield put({
      type: TYPE.DELETE_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* editDeliveryAddress(action) {
  try {
    const { data } = yield call(
      services.profile.editDeliveryAddress,
      action.id,
      action.data
    );

    yield put({ type: TYPE.EDIT_DELIVERY_ADDRESS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.EDIT_DELIVERY_ADDRESS_FAILED, error });
  } finally {
    yield offLoading();
  }
}

function* createNewAddressImprovement(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
    const { data } = action;
    const disableUSList = yield select(state => state.website.disableUS);
    const quotationList = yield select(
      state => state.saleOrder.draft?.quotation_list || []
    );
    const listSelected = quotationList.filter(item => item.status === 'selected');
    const websiteList = listSelected.filter(item =>
      isIncludeHostName(disableUSList, item?.website || '')
    );

    if (data.type_addr === 'usa' && size(websiteList) > 0) {
      messageError(Labels.USWarning);
      return;
    }

    const location = yield select(state => state.location);
    const city =
      data?.city && find(location.provinceList, item => item.id === data.city);
    const district =
      data?.district &&
      find(location.districtList, item => item.id === data.district);
    const ward =
      data?.ward && find(location.wardList, item => item.id === data.ward);

    const requestData = { delivery_address: { ...data, city, district, ward } };
    const { data: responseData } = yield call(
      services.user.createNewAddress,
      requestData
    );

    yield put({
      type: TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT_SUCCESS,
      data: responseData
    });

    const pathName = window.location.pathname;
    const isWholeSale = yield select(state => state.profile?.deposit?.is_wholesale);

    if (pathName === Path.SHOPPING_ORDER && !isWholeSale) {
      yield messageSuccess(
        'Phí vận chuyển và phí phụ đã được cập nhật theo địa chỉ mới của bạn.'
      );
    }

    yield put({
      type: TYPE.NEW_USER_CREATE_ADDRESS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT_FAILED,
      message: error
    });
  } finally {
    // yield offLoading();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
  }
}

function* editAddressImprovement(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
    const { data } = action;
    const state = getStore().getState().location;
    const addressId = getStore().getState().saleOrder.editAddressID;

    const city =
      data?.city && find(state.provinceList, item => item.id === data.city);
    const district =
      data?.district && find(state.districtList, item => item.id === data.district);
    const ward = data?.ward && find(state.wardList, item => item.id === data.ward);

    const requestData = { ...data, city, district, ward };

    const { data: responseData } = yield call(
      services.profile.editDeliveryAddress,
      addressId,
      requestData
    );

    const pathName = window.location.pathname;
    const isWholeSale = yield select(
      _state => _state.profile?.deposit?.is_wholesale
    );

    if (pathName === Path.SHOPPING_ORDER && !isWholeSale) {
      yield messageSuccess(
        'Phí vận chuyển và phí phụ đã được cập nhật theo địa chỉ mới của bạn.'
      );
    }

    yield put({
      type: TYPE.EDIT_ADDRESS_IMPROVEMENT_SUCCESS,
      data: responseData
    });
  } catch (error) {
    yield put({
      type: TYPE.EDIT_ADDRESS_IMPROVEMENT_FAILED,
      message: error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'addressManagement'
    });
    // yield offLoading();
  }
}

function* createDeliveryAddress(action) {
  try {
    const { requestData } = yield convertDeliveryAddress(action);
    const { data } = yield call(services.user.createNewAddress, requestData);
    const isChangeAddress = yield hasChangeAddress(data);
    if (isChangeAddress) yield changeWeightWithoutWholeSale();

    yield put({ type: TYPE.CREATE_DELIVERY_ADDRESS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_DELIVERY_ADDRESS_FAILED });
  } finally {
    yield offLoading();
  }
}

function* newEditDeliveryAddress(action) {
  try {
    const { requestData } = yield convertDeliveryAddress(action);
    const { data, ...response } = yield call(
      services.profile.editDeliveryAddress,
      action.address._id,
      requestData
    );
    if (!response?.success) throw new Error(response?.message);
    const isChangeAddress = yield hasChangeAddress(data);
    if (isChangeAddress) yield changeWeightWithoutWholeSale();

    const isUpdateTotalPrice = get(data, 'address_management', [])
      .filter(item => item._id === action.address._id)
      .every(item => item.selected);
    if (isUpdateTotalPrice) {
      yield put({ type: TYPE.EDIT_SELECTED_DELIVERY_ADDRESS, data });
    }

    yield put({ type: TYPE.NEW_EDIT_DELIVERY_ADDRESS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.NEW_EDIT_DELIVERY_ADDRESS_FAILED, error });
  } finally {
    yield offLoading();
  }
}

function* checkLoginProvider() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'checkLoginProvider' });
    const { data } = yield call(services.profile.checkLoginProvider);
    yield put({ type: TYPE.CHECK_LOGIN_PROVIDER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.CHECK_LOGIN_PROVIDER_FAILED });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'checkLoginProvider' });
    yield put({ type: TYPE.RESET_LOADING, target: 'checkLoginProvider' });
  }
}

function* changeUserPassword({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'changeUserPassword' });
    const { data, ...response } = yield call(
      services.profile.changeUserPassword,
      payload
    );
    yield put({ type: TYPE.CHANGE_USER_PASSWORD_SUCCESS, data });
    if (response?.success) {
      const messageSuccessContent = payload?.oldPassword
        ? 'Đổi mật khẩu thành công'
        : 'Tạo mật khẩu thành công';
      messageSuccess(messageSuccessContent);
      yield put({
        type: TYPE.DISABLE_LOADING_IMPROVE,
        target: 'changeUserPassword'
      });
      yield put({ type: TYPE.RESET_LOADING, target: 'changeUserPassword' });
    }
  } catch (error) {
    yield put({ type: TYPE.CHANGE_USER_PASSWORD_FAILED });
  } finally {
    const loading = yield select(
      state => state.common.loadingImprove?.changeUserPassword
    );
    if (loading) {
      yield put({
        type: TYPE.DISABLE_LOADING_IMPROVE,
        target: 'changeUserPassword'
      });
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.POST_CREATE_NEW_ADDRESS, createNewAddress),
    takeLatest(TYPE.PUT_SELECT_ADDRESS, selectAddress),
    takeLatest(TYPE.RESET_DELIVERY_ADDRESS, resetDeliveryAddress),
    takeLatest(TYPE.DELETE_ADDRESS, deleteAddress),
    takeLatest(TYPE.EDIT_DELIVERY_ADDRESS, editDeliveryAddress),
    takeLatest(TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT, createNewAddressImprovement),
    takeLatest(TYPE.CREATE_DELIVERY_ADDRESS, createDeliveryAddress),
    takeLatest(TYPE.EDIT_ADDRESS_IMPROVEMENT, editAddressImprovement),
    takeLatest(TYPE.NEW_EDIT_DELIVERY_ADDRESS, newEditDeliveryAddress),
    takeLatest(TYPE.CHECK_LOGIN_PROVIDER, checkLoginProvider),
    takeLatest(TYPE.CHANGE_USER_PASSWORD, changeUserPassword)
    // takeLatest(TYPE.EDIT_ADDRESS_IMPROVEMENT, editAddressImprovement)
  ]);
}
