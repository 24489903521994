import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { element } from 'prop-types';
import React from 'react';
import { BuyNgonContainer } from '../components/2.2.0/Common';
import SideBar from '../components/2.2.0/Layout/SideBar';
import useEmptyLayout from '../hooks/2.2.0/useEmptyLayout';
import useResponsive from '../hooks/useResponsive';
import MainLayout from './MainLayout';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(3)
  }
}));

const UserInfoLayout = ({ children }) => {
  const classes = useStyles();
  const matches = useResponsive();

  const layout = (
    <MainLayout>
      <BuyNgonContainer fixed>
        <Box className={classes.container}>
          {!matches && (
            <Box minWidth={251} style={{ marginRight: 50 }}>
              <SideBar />
            </Box>
          )}
          <Box style={{ flex: 1 }}>{children}</Box>
        </Box>
      </BuyNgonContainer>
    </MainLayout>
  );

  return useEmptyLayout(children, layout);
};

UserInfoLayout.propTypes = {
  children: element
};

UserInfoLayout.defaultProps = {
  children: null
};

export default UserInfoLayout;
