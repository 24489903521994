import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function OrderConfirmedIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill={theme.palette.primary.main} />
      <path
        d="M17.1314 9.38594L12.3189 12.1734C12.1252 12.2859 11.8814 12.2859 11.6814 12.1734L6.86895 9.38594C6.5252 9.18594 6.4377 8.71719 6.7002 8.42344C6.88145 8.21719 7.0877 8.04844 7.30645 7.92969L10.6939 6.05469C11.4189 5.64844 12.5939 5.64844 13.3189 6.05469L16.7064 7.92969C16.9252 8.04844 17.1314 8.22344 17.3127 8.42344C17.5627 8.71719 17.4752 9.18594 17.1314 9.38594Z"
        fill="white"
      />
      <path
        d="M11.6438 13.3344V17.5969C11.6438 18.0719 11.1625 18.3844 10.7375 18.1781C9.45005 17.5469 7.2813 16.3656 7.2813 16.3656C6.06256 15.7476 5.8938 14.8469 5.8938 13.9531V10.7281C5.8938 10.2344 6.41255 9.92187 6.83755 10.1656L11.3313 12.7719C11.5188 12.8906 11.6438 13.1031 11.6438 13.3344Z"
        fill="white"
      />
      <path
        d="M12.3562 17.5969V13.3344C12.3562 13.1031 12.4812 12.8906 12.6687 12.7719L17.1625 10.1656C17.5875 9.92187 18.1062 10.2344 18.1062 10.7281V13.9531C18.1062 14.8469 17.9376 15.7476 16.7187 16.3656C16.7187 16.3656 14.55 17.5469 13.2625 18.1781C12.8375 18.3844 12.3562 18.0719 12.3562 17.5969Z"
        fill="white"
      />
    </SvgIcon>
  );
}
