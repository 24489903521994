import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ExtensionIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M3.75001 21C3.55001 21 3.36001 20.92 3.22001 20.78C3.02001 20.58 2.95001 20.28 3.04001 20.01L6.54001 9.51C6.62001 9.26 6.83001 9.08 7.08001 9.02C7.33001 8.96 7.60001 9.04 7.78001 9.22L14.78 16.22C14.96 16.4 15.04 16.67 14.98 16.92C14.92 17.17 14.73 17.38 14.49 17.46L3.99001 20.96C3.91001 20.99 3.83001 21 3.75001 21ZM7.58001 11.14L4.94001 19.07L12.87 16.43L7.58001 11.14Z" />
      <path d="M13.04 11.04C12.85 11.04 12.66 10.97 12.51 10.82C12.22 10.53 12.22 10.05 12.51 9.75998C13.07 9.19998 13.7 9.22998 14.08 9.23998C14.41 9.25998 14.44 9.23998 14.52 9.15998C14.6 9.07998 14.61 9.04998 14.6 8.71998C14.58 8.33998 14.56 7.70998 15.11 7.15998C15.67 6.59998 16.3 6.62998 16.68 6.63998C17.01 6.64998 17.04 6.63998 17.12 6.55998C17.2 6.47998 17.21 6.43998 17.2 6.11998C17.18 5.73998 17.16 5.10998 17.72 4.54998C18.28 3.98998 18.91 4.01998 19.29 4.02998C19.62 4.04998 19.65 4.02998 19.73 3.94998C20.02 3.65998 20.5 3.65998 20.79 3.94998C21.08 4.23998 21.08 4.71998 20.79 5.00998C20.23 5.56998 19.6 5.53998 19.22 5.52998C18.89 5.51998 18.86 5.52998 18.78 5.60998C18.7 5.68998 18.69 5.72998 18.7 6.04998C18.72 6.42998 18.74 7.05998 18.18 7.61998C17.62 8.17998 16.99 8.14998 16.62 8.13998C16.29 8.12998 16.26 8.13998 16.18 8.21998C16.1 8.29998 16.09 8.32998 16.1 8.65998C16.12 9.03998 16.14 9.66998 15.59 10.22C15.03 10.78 14.41 10.75 14.02 10.74C13.69 10.73 13.66 10.74 13.58 10.82C13.43 10.97 13.24 11.04 13.04 11.04Z" />
      <path d="M10.25 9C9.84 9 9.5 8.66 9.5 8.25V6.25C9.5 5.29 8.71 4.5 7.75 4.5C7.34 4.5 7 4.16 7 3.75C7 3.34 7.34 3 7.75 3C9.54 3 11 4.46 11 6.25V8.25C11 8.66 10.66 9 10.25 9Z" />
      <path d="M20.25 17C19.84 17 19.5 16.66 19.5 16.25C19.5 15.29 18.71 14.5 17.75 14.5H15.75C15.34 14.5 15 14.16 15 13.75C15 13.34 15.34 13 15.75 13H17.75C19.54 13 21 14.46 21 16.25C21 16.66 20.66 17 20.25 17Z" />
      <path d="M12.75 5.49999C12.65 5.49999 12.56 5.47999 12.46 5.43999C12.37 5.39999 12.29 5.34999 12.22 5.27999C12.08 5.13999 12 4.94999 12 4.74999C12 4.54999 12.08 4.35999 12.22 4.21999C12.43 4.00999 12.76 3.93999 13.04 4.05999C13.13 4.09999 13.21 4.14999 13.28 4.21999C13.35 4.28999 13.4 4.36999 13.44 4.45999C13.48 4.55999 13.5 4.64999 13.5 4.74999C13.5 4.94999 13.42 5.13999 13.28 5.27999C13.21 5.34999 13.13 5.39999 13.04 5.43999C12.94 5.47999 12.85 5.49999 12.75 5.49999Z" />
      <path d="M20.25 12C20.05 12 19.86 11.92 19.72 11.78C19.65 11.71 19.6 11.63 19.56 11.54C19.52 11.45 19.5 11.35 19.5 11.25C19.5 11.05 19.58 10.86 19.72 10.72C19.79 10.65 19.87 10.6 19.96 10.56C20.15 10.48 20.35 10.48 20.54 10.56C20.63 10.6 20.71 10.65 20.78 10.72C20.92 10.86 21 11.05 21 11.25C21 11.35 20.98 11.45 20.94 11.54C20.9 11.63 20.85 11.71 20.78 11.78C20.64 11.92 20.45 12 20.25 12Z" />
    </SvgIcon>
  );
}
