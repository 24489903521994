import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Animation from '../../icons/new/improvement/animation/Animation';

export function useGetIconMethods() {
  const hoverRef = useRef({ onActiveMode: () => {}, onInactiveMode: () => {} });

  return { hoverRef };
}

export default function HoverIcon(props) {
  const { route, hoverRef, isActive } = props;
  const { ActiveIcon, Icon, animation } = route;

  const theme = useTheme();

  const [hoverMode, setHoverMode] = useState(false);
  const onActiveMode = useCallback(() => setHoverMode(true), []);
  const onInactiveMode = useCallback(() => setHoverMode(false), []);

  useEffect(() => {
    hoverRef.current = { onActiveMode, onInactiveMode };
  }, [hoverRef, onActiveMode, onInactiveMode]);

  if (hoverMode) {
    if (!animation) return <ActiveIcon color="primary" />;
    return <Animation animationData={animation} style={{ width: 24, height: 24 }} />;
  }

  if (!isActive) return <Icon htmlColor={theme.palette.common.black} />;

  return <ActiveIcon color="primary" />;
}

HoverIcon.propTypes = {
  route: PropTypes.shape({
    ActiveIcon: PropTypes.elementType.isRequired,
    Icon: PropTypes.elementType.isRequired,
    animation: PropTypes.object
  }).isRequired,
  hoverRef: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired
};
