import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { MENUS, NAV_TABS } from '../../../../contents/DynamicConstant';
import useCheckAuth from '../../../../hooks/useCheckAuth';
import actions from '../../../../redux/actions';
import dispatch from '../../../../utils/dispatch';
import { NewCloseIcon } from '../../Icons/Improve/CloseIcon';
import CustomMenuItem from '../CustomMenuItem';
import CustomizedIconButton from './CustomizedIconButton';
import UserInfo from './UserInfo';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400
    }
  }
}));

export default function ProfileMenu() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const isLogged = useCheckAuth();
  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(prevState => !prevState);
  };

  const handleLogout = event => {
    dispatch(actions.logout());
    toggleDrawer(event);
  };

  return (
    <>
      <CustomizedIconButton onClick={toggleDrawer}>
        <MenuIcon style={{ fontSize: 16 }} />
      </CustomizedIconButton>

      <Drawer
        open={open}
        onClose={toggleDrawer}
        PaperProps={{ style: { width: '100%' } }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={1.25}
        >
          <img src="/images/image/main-logo-full.png" alt="logo" height={32} />

          <IconButton size="small" onClick={toggleDrawer} color="inherit">
            <NewCloseIcon />
          </IconButton>
        </Box>

        <Box px={1}>
          {NAV_TABS.map((item, index) => (
            <ListItem
              key={index}
              dense
              button
              to={item.href}
              href={item.href}
              target={item.target}
              component={item.component}
              selected={item.href === pathname}
              onClick={!item.href ? handleLogout : toggleDrawer}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={classes.label}
                    color={item.href === pathname ? 'primary' : 'initial'}
                  >
                    {item.label}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </Box>

        <Box borderTop="1px solid #E1E2E5" px={2} py={3}>
          <UserInfo onClose={toggleDrawer} />
        </Box>

        {isLogged && (
          <List component="nav" dense>
            {MENUS.slice(1).map((item, index) => (
              <CustomMenuItem
                key={index}
                routeProps={item}
                callback={!item.href ? handleLogout : toggleDrawer}
              />
            ))}
          </List>
        )}
      </Drawer>
    </>
  );
}
