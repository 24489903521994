import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function TransferingIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68758 1.66797C8.46894 1.66807 8.25041 1.75195 8.08374 1.91862L6.87606 3.1263L3.47925 2.14648L2.87541 2.75195L5.06291 4.93945L4.28166 5.7207L2.65568 5.17871L2.04208 5.79232L5.41447 9.16797L6.02971 8.55273L5.48934 6.92839L6.27059 6.14714L8.45646 8.33301L9.06193 7.72917L8.08374 4.33398L9.29142 3.1263C9.62476 2.79297 9.62476 2.25279 9.29142 1.91862C9.12434 1.75154 8.90623 1.66786 8.68758 1.66797ZM11.9786 1.86003C11.7762 1.87091 11.5848 1.95516 11.44 2.09701C11.2953 2.23886 11.2072 2.4286 11.1922 2.6307C11.1772 2.83281 11.2364 3.03345 11.3587 3.19507C11.481 3.35669 11.658 3.46822 11.8565 3.50879C12.5174 3.65907 13.1383 3.91175 13.7087 4.24121V7.5013C13.7087 7.95964 14.0837 8.33464 14.5421 8.33464C15.0021 8.33464 15.3754 8.70797 15.3754 9.16797C15.3754 9.62797 15.0021 10.0013 14.5421 10.0013H12.0421C11.5837 10.0013 11.2087 10.3763 11.2087 10.8346V12.5013C11.2087 13.418 11.9587 14.168 12.8754 14.168C13.3354 14.168 13.7087 14.5413 13.7087 15.0013V15.7744C12.7284 16.3401 11.5926 16.668 10.3754 16.668C9.79815 16.668 9.24228 16.5867 8.70874 16.4499V15.8346C8.70874 14.918 7.95874 14.168 7.04208 14.168C6.58208 14.168 6.20874 13.7946 6.20874 13.3346V12.5013C6.20874 12.043 5.83374 11.668 5.37541 11.668H3.92684C3.79005 11.1344 3.70874 10.5786 3.70874 10.0013C3.7103 9.89088 3.6899 9.78124 3.64872 9.67877C3.60755 9.57629 3.54641 9.48303 3.46888 9.40438C3.39134 9.32574 3.29894 9.2633 3.19706 9.22067C3.09518 9.17805 2.98585 9.1561 2.87541 9.1561C2.76497 9.1561 2.65563 9.17805 2.55375 9.22067C2.45187 9.2633 2.35948 9.32574 2.28194 9.40438C2.20441 9.48303 2.14327 9.57629 2.10209 9.67877C2.06091 9.78124 2.04051 9.89088 2.04208 10.0013C2.04208 14.5941 5.78263 18.3346 10.3754 18.3346C14.9682 18.3346 18.7087 14.5941 18.7087 10.0013C18.7087 6.04452 15.938 2.72657 12.2276 1.88281C12.1732 1.86966 12.1176 1.86202 12.0616 1.86003C12.034 1.85865 12.0063 1.85865 11.9786 1.86003Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
}
