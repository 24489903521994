import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const BuyNgonContainer = withStyles(theme => ({
  fixed: {
    [theme.breakpoints.up(601)]: {
      maxWidth: 600
    },
    [theme.breakpoints.up(761)]: {
      maxWidth: 768
    },
    [theme.breakpoints.up(961)]: {
      maxWidth: 960
    },
    [theme.breakpoints.up(1281)]: {
      maxWidth: 1280
    },
    [theme.breakpoints.up(1441)]: {
      maxWidth: 1280
    }
  }
}))(props => <Container {...props} fixed disableGutters />);

export default BuyNgonContainer;
