import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getDetail({ payload }) {
  try {
    const { data } = yield call(services.deal.getDetail, payload);
    yield put({ type: TYPE.GET_DEAL_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_DEAL_DETAIL_FAILED, error });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.GET_DEAL_DETAIL, getDetail)]);
}
