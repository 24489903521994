/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import useActiveAsPath from '../../../hooks/useActiveAsPath';
import CustomizedMenuItem from './CustomizedMenuItem';

export default function CustomMenuItemChild(props) {
  const { href } = props.routeProps;
  const isActive = useActiveAsPath(href);

  return (
    <CustomizedMenuItem
      {...props}
      isActive={isActive}
      listItemTextProps={{ style: { fontSize: 12 } }}
    />
  );
}
