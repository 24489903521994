import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { any, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  tag: {
    display: 'inline-block',
    borderRadius: theme.spacing(2),
    color: theme.palette.common.white,
    cursor: 'pointer',
    padding: theme.spacing(0.5, 1),
    fontSize: theme.spacing(1),
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      fontSize: 8
    }
  }
}));

export default function Tag({ content, ...props }) {
  const classes = useStyles();

  return (
    <Grid {...props} container className={classes.tag} alignContent="center">
      {content}
    </Grid>
  );
}

Tag.propTypes = {
  content: any,
  color: string
};

Tag.defaultProps = {
  content: '',
  color: null
};
