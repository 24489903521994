import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import React from 'react';
import CustomizedMenuItem from './CustomizedMenuItem';
import HoverIcon, { useGetIconMethods } from './HoverIcon';

export default function CustomMenuItemStartIcon({ listItemProps, ...props }) {
  const { routeProps, isActive } = props;
  const { hoverRef } = useGetIconMethods();

  return (
    <CustomizedMenuItem
      listItemProps={{
        ...listItemProps,
        onMouseEnter: () => hoverRef.current.onActiveMode(),
        onMouseLeave: () => hoverRef.current.onInactiveMode()
      }}
      startIcon={
        <ListItemIcon style={{ minWidth: 0, marginRight: 16 }}>
          <HoverIcon isActive={isActive} hoverRef={hoverRef} route={routeProps} />
        </ListItemIcon>
      }
      {...props}
    />
  );
}

CustomMenuItemStartIcon.propTypes = {
  listItemProps: PropTypes.object.isRequired,
  routeProps: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired
};
