import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BoxTickIcon(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M19.1666 14.9252C19.1833 15.5502 19.0166 16.1419 18.7166 16.6502C18.55 16.9502 18.325 17.2252 18.075 17.4502C17.5 17.9836 16.7416 18.3086 15.9 18.3336C14.6833 18.3586 13.6083 17.7336 13.0166 16.7752C12.7 16.2836 12.5083 15.6919 12.5 15.0669C12.475 14.0169 12.9416 13.0669 13.6916 12.4419C14.2583 11.9752 14.975 11.6836 15.7583 11.6669C17.6 11.6252 19.125 13.0835 19.1666 14.9252Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5333 15.026L15.375 15.8259L17.1167 14.1426"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64172 6.19922L10 10.4575L17.3084 6.22419"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.0076V10.4492"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0083 7.64102V12.3577C18.0083 12.3994 18.0084 12.4327 18 12.4743C17.4167 11.966 16.6667 11.666 15.8334 11.666C15.05 11.666 14.325 11.941 13.75 12.3994C12.9834 13.0077 12.5 13.9494 12.5 14.9994C12.5 15.6244 12.675 16.216 12.9833 16.716C13.0583 16.8494 13.15 16.9744 13.25 17.091L11.725 17.9327C10.775 18.466 9.22501 18.466 8.27501 17.9327L3.82502 15.466C2.81668 14.9077 1.9917 13.5077 1.9917 12.3577V7.64102C1.9917 6.49102 2.81668 5.09103 3.82502 4.5327L8.27501 2.06602C9.22501 1.53268 10.775 1.53268 11.725 2.06602L16.175 4.5327C17.1834 5.09103 18.0083 6.49102 18.0083 7.64102Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
