import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import { nanoid } from 'nanoid';
import qs from 'qs';
import * as yup from 'yup';
import { history } from '../App';

import {
  CURRENCY_STATE,
  DEBT_TYPE,
  FLASH_SALE_CATEGORY,
  FOR_YOU_CATEGORY,
  IS_AUTH,
  LOCATION_STATE,
  LOOKING_CATEGORY,
  NotifyTypes,
  ORDER_TRACKING_STATE,
  Path,
  QUOTATION_ANONYMOUS,
  SALE_ORDER_STATUS,
  SOURCE_LIST,
  SUB_JOURNEY_STEP,
  TAG_CATALOG_NAME,
  VN_TRACKING_STATUS
} from '../contents/Constants';
import Images from '../contents/Images';
import actions from '../redux/actions';
import TYPE from '../redux/actions/TYPE';
import RoutePages from '../routes/routes';
import { getProductInfo, nonAccentVietnamese } from './convert';
import dispatch from './dispatch';

import AirplaneIcon from '../components/icons/AirplaneIcon';
import BagIcon from '../components/icons/BagIcon';
import BoxTickIcon from '../components/icons/BoxTickIcon';
import BoxTimeIcon from '../components/icons/BoxTimeIcon';
import ClockIcon from '../components/icons/ClockIcon';
import CloseCircleIcon from '../components/icons/CloseCircleIcon';
import TruckDeliveryIcon from '../components/icons/TruckDeliveryIcon';

const normalization = value => value.toString().trim().toLowerCase();

export const searchListSaleOrder = (filter, list = []) => {
  const { search, status } = filter;
  let result = [];

  if (!isEmpty(search)) {
    const keyword = normalization(search);
    result = list.filter(
      item =>
        normalization(get(item, 'sale_order_code', '')).startsWith(keyword) ||
        normalization(get(item, 'product_number', 0)).startsWith(keyword) ||
        normalization(get(item, 'total_price_after_sale', 0)).startsWith(keyword)
    );
  } else {
    result = list;
  }

  if (!isEmpty(status) && status !== 'none') {
    result = result.filter(item => item.status === status);
  }

  const startDate = get(filter, 'startDate', '');
  const endDate = get(filter, 'endDate', '');

  if (!isEmpty(startDate) && !isEmpty(endDate)) {
    const start = moment(startDate, 'DD-MM-YYYY');
    const end = moment(endDate, 'DD-MM-YYYY').add(1, 'days');

    result = result.filter(item => {
      const date = moment(item.created_at);
      return date.isSameOrAfter(start) && date.isBefore(end);
    });
  }

  return result;
};

export const filterTrackingList = (filter, list = []) => {
  const { search, status } = filter;
  let result = [];

  if (!isEmpty(search)) {
    const keyword = normalization(search);
    result = list.filter(
      item =>
        normalization(get(item, 'name', '')).startsWith(keyword) ||
        normalization(get(item, 'order_name', '')).startsWith(keyword)
    );
  } else {
    result = list;
  }
  if (!isEmpty(status) && status !== 'all') {
    result = result.filter(item => item.status === status);
  }

  const startDate = get(filter, 'startDate', '');
  const endDate = get(filter, 'endDate', '');

  if (!isEmpty(startDate) && !isEmpty(endDate)) {
    const start = moment(startDate, 'DD-MM-YYYY');
    const end = moment(endDate, 'DD-MM-YYYY').add(1, 'days');

    result = result.filter(item => {
      const date = moment(item.estimate_date, 'DD-MM-YYYY');
      return date.isSameOrAfter(start) && date.isBefore(end);
    });
  }

  return result;
};

export const isValidDomain = url => {
  const linkRegex = /(http:\/\/|https:\/\/)/;

  const isValidLink = linkRegex.test(url);
  if (isEmpty(url) || !isValidLink) {
    return false;
  }

  return true;
};

export const isValidLink = link => {
  const linkSchema = yup.string().required().url();
  return linkSchema.isValidSync(link);
};

export const convertStringToArray = string => {
  const arrayLinks = string.trim().split('\n');

  return arrayLinks.reduce((acc, cur) => {
    const currentLink = cur.trim();
    if (!currentLink) return acc;

    if (isValidLink(currentLink)) return [...acc, currentLink];

    const transformLink = `https://${currentLink}`;
    if (isValidLink(transformLink)) return [...acc, transformLink];

    return acc;
  }, []);
};

export const getDomain = url => {
  const isError = !isValidDomain(url);
  if (isError) return '';

  const domain = new URL(url);
  return domain.hostname.replace('www.', '');
};

export const isDevelopmentMode = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isIncludeHostName = (list, link) => list.some(el => link?.includes(el));

function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

function filterProduct(filterId, dataName, data) {
  switch (filterId) {
    case TAG_CATALOG_NAME.popular:
      return {
        [dataName]: {
          ...data,
          filter: sortBy(data.list, item => item.rating[0]).reverse()
        }
      };

    case TAG_CATALOG_NAME.highPrice:
      return {
        [dataName]: {
          ...data,
          filter: sortBy(data.list, item => item.price).reverse()
        }
      };

    case TAG_CATALOG_NAME.lowPrice:
      return {
        [dataName]: {
          ...data,
          filter: sortBy(data.list, item => item.price)
        }
      };

    default:
      return {
        [dataName]: {
          ...data,
          list: data.list,
          filter: data.list
        }
      };
  }
}

export function filterCategoryCatalog(state, { tagId, categoryId }) {
  if (categoryId === FLASH_SALE_CATEGORY.value)
    return filterProduct(tagId, 'flashSale', state.flashSale);

  if (categoryId === FOR_YOU_CATEGORY.value)
    return filterProduct(tagId, 'forYou', state.forYou);

  if (categoryId === LOOKING_CATEGORY.value)
    return filterProduct(tagId, 'looking', state.looking);

  const isCatalogPage = window.location.pathname !== Path.HOME;
  if (isCatalogPage) {
    return filterProduct(tagId, 'category', state.category);
  }

  const filterIndexItem = state.productDealHome.findIndex(
    item => item.category_id[0] === +categoryId
  );
  return {
    ...state,
    productDealHome: state.productDealHome.map((item, index) => {
      if (index !== filterIndexItem) return item;
      return filterProduct(tagId, index, state.productDealHome[index])[index];
    })
  };
}

export function addQuotationToShoppingCart(payload) {
  const isAuth = localStorage.getItem(IS_AUTH);
  if (!isAuth) {
    dispatch(actions.addShoppingCartAnonymous(payload));
  } else {
    dispatch(actions.addShoppingCart(payload));
  }
}

export function buyNowQuotation(quotation) {
  const isAuth = localStorage.getItem(IS_AUTH);
  if (!isAuth) {
    dispatch(actions.buynowAnonymous({ quotation }));
  } else {
    dispatch(actions.buynow({ quotation }));
  }
}

export function convertQuotation(item, other = {}) {
  return {
    link: item.link,
    image: item.image,
    name: item.name,
    size: item.size || '',
    color: item.color || '',
    note: item?.note || '',
    notice: item?.notice || '',
    added_from_bn_link: item?.added_from_bn_link || '',
    created_from: SOURCE_LIST.WEBAPP,
    location: item.location,
    price: toNumber(item.price),
    quantity: toNumber(item.quantity),
    sale_price: toNumber(item.sale_price || 0),
    sale_price_type: item?.sale_price_type || '$',
    weight: item?.weight || 1,
    category: item?.category || {},
    ...other
  };
}

export const convertCurrencyToLocation = currency => {
  switch (currency) {
    case CURRENCY_STATE.EUR.value:
      return LOCATION_STATE.ESP.value;

    case CURRENCY_STATE.USD.value:
      return LOCATION_STATE.USA.value;

    default:
      return LOCATION_STATE.USA.value;
  }
};

export function pushQuotationToStorage(quotations) {
  const current = JSON.parse(localStorage.getItem(QUOTATION_ANONYMOUS));
  if (isEmpty(current)) {
    localStorage.setItem(QUOTATION_ANONYMOUS, JSON.stringify(quotations));
  } else {
    current.push(quotations[0]);
    localStorage.setItem(QUOTATION_ANONYMOUS, JSON.stringify(current));
  }
}

export function editQuotationAnonymousFlow(detail) {
  const isAuth = localStorage.getItem(IS_AUTH);
  if (isAuth) {
    dispatch(actions.editSaleOrderImprovement(detail._id, { ...detail }));
  } else {
    dispatch(actions.editSaleOrderAnonymous(detail._id, { ...detail }));
  }
}

export function deleteQuotationAnonymousFlow(id) {
  const isAuth = localStorage.getItem(IS_AUTH);
  if (isAuth) {
    dispatch(actions.deleteQuotation(id));
  } else {
    dispatch(actions.deleteQuotationAnonymous(id));
  }
}

export function deleteSelectedCartItemFlow(payload) {
  const isAuth = localStorage.getItem(IS_AUTH);
  if (isAuth) {
    dispatch(actions.deleteSelectedCartItems(payload));
  } else {
    dispatch(actions.deleteSelectedAnonymousCartItems(payload));
  }
}

export const getParams = (search, listParams) => {
  const prefixed = qs.parse(search, { ignoreQueryPrefix: true });
  const result = {};

  Object.keys(prefixed).forEach(key => {
    if (listParams.includes(key)) {
      result[key] = prefixed[key];
      return;
    }
    const curKeyResult = Object.keys(result);
    const lastKey = curKeyResult[curKeyResult.length - 1];
    result[lastKey] += `%26${key}=${prefixed[key]}`;
  });

  return result;
};

export function generateUidUserAccount() {
  return `anonymous_${nanoid(24)}`;
}

export const validationUserInfoData = (data, hasEmail = true) => {
  const errors = {};

  if (isEmpty(get(data, 'name', ''))) {
    errors.name = 'Vui lòng nhập tên';
  }
  if (hasEmail && isEmpty(get(data, 'email', ''))) {
    errors.email = 'Vui lòng nhập email';
  }
  if (isEmpty(get(data, 'phone', ''))) {
    errors.phone = 'Vui lòng nhập số điện thoại';
  }

  dispatch(actions.validateForm(errors));

  return !isEmpty(errors);
};

export function filterListOrderDeal(list, filterValue) {
  const { search, startDate, endDate } = filterValue;

  let result = list?.filter(item => {
    const transformSearch = nonAccentVietnamese(search);
    const transformName = nonAccentVietnamese(
      item?.deal_purchase_transaction?.deal_order_id?.history?.name || ''
    );
    const transformCode = nonAccentVietnamese(
      item?.deal_purchase_transaction?.deal_order_id?.order_code || ''
    );

    return (
      transformName.includes(transformSearch) ||
      transformCode.includes(transformSearch)
    );
  });

  if (startDate && endDate) {
    const start = moment(startDate, 'DD/MM/YYYY');
    const end = moment(endDate, 'DD/MM/YYYY').add(1, 'days');

    result = result.filter(item => {
      const date = moment(
        item?.deal_purchase_transaction?.deal_order_id?.created_at
      );
      return date.isSameOrAfter(start) && date.isBefore(end);
    });
  }

  return result;
}

export function getColorStatus(type) {
  switch (type) {
    case SALE_ORDER_STATUS.customer_confirmed.value:
      return {
        Icon: ClockIcon,
        color: '#FFAA00',
        backgroundColor: '#FFFAEA',
        sublable: ORDER_TRACKING_STATE.DRAFT.sublable,
        sublable2: ORDER_TRACKING_STATE.DRAFT.sublable2
      };

    case SALE_ORDER_STATUS.waiting.value:
      return {
        Icon: ClockIcon,
        color: '#FFAA00',
        backgroundColor: '#FFFAEA',
        sublable: SALE_ORDER_STATUS.waiting.label,
        sublable2: 'Vui lòng nhấn Xác nhận đơn bên dưới'
      };

    case SALE_ORDER_STATUS.confirmed.value:
      return {
        Icon: BoxTimeIcon,
        color: '#3838FF',
        backgroundColor: '#EBF1FF',
        sublable: ORDER_TRACKING_STATE.CONFIRMED.sublable,
        sublable2: ORDER_TRACKING_STATE.CONFIRMED.sublable2
      };

    case SALE_ORDER_STATUS.purchasing.value:
      return {
        Icon: BagIcon,
        color: '#3838FF',
        backgroundColor: '#EBF1FF',
        sublable: ORDER_TRACKING_STATE.PURCHASING.sublable,
        sublable2: ORDER_TRACKING_STATE.PURCHASING.sublable2
      };
    case SALE_ORDER_STATUS.purchased.value:
      return {
        Icon: BagIcon,
        color: '#3838FF',
        backgroundColor: '#EBF1FF',
        sublable: SALE_ORDER_STATUS.purchased.label,
        sublable2: ''
      };

    case SALE_ORDER_STATUS.us_shipping.value:
    case SALE_ORDER_STATUS.arrived_us.value:
    case SALE_ORDER_STATUS.clear.value:
    case SALE_ORDER_STATUS.buyngon_received.value:
      return {
        Icon: AirplaneIcon,
        color: '#3838FF',
        backgroundColor: '#EBF1FF',
        sublable: ORDER_TRACKING_STATE.TRACKING.sublable,
        sublable2: ORDER_TRACKING_STATE.TRACKING.sublable2
      };
    case SALE_ORDER_STATUS.vn_shipping.value:
      return {
        Icon: TruckDeliveryIcon,
        color: '#3838FF',
        backgroundColor: '#EBF1FF',
        sublable: ORDER_TRACKING_STATE.DELIVERY.sublable,
        sublable2: ORDER_TRACKING_STATE.DELIVERY.sublable2
      };
    case SALE_ORDER_STATUS.transferred.value:
      return {
        Icon: BoxTickIcon,
        color: '#22C55E',
        backgroundColor: '#F2FDF6',
        sublable: ORDER_TRACKING_STATE.DONE.sublable,
        sublable2: ORDER_TRACKING_STATE.DONE.sublable2
      };
    case SALE_ORDER_STATUS.cancelled.value:
    case SALE_ORDER_STATUS.buyngon_rejected.value:
    case SALE_ORDER_STATUS.customer_rejected.value:
      return {
        Icon: CloseCircleIcon,
        color: '#F51D2C',
        backgroundColor: '#FFEBEC',
        sublable: ORDER_TRACKING_STATE.CANCEL.sublable,
        sublable2: ''
      };

    default:
      return {
        Icon: null,
        color: 'white',
        backgroundColor: 'white',
        sublable: '',
        sublable2: ''
      };
  }
}

export const isCouponMeetTheCondition = (group, promotions) => {
  const result = promotions.filter(
    item => item?.group === group || item?.type === 'single'
  );
  return !(result.length > 0);
};

export function transformStepList(list) {
  const originalList = SUB_JOURNEY_STEP.reduce((acc, cur) => {
    const step = list
      .filter(x => x.substatus === cur.key)
      .map(item => ({ ...item, name: cur.label, date: item?.time }));

    return [...acc, ...step];
  }, []);

  const reverseList = originalList.reverse();
  const highestPriorityIndex = reverseList.findIndex(item => item.date);
  const stepList = reverseList.slice(highestPriorityIndex);

  return stepList;
}

export function generatePaymentCode(name, bank, code) {
  // eslint-disable-next-line prefer-const
  let upperCaseName = name.toUpperCase();

  return (
    // eslint-disable-next-line prefer-template
    removeAccents(upperCaseName.slice(0, 25)).replace(
      /[.*+-?^\s<>;'"#@${}()|[\]\\]/g,
      ''
    ) +
    bank +
    '-' +
    code
  );
}

export function generateProductName(list) {
  const name = get(list, '[0].sale_order_line.name', '');
  const image = get(list, '[0].sale_order_line.product_info.image_url', '');

  if (size(list) <= 1) {
    return {
      image,
      name: `${name.slice(0, 45)}${size(name) > 45 ? '...' : ''}`
    };
  }

  return {
    image,
    name: `${name.slice(0, 30)}... và ${size(list) - 1} sản phẩm khác`
  };
}

export function getVariant(status) {
  switch (status) {
    case VN_TRACKING_STATUS.draft.value:
      return { color: 'draft', ...VN_TRACKING_STATUS.draft };

    case VN_TRACKING_STATUS.shipping.value:
      return { color: 'shipping', ...VN_TRACKING_STATUS.shipping };

    case VN_TRACKING_STATUS.delivered.value:
      return { color: 'delivered', ...VN_TRACKING_STATUS.delivered };

    default:
      return { color: '', label: '', value: '' };
  }
}
export function CompareShallowObject(source, target) {
  if (typeof source !== 'object' || typeof target !== 'object') {
    return false;
  }
  return Object.keys(source).every(key => source[key] === target[key]);
}

export function convertStringByWord(str, wordCount) {
  const wordArr = (str || '').split(' ');
  if (wordArr.length <= wordCount) return str;
  return `${wordArr.slice(0, wordCount).join(' ')}...`;
}

function groupByPromotion(list) {
  return list.reduce((acc, cur, idx) => {
    if (!cur?.store_promotion) return [...acc, cur];

    const condition = item => item?.store_promotion?._id === cur.store_promotion._id;
    const isExistPromotion = acc.some(condition);
    if (isExistPromotion) return acc;
    const listPromotion = list.slice(idx).filter(condition);

    return [...acc, ...listPromotion];
  }, []);
}

export function simpleSortByCreatedAt(list) {
  const listSorted = [...(list || [])].sort((a, b) => {
    const firstTime = moment(a?.created_at || 0).format('x');
    const secondTime = moment(b?.created_at || 0).format('x');
    return secondTime - firstTime;
  });
  return groupByPromotion(listSorted);
}

export function getGroupQuotation(list) {
  return simpleSortByCreatedAt(list).reduce((acc, cur) => {
    const website = cur?.deal?._id
      ? `${cur.website}_${cur.location}_${cur?.deal?._id}`
      : `${cur.website}_${cur.location}`;
    const currentIndex = acc.findIndex(item => item.website === website);
    if (currentIndex < 0) {
      acc.push({ website, list: [cur] });
    } else {
      acc[currentIndex].list.push(cur);
    }
    return acc;
  }, []);
}

export function choosePromotion(list, cur) {
  const { isAdd, coupon } = cur;
  let listingChoosePromotion = [...list];
  if (
    isAdd &&
    (listingChoosePromotion.length === 0 ||
      (coupon.type === 'multi' &&
        isCouponMeetTheCondition(coupon?.group, listingChoosePromotion)))
  ) {
    listingChoosePromotion.push(coupon);
  }
  if (!isAdd) {
    listingChoosePromotion = listingChoosePromotion.filter(
      item => item?.code !== coupon?.code
    );
  }

  return {
    listingChoosePromotion,
    isReject: size(listingChoosePromotion) === size(list)
  };
}

export function marginLastItem(theme, { isWholesale, isEmptyList }) {
  if (isWholesale) {
    return theme.spacing(isEmptyList ? 19 : 22);
  }
  return theme.spacing(isEmptyList ? 19 : 22);
}

export function convertTypeDebt(type) {
  return DEBT_TYPE.find(item => item.value === type);
}

export const getImagesNotification = (img, type) => {
  switch (type) {
    case NotifyTypes.RECHARGE_SUCCESS:
    case NotifyTypes.WITHDRAWL_SUCCESS:
    case NotifyTypes.RECHARGE_REQUEST_SUCCESS:
    case NotifyTypes.WITHDRAWL_REQUEST_SUCCESS:
      return Images.debtDefault;

    default:
      return img;
  }
};

export const convertContentToParams = data => {
  return data.replaceAll(' ', '-');
};

export const convertUserName = fullName => {
  const MAX_LENGTH = 8;
  const name = fullName.split(' ').at(-1); // .repeat();
  if (name.length <= MAX_LENGTH) return name;

  return `${name.slice(0, MAX_LENGTH)}...`;
};

export const shuffle = array => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
};

export const getEventNumberInput = (values, sourceInfo) => {
  return {
    name: get(sourceInfo, 'event.target.name'),
    value: get(values, 'floatValue')
  };
};

export const convertTopicContent = topics => {
  const topicContent = topics.includes('best_seller') ? 'Bán chạy' : '';
  return topicContent;
};

export const convertTooLongText = (text = '', quantity = 0) => {
  return text.length > quantity ? `${text.slice(0, quantity)}...` : text;
};

export const handleHistoryPushQueryParam = (params, category_id) => {
  history.push({
    pathname: `/${category_id}`,
    search: qs.stringify({ ...params }, { addQueryPrefix: true })
  });
};

export const generateCheckPriceLoadingType = type => {
  return `${TYPE.CHECK_PRICE_PRODUCT}-${type}`;
};

export const convertListMobile = (originalList, newList) => {
  return newList.reduce((acc, cur) => {
    const productIndex = acc.findIndex(item => item.product_id === cur.product_id);

    if (productIndex < 0) acc.push(cur);
    acc[productIndex] = cur;

    return acc;
  }, originalList);
};

export const checkTypePath = path => {
  const listRouteValue = Object.values(RoutePages);
  const listRouteKey = Object.keys(RoutePages);
  let typePath;

  for (let i = 0; i < listRouteValue.length; i += 1) {
    for (let j = 0; j < listRouteValue[i].length; j += 1) {
      const primaryPath = listRouteValue[i][j].path;
      const fristParamIndex = primaryPath.indexOf(':');
      const checkPath =
        fristParamIndex !== -1
          ? primaryPath.slice(0, fristParamIndex - 1)
          : primaryPath;

      if (path?.includes(checkPath)) {
        typePath = listRouteKey[i];
        break;
      }
    }
  }

  return typePath;
};

export const encodeingUrlParamsCrawl = url => {
  if (!isValidLink(url)) {
    throw new Error(`URL not valid: ${url}`);
  }
  const urlObj = new URL(url);
  const getPathName = urlObj.pathname.slice(1); // remove "/"
  const getSearch = urlObj.search.slice(1); // remove  "?"

  urlObj.search = encodeURIComponent(getSearch);
  urlObj.pathname = encodeURIComponent(getPathName);
  return urlObj.toString();
};

export const isZeroMinWeight = category => category?.min_weight === 0;

function getFirstIndex(list, storePromotionId) {
  if (!storePromotionId) return -1;
  return list.findIndex(item => item?.store_promotion?._id === storePromotionId);
}

function getLastIndex(list, storePromotionId) {
  if (!storePromotionId) return -1;
  return findLastIndex(
    list,
    item => item?.store_promotion?._id === storePromotionId
  );
}

export function checkboxQuotation(
  classes,
  onCheck,
  onCheckGroup,
  index,
  list,
  item
) {
  const storePromotionId = item?.store_promotion?._id;

  const firstIndex = getFirstIndex(list, storePromotionId);
  const lastIndex = getLastIndex(list, storePromotionId);
  const isOneItemPromotion = firstIndex === lastIndex;

  const handleCheckItem = (checked, id) => {
    if (!item?.store_promotion) {
      onCheck(checked, id);
      return;
    }

    if (index === firstIndex) {
      const ids = list.reduce((acc, cur) => {
        if (cur?.store_promotion?._id === storePromotionId) return [...acc, cur._id];
        return acc;
      }, []);
      onCheckGroup(checked, ids);
    }
  };

  const dividerClassName = isOneItemPromotion
    ? {}
    : {
        [classes.dividerBefore]: index !== firstIndex,
        [classes.dividerAfter]: index !== lastIndex
      };

  const disabledCheckbox = isOneItemPromotion
    ? {}
    : { disabled: index !== firstIndex };

  return {
    handleCheckItem,
    dividerClassName,
    disabledCheckbox
  };
}

export function getContentWebsite(sale_order_line) {
  const websites = [
    ...new Set(sale_order_line.map(item => item.product_info.website))
  ];

  if (websites.length === 0) return '';

  if (websites.length === 1) return websites[0];

  return `${websites[0]} và ${websites.length - 1} website khác`;
}

export function getWebsiteKey(product, location) {
  const { dealId, productLink } = getProductInfo(product);

  let arr = [];

  if (productLink && location) arr = [getDomain(productLink), location];
  if (dealId) arr = [...arr, dealId];

  return arr.join('_');
}

export function getProductByWebsite(list, location) {
  const productByWebsite = (list || []).reduce((acc, cur) => {
    const website = getWebsiteKey(cur, location);

    if (acc[website]) {
      acc[website].push(cur);
    } else {
      acc[website] = [cur];
    }

    return acc;
  }, {});
  return productByWebsite;
}
