import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function OrderIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.25 21H4.75C3.78 21 3 20.21 3 19.25V14.75C3 13.79 3.78 13 4.75 13H9.25C10.21 13 11 13.79 11 14.75V19.25C11 20.21 10.21 21 9.25 21ZM4.75 14.5C4.61 14.5 4.5 14.61 4.5 14.75V19.25C4.5 19.39 4.61 19.5 4.75 19.5H9.25C9.39 19.5 9.5 19.39 9.5 19.25V14.75C9.5 14.61 9.39 14.5 9.25 14.5H4.75Z" />
      <path d="M9.25 11H4.75C3.78 11 3 10.21 3 9.25V4.75C3 3.79 3.78 3 4.75 3H9.25C10.21 3 11 3.79 11 4.75V9.25C11 10.21 10.21 11 9.25 11ZM4.75 4.5C4.61 4.5 4.5 4.61 4.5 4.75V9.25C4.5 9.39 4.61 9.5 4.75 9.5H9.25C9.39 9.5 9.5 9.39 9.5 9.25V4.75C9.5 4.61 9.39 4.5 9.25 4.5H4.75Z" />
      <path d="M19.25 11H14.75C13.78 11 13 10.21 13 9.25V4.75C13 3.79 13.78 3 14.75 3H19.25C20.21 3 21 3.79 21 4.75V9.25C21 10.21 20.21 11 19.25 11ZM14.75 4.5C14.61 4.5 14.5 4.61 14.5 4.75V9.25C14.5 9.39 14.61 9.5 14.75 9.5H19.25C19.39 9.5 19.5 9.39 19.5 9.25V4.75C19.5 4.61 19.39 4.5 19.25 4.5H14.75Z" />
      <path d="M21 17C21 17.41 20.66 17.75 20.25 17.75H17.75V20.25C17.75 20.66 17.41 21 17 21C16.59 21 16.25 20.66 16.25 20.25V17.75H13.75C13.34 17.75 13 17.41 13 17C13 16.59 13.34 16.25 13.75 16.25H16.25V13.75C16.25 13.34 16.59 13 17 13C17.41 13 17.75 13.34 17.75 13.75V16.25H20.25C20.66 16.25 21 16.59 21 17Z" />
    </SvgIcon>
  );
}
