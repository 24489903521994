import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CloseCircleIcon(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04167 9.99935C1.04167 5.07084 5.07149 1.04102 10 1.04102C14.9285 1.04102 18.9583 5.07084 18.9583 9.99935C18.9583 14.9279 14.9285 18.9577 10 18.9577C5.07149 18.9577 1.04167 14.9279 1.04167 9.99935ZM10 2.29102C5.76185 2.29102 2.29167 5.76119 2.29167 9.99935C2.29167 14.2375 5.76185 17.7077 10 17.7077C14.2382 17.7077 17.7083 14.2375 17.7083 9.99935C17.7083 5.76119 14.2382 2.29102 10 2.29102Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8003 7.19868C13.0444 7.44276 13.0444 7.83849 12.8003 8.08257L8.08362 12.7992C7.83954 13.0433 7.44381 13.0433 7.19974 12.7992C6.95566 12.5552 6.95566 12.1594 7.19974 11.9153L11.9164 7.19868C12.1605 6.95461 12.5562 6.95461 12.8003 7.19868Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19974 7.19868C7.44381 6.95461 7.83954 6.95461 8.08362 7.19868L12.8003 11.9153C13.0444 12.1594 13.0444 12.5552 12.8003 12.7992C12.5562 13.0433 12.1605 13.0433 11.9164 12.7992L7.19974 8.08257C6.95566 7.83849 6.95566 7.44276 7.19974 7.19868Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
