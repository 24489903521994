import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function WalletIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M20.25 4H3.75C2.79 4 2 4.78 2 5.75V18.25C2 19.21 2.79 20 3.75 20H20.25C21.21 20 22 19.21 22 18.25V5.75C22 4.78 21.21 4 20.25 4ZM20.5 14.5H16C14.62 14.5 13.5 13.38 13.5 12C13.5 10.62 14.62 9.5 16 9.5H20.5V14.5ZM20.5 8H16C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16H20.5V18.25C20.5 18.39 20.39 18.5 20.25 18.5H3.75C3.61 18.5 3.5 18.39 3.5 18.25V5.75C3.5 5.61 3.61 5.5 3.75 5.5H20.25C20.39 5.5 20.5 5.61 20.5 5.75V8Z" />
      <path d="M17.02 12C17.02 12.55 16.57 13 16.02 13C15.47 13 15.01 12.55 15.01 12C15.01 11.45 15.46 11 16.01 11H16.02C16.57 11 17.02 11.45 17.02 12Z" />
    </SvgIcon>
  );
}
