import { Box, useTheme } from '@material-ui/core';
import React from 'react';

export default function SkeletonUserInfoLayout() {
  const theme = useTheme();

  return (
    <Box
      width={1}
      bgcolor={theme.palette.common.white}
      borderRadius={8}
      height={500}
    >
      &nbsp;
    </Box>
  );
}
