import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import get from 'lodash/get';
import { number, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LOCATION_STATE, Path } from '../../../contents/Constants';
import Images from '../../../contents/Images';
import { formatCurrency, formatCurrencyByLocation } from '../../../utils/format';
import { simpleSortByCreatedAt } from '../../../utils/helpers';
import cartAnimation from '../../icons/new/improvement/animation/BuyNgon-Cart.json';
import PopupButton from '../Common/PopupButton';
import TagSalePrice from '../DealNgon/TagSalePrice';
import Image from '../Image';
import GiftLabel from '../Shopping/GiftLabel';

const useStyles = makeStyles(theme => ({
  btn: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  image: {
    width: 64,
    height: 64,
    borderRadius: 8,
    objectFit: 'contain'
  },
  name: {
    lineHeight: 1.5,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    wordBreak: 'break-word'
  },
  item: {
    cursor: 'pointer'
  },
  emptyCart: {
    width: theme.spacing(59.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(10, 8.5),
    '& > img': {
      marginBottom: theme.spacing(2)
    }
  }
}));

export default function ShoppingCartButton() {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const quotationList = useSelector(state => {
    return simpleSortByCreatedAt(get(state, 'saleOrder.draft.quotation_list', []));
  });
  const countQuotation = quotationList.length;
  const topQuotation = quotationList.slice(0, 5);

  const handleShowShoppingCart = () => history.push(Path.SHOPPING_CART);

  return (
    <PopupButton
      badge={countQuotation}
      onRedirect={handleShowShoppingCart}
      active={pathname === Path.SHOPPING_CART}
      icon={ShoppingCartIcon}
      animation={cartAnimation}
      popover={false}
    >
      {countQuotation < 1 ? (
        <Box className={classes.emptyCart}>
          <img src={Images.v3EmptyCart} alt="empty cart" />
          <Typography variant="subtitle1">Chưa có sản phẩm nào trong giỏ</Typography>
          <Typography variant="caption" color="textSecondary">
            Hãy làm đầy ấm giỏ hàng nào bạn yêu dấu!
          </Typography>
        </Box>
      ) : (
        <Box width={506} p={2}>
          {topQuotation.map((item, index) => (
            <QuotationCartItem key={index} {...item} />
          ))}

          <Divider />

          <Box px={2} pt={2} display="flex" justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {countQuotation} sản phẩm trong giỏ
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              className={classes.btn}
              onClick={handleShowShoppingCart}
            >
              Xem giỏ hàng
            </Typography>
          </Box>
        </Box>
      )}
    </PopupButton>
  );
}

function QuotationCartItem({
  name,
  image,
  price_after_sale_VND,
  price,
  location,
  sale_unit_price,
  sale_price,
  sale_price_original,
  sale_price_type
}) {
  const classes = useStyles();
  const history = useHistory();
  const showOriginalPrice = !!sale_price;
  const isGift = price === 0;

  return (
    <Box
      width={1}
      p={2}
      display="flex"
      justifyContent="space-between"
      onClick={() => history.push(Path.SHOPPING_CART)}
      className={classes.item}
    >
      <Box width="70%" display="flex">
        <Image src={image} alt="avatar" className={classes.image} />
        <Box ml={2} width="calc(100% - 64px - 16px)">
          {isGift && <GiftLabel />}
          <Typography variant="caption" className={classes.name}>
            {name}
          </Typography>
          <Box width="fit-content">
            <TagSalePrice off={sale_price_original} suffix={sale_price_type} />
          </Box>
        </Box>
      </Box>
      <Box
        width="30%"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        {isGift ? (
          <Typography
            variant="caption"
            color="secondary"
            style={{ fontWeight: 700 }}
          >
            Miễn phí
          </Typography>
        ) : (
          <>
            <Typography variant="caption" style={{ fontWeight: 700 }}>
              {formatCurrency(price_after_sale_VND, 'VND')}
            </Typography>
            <Typography variant="caption">
              {showOriginalPrice && (
                <span style={{ textDecoration: 'line-through', color: '#B0B1B8' }}>
                  {formatCurrencyByLocation(price, location)}
                </span>
              )}
              <span>
                &nbsp;
                {formatCurrencyByLocation(price - sale_unit_price, location)}
              </span>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}

QuotationCartItem.propTypes = {
  sale_price_type: string,
  sale_price_original: number,
  price_after_sale_VND: number,
  price: number,
  name: string,
  location: string,
  image: string,
  sale_unit_price: number,
  sale_price: number
};

QuotationCartItem.defaultProps = {
  sale_price_type: '',
  sale_price_original: 0,
  price_after_sale_VND: 0,
  price: 0,
  name: '',
  location: LOCATION_STATE.USA.value,
  image: '',
  sale_unit_price: 0,
  sale_price: 0
};
