import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function ChevronRightIcon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52827 11.1387C6.26792 10.8784 6.26792 10.4563 6.52827 10.1959L8.72353 8.00065L6.52827 5.80539C6.26792 5.54504 6.26792 5.12293 6.52827 4.86258C6.78862 4.60223 7.21073 4.60223 7.47108 4.86258L10.1377 7.52925C10.2628 7.65427 10.333 7.82384 10.333 8.00065C10.333 8.17746 10.2628 8.34703 10.1377 8.47206L7.47108 11.1387C7.21073 11.3991 6.78862 11.3991 6.52827 11.1387Z"
      />
    </SvgIcon>
  );
}
