import { EXTENSION_URL } from '../utils/domain';
import Icons from './Icons';
import Images from './Images';

export const COUPON = 'coupon';
export const ACCESS_KEY = 'ack';
export const REFRESH_TOKEN = 're-ack';
export const REQUEST_SALE_ORDER = 'rso';
export const CART_SALE_ORDER = 'cso';
export const FCM_TOKEN = 'fcm';
export const IS_AUTH = 'isAuth';
export const QUOTATION_ANONYMOUS = 'qa';
export const SCRAPED_LIST = 'scrapedList';
export const ANONYMOUS_ID = 'anonymous_id';
export const CATALOG_ITEM = 'catalog_item';

export const HTTP_CODE = {
  SUCCESS_200: 200,
  CREATED_201: 201,
  BAD_REQUEST_400: 400,
  UNAUTHORIZED_401: 401,
  FORBIDDEN_403: 403,
  METHOD_NOT_ALLOWED_405: 405,
  HAVE_NOT_ACCESS_406: 406,
  INTERNAL_SERVER_ERROR_500: 500,
  USER_AGENT_NOT_SUPPORT_599: 599
};

export const PRODUCTS_PATH_PATTERN = '[a-z0-9-]+';
export const PRODUCT_DETAIL_PATH_PATTERN = '[a-z0-9-]+\\b[PV]\\d+';

export const Path = {
  HOME: '/',
  FLASH_SALE: '/flash-sale',

  DEAL_DETAIL: `/deal/:dealId`,

  PRODUCTS: `/:categoryId(${PRODUCTS_PATH_PATTERN})`,
  PRODUCT_DETAIL: `/:slugId(${PRODUCT_DETAIL_PATH_PATTERN})`,

  CATALOG_DEFAULT_PATH: '/deal-ngon/catalog',

  BLOG: '/blog',
  WEBSITE_US: '/website-us',
  POLICY: '/policy/:id',
  CONTACT: '/contact',
  PROMOTION_US: '/website-us/promotion-us',
  QUOTATION: '/quotation',
  SHOPPING_CART: '/quotation/cart',
  PRIVACY_POLICY: '/privacy-policy',
  DEBT_RECOVERY: '/debt-recovery/:id',

  SHOPPING_ORDER: '/quotation/order',
  ORDER_MANAGEMENT: '/order-management/:type',
  TRACKING_ORDER_DETAIL: '/order-management/:type/detail/:id',
  ORDER_MANAGEMENT_DETAIL_TRACKING: '/order-management/:type/detail/:id/tracking',
  LIST_VN_TRACKING: '/vn-tracking/list',
  DETAIL_VN_TRACKING: '/vn-tracking/detail/:id',
  USER_PROFILE: '/user',
  USER_INFO: '/user-info',
  CHANGE_PASSWORD: '/change-password',
  USER_ADDRESS: '/user-address',
  USER_DEPOSIT: '/user-deposit',
  NOTIFICATION: '/notification',
  USER_REFERRAL: '/user-referral',
  SHIPPING: '/shipping',
  HELP: '/help',
  WELCOME: '/welcome',
  SHOPPING_COMPLETE: '/quotation/complete',

  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  IDENTIFY: '/sign-in/identify',
  RECOVERY: '/sign-in/recovery',
  VERIFY_EMAIL: '/sign-in/verify',
  RECOVERY_EMAIL: '/sign-in/check-email',
  VERIFY_EMAIL_CONFIRMED: '/sign-in/verify/confirm',
  REFERRAL: '/referral/:code',
  SELLER_PROFILE: '/seller',

  TRANSACTION_DEFAULT_PATH: '/quotation/',
  ORDER_MANAGEMENT_DEFAULT_PATH: '/order-management',
  VN_TRACKING_DEFAULT_PATH: '/vn-tracking',
  POLICY_DEFAULT_PATH: '/policy',

  USER_LOGOUT: '/user-logout',
  EXTENSION_INSTALL: EXTENSION_URL
};

export const ORDER_STATUS = [
  'Đang mua hàng',
  'Đang vận chuyển',
  'Đang giao tới quý khách',
  'Hoàn thành'
];
export const SHOPPING_STEPS = [
  { id: 1, path: Path.SHOPPING_CART, label: 'Giỏ hàng' },
  { id: 2, path: Path.SHOPPING_ORDER, label: 'Thanh toán' },
  { id: 3, path: Path.SHOPPING_COMPLETE, label: 'Hoàn tất' }
];

export const FLASH_SALE_CATEGORY = {
  value: 'top_order',
  label: 'Top order nhiều nhất',
  longLabel: 'Top sản phẩm được order nhiều nhất'
};
export const FOR_YOU_CATEGORY = {
  value: 'for-you',
  label: 'BuyNgon dành riêng cho bạn'
};
export const LOOKING_CATEGORY = {
  value: 'looking',
  label: 'Tìm kiếm với'
};
export const FAVORITE_CATEGORY = {
  value: 'favorite',
  label: 'Có thể bạn sẽ thích'
};

export const NotificationType = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
};

export const MessageContent = {
  error: 'Có lỗi xảy ra. Vui lòng tải lại trang',
  loginSuccess: 'Đăng nhập thành công',
  authFailed: 'Token hết hạn, vui lòng đăng nhập lại',
  notificationSaleOrder: 'Đơn hàng của bạn đã được cập nhật',
  notificationTracking: 'Tracking của đơn hàng đã được cập nhật',
  notificationDeposit: 'Số dư trong ví của bạn đã được cập nhật',
  notificationUpdateTimeTransaction: 'Đang kết nối'
};

export const HeaderListDepositManagement = [
  { value: '', name: '' },
  { value: 'Giao dịch', name: 'content' },
  { value: 'Ngày giao dịch', name: 'date' },
  { value: 'Số điểm', name: 'amount' },
  { value: 'Số dư', name: 'amount_after' }
];

export const HeaderReferralTable = [
  { value: '', name: '' },
  { value: 'Người dùng', name: 'user_info' },
  { value: 'Ngày đăng ký', name: 'create_account_date' },
  { value: '', name: '' },
  { value: 'Trạng thái', name: 'status' },
  { value: 'Điểm thưởng', name: 'reward_point' }
];

export const DepositSpacing = [
  { align: 'center', style: { maxWidth: 48, minWidth: 48 } },
  { align: 'left', style: { maxWidth: 300, minWidth: 300 } },
  { align: 'center', style: { maxWidth: 100, minWidth: 100 } },
  { align: 'right', style: { maxWidth: 125, minWidth: 125 } },
  { align: 'right', style: { maxWidth: 175, minWidth: 175 } }
];

export const ReferralTableSpacing = [
  { align: 'center', style: { maxWidth: 72, minWidth: 72 } },
  { align: 'left', style: { maxWidth: 200, minWidth: 200 } },
  { align: 'center', style: { maxWidth: 110, minWidth: 110 } },
  { align: 'center', style: { maxWidth: 50, minWidth: 50 } },
  { align: 'left', style: { maxWidth: 170, minWidth: 170 } },
  { align: 'right', style: { maxWidth: 100, minWidth: 100 } }
];

export const Regex = {
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&=]*)/gm,
  validURL: /(http:\/\/|https:\/\/)/,
  validText: /[^\w\s]/,
  subDomain: /(www\.)/,
  numberInput: /[^0-9]/g
};

export const SALE_PRICE_TYPE = [
  { label: 'Không có', value: 'none' },
  { label: 'Chiếc khấu (%)', value: '%' },
  { label: 'Số lượng ($)', value: '$' }
];

export const CURRENCY_STATE = {
  USD: {
    value: 'USD',
    unit: '$'
  },
  EUR: {
    value: 'EUR',
    unit: '€'
  },
  VND: {
    value: 'VND',
    unit: '₫'
  }
};

export const LOCATION_STATE = {
  USA: {
    value: 'USA',
    shortValue: 'US',
    currency: CURRENCY_STATE.USD.unit,
    currencyInfo: CURRENCY_STATE.USD,
    label: 'Mỹ'
  },
  ESP: {
    value: 'ESP',
    shortValue: 'ES',
    currency: CURRENCY_STATE.EUR.unit,
    currencyInfo: CURRENCY_STATE.EUR,
    label: 'Tây Ban Nha'
  },
  VN: {
    value: 'VN',
    shortValue: 'VI',
    currency: CURRENCY_STATE.VND.unit,
    currencyInfo: CURRENCY_STATE.VND,
    label: 'Việt Nam'
  }
};

export const CRAWL_DATA_VALUES = {
  link: '',
  image: '',
  name: '',
  price: '',
  color: '',
  size: '',
  quantity: 1,
  notice: ''
};

export const ALERT_MESSAGE_DURATION_TIME = 3 * 1000;

export const NOT_EXIST_TRACK = 'None';

export const BankTransferList = [
  // {
  //   path: Images.VCBIcon,
  //   name: 'Ngân hàng Ngoại thương Việt Nam - Vietcombank',
  //   value: 'VCB',
  //   account_number: '0071001182834',
  //   account_holder: 'Huỳnh Công Nguyên',
  //   branch: 'Chi nhánh TP. Hồ Chí Minh',
  //   location: 'TP. Hồ Chí Minh',
  //   sortName: 'Vietcombank'
  // },
  {
    path: Images.TCBIcon,
    name: 'Ngân hàng TMCP Kỹ Thương - Techcombank',
    value: 'TCB',
    account_number: '19027977333012',
    account_holder: 'Huỳnh Công Nguyên',
    branch: 'Chi nhánh TP. Hồ Chí Minh',
    location: 'TP. Hồ Chí Minh',
    sortName: 'Techcombank'
  }
  // {
  //   path: Images.ACBIcon,
  //   name: "Ngân hàng TMCP Á Châu - ACBank",
  //   value: "ACB",
  //   account_number: "225894419",
  //   account_holder: "Huỳnh Công Nguyên",
  //   branch: "Chi nhánh TP. Hồ Chí Minh",
  //   location: "TP. Hồ Chí Minh",
  //   sortName: "ACB"
  // }
];

export const StatusSaleOrder = [
  { value: 'none', label: 'Tất cả' },
  { value: 'buyngon_rejected', label: 'Buyngon đã huỷ' },
  { value: 'customer_confirmed', label: 'Đang chờ xác nhận' },
  { value: 'customer_rejected', label: 'Bạn đã huỷ' },
  { value: 'sale', label: 'Đã xác nhận' },
  { value: 'done', label: 'Hoàn Thành' },
  { value: 'cancel', label: 'Không Mua Được' }
];

export const TYPE_ADDRESS = [
  { name: 'Việt Nam', value: 'vietnam' },
  { name: 'Mỹ', value: 'usa' }
];

export const TYPE_QUOTE = {
  gift: { value: 'gift', label: 'Quà tặng' },
  product: { value: 'product', label: 'Sản phẩm cần mua' }
};
export const TYPE_SALE_ORDER_PATH = {
  odoo_id: 'sale_order_code',
  mongodb_id: 'sale_order_id'
};

export const PAYMENT_TYPE = {
  felixbuy: 'felixbuy',
  card: 'card',
  live: 'live',
  debt: 'debt'
};

export const STATUS_TRACKING = {
  us: [
    {
      label: 'Tất cả',
      value: 'all'
    },
    {
      label: 'Có tracking - chờ về kho Mỹ',
      value: 'us_shipping'
    },
    {
      label: 'Đã về kho Mỹ',
      value: 'purchase_partner_received'
    },
    {
      label: 'Đang trên đường về Việt Nam',
      value: 'forwarder_received'
    },
    {
      label: 'Đã về kho Việt Nam',
      value: 'felixbuy_received'
    },
    {
      label: 'Đã đóng gói - Chờ vận chuyển',
      value: 'packed'
    },
    {
      label: 'Đang giao hàng đến quý khách',
      value: 'shipping'
    },
    {
      label: 'Đã giao hàng đến quý khách',
      value: 'transfered'
    },
    {
      label: 'Đã hủy',
      value: 'cancel'
    }
  ],
  vn: [
    {
      label: 'Tất cả',
      value: 'all'
    },
    {
      label: 'Đã đóng gói - Chờ vận chuyển',
      value: 'draft'
    },
    {
      label: 'Đang giao hàng đến quý khách',
      value: 'shipping'
    },
    {
      label: 'Đã giao hàng đến quý khách',
      value: 'delivered'
    },
    {
      label: 'Đã hủy',
      value: 'cancelled'
    }
  ]
};

export const SOURCE_LIST = {
  WEBAPP: 'we',
  EXTENSION: 'ex'
};

export const DEFAULT_QUOTATION = {
  link: '',
  image: '',
  name: '',
  price: '',
  quantity: 1,
  color: '',
  size: '',
  note: '',
  sale_price: '',
  sale_price_type: '',
  notice: ''
};

export const NEW_DEFAULT_QUOTATION = {
  link: '',
  image: '',
  name: '',
  price: '',
  color: '',
  size: '',
  quantity: 1,
  notice: '',
  is_gift: false
};

export const GIFT_QUOTATION = {
  link: '',
  image: '',
  name: '',
  price: 0,
  quantity: 1,
  color: '',
  size: '',
  note: '',
  sale_price: '',
  sale_price_type: '',
  notice: ''
};
export const DEFAULT_ADDRESS = {
  name: '',
  phone: '',
  type_addr: { name: 'Việt Nam', value: 'vietnam' },
  city: { name: '', id: '' },
  district: { name: '', id: '' },
  ward: { name: '', id: '' },
  street: '',
  default_address: false
};

export const TYPE_PAYMENT = {
  createNew: 'CREATE_NEW_ADDRESS',
  list: 'LIST_ADDRESS',
  selected: 'SELECTED_ADDRESS',
  edit: 'EDIT_ADDRESS',
  create: 'CREATE_ADDRESS'
};

export const DEFAULT_SEARCH_VALUE_ORDER = { search: '', startDate: '', endDate: '' };

export const DANGER_NOTI =
  'Giá bên dưới chỉ là ước tính. Quý Khách cần phải kiểm tra và cung cấp đầy đủ thông tin về nhóm ngành hàng, trọng lượng, thuộc tính sản phẩm để có giá gần đúng nhất trước khi đặt hàng';
export const ORDER_NOTI = 'Đã đặt / Đã mua / Đã nhận';
export const TOOLTIP_FEE = `
Phí bao gồm phí BuyNgon,
phí vận chuyển, phụ thu  
(nếu có)
`;

export const BREADCRUMB_CATALOG = [{ value: 'Trang chủ', path: Path.HOME }];

export const BREADCRUMB_WEBSITE_US = [
  { value: 'Website', path: Path.WEBSITE_US },
  { value: 'Khuyến mãi Website', path: Path.PROMOTION_US }
];

export const TAG_CATALOG_NAME = {
  bestSeller: 'best-seller',
  popular: 'popular',
  // new: 'new',
  highPrice: 'high-price',
  lowPrice: 'low-price'
};

export const TAG_CATALOG = [
  { id: TAG_CATALOG_NAME.bestSeller, value: 'Bán chạy nhất' },
  { id: TAG_CATALOG_NAME.popular, value: 'Phổ biến' },
  // { id: TAG_CATALOG_NAME.new, value: 'Mới' },
  { id: TAG_CATALOG_NAME.highPrice, value: 'Giá cao' },
  { id: TAG_CATALOG_NAME.lowPrice, value: 'Giá thấp' }
];

export const PROVIDER_ID = {
  gg: 'google.com',
  fb: 'facebook.com',
  up: 'up'
};

export const SOCKET_MESSAGE = {
  ANONYMOUS_SYNC_SHOPPING_CART: 'ANONYMOUS_SYNC_SHOPPING_CART',
  LOGGED_SYNC_QUOTATION: 'LOGGED_SYNC_QUOTATION',
  LOGGED_NOTIFICATION: 'LOGGED_NOTIFICATION',
  LOGGED_SYNC_BANK: 'LOGGED_SYNC_BANK'
};

export const DEPOSIT_TYPE = {
  management: '',
  charge: 'Nạp',
  withdrawal: 'Rút'
};

export const ORDER_TRACKING_STATE = {
  DRAFT: {
    value: 'draft',
    label: 'Chờ xác nhận',
    sublable: 'Chờ BuyNgon xác nhận',
    sublable2: 'BuyNgon sẽ xác nhận khi bạn thanh toán đơn hàng.',
    include: 'customer_confirmed'
  },
  CONFIRMED: {
    value: 'confirmed',
    label: 'Đã xác nhận',
    sublable: 'Đã xác nhận, Chờ mua hàng',
    sublable2: 'Đã xác nhận đơn hàng và đang tiến hành mua hàng',
    include: 'confirmed'
  },
  PURCHASING: {
    value: 'purchasing',
    label: 'Mua hàng',
    sublable: 'Đang mua hàng',
    sublable2: ' Đang tiến hành mua hàng tại website nước ngoài',
    include: 'purchased,purchasing'
  },
  TRACKING: {
    value: 'tracking',
    label: 'Vận chuyển',
    sublable: 'Đang vận chuyển ',
    sublable2: 'Đang vận chuyển từ nước ngoài về VN',
    include: 'us_shipping,arrived_us,clear,buyngon_received'
  },
  DELIVERY: {
    value: 'delivery',
    label: 'Đang giao',
    sublable: 'Đang giao hàng',
    sublable2: 'Đơn hàng đang được giao đến bạn',
    include: 'vn_shipping'
  },
  DONE: {
    value: 'done',
    label: 'Hoàn thành',
    sublable: 'Hoàn thành',
    sublable2: '',
    include: 'transferred'
  },
  CANCEL: {
    value: 'cancel',
    label: 'Đã huỷ',
    sublable: 'Đã huỷ',
    sublable2: '',
    include: 'cancelled,buyngon_rejected,customer_rejected'
  }
};

export const SALE_ORDER_STATUS = {
  ordered: { value: 'ordered', label: 'Đơn hàng đã đặt' },
  waiting: { value: 'waiting', label: 'Chờ khách hàng xác nhận' },
  customer_confirmed: { value: 'customer_confirmed', label: 'Chờ BuyNgon xác nhận' },
  confirmed: { value: 'confirmed', label: 'Đã xác nhận - Chờ mua hàng' },
  purchasing: { value: 'purchasing', label: 'Đang mua hàng' },
  purchased: { value: 'purchased', label: 'Đã mua, Chờ vận chuyển ' },
  us_shipping: { value: 'us_shipping', label: 'Đang giao đến kho nước ngoài' },
  arrived_us: { value: 'arrived_us', label: 'Đã đến kho nước ngoài' },
  clear: { value: 'clear', label: 'Thông quan VN' },
  buyngon_received: {
    value: 'buyngon_received',
    label: 'Đã đến kho Việt Nam'
  },
  vn_shipping: { value: 'vn_shipping', label: 'Đang giao hàng tới bạn' },
  transferred: { value: 'transferred', label: 'Hoàn thành' },
  cancelled: { value: 'cancelled', label: 'Không mua được hàng' },
  buyngon_rejected: {
    value: 'buyngon_rejected',
    label: 'Đã hủy do chưa thanh toán'
  },
  customer_rejected: { value: 'customer_rejected', label: 'Bạn đã hủy' }
};

export const SALE_ORDER_RESPONSIVE_STATUS = {
  ordered: {
    value: 'ordered',
    label: 'Đơn hàng đã đặt',
    label_detail: 'Đơn hàng đã đặt',
    sub: 'Đơn hàng đã đặt'
  },
  waiting: {
    value: 'waiting',
    label: 'Chờ xác nhận',
    label_detail: 'Chờ xác nhận',
    sub: 'Chờ khách hàng xác nhận'
  },
  customer_confirmed: {
    value: 'customer_confirmed',
    label: 'Chờ xác nhận',
    label_detail: 'Chờ xác nhận',
    sub: 'Chờ BuyNgon xác nhận'
  },
  confirmed: {
    value: 'confirmed',
    label: 'Đã xác nhận',
    label_detail: 'Đã xác nhận',
    sub: 'Chờ mua hàng'
  },
  purchasing: {
    value: 'purchasing',
    label: 'Đang mua hàng',
    label_detail: 'Mua hàng',
    sub: 'Đang mua hàng'
  },
  purchased: {
    value: 'purchased',
    label: 'Đã mua hàng',
    label_detail: 'Mua hàng',
    sub: 'Đã mua hàng, Chờ vận chuyển '
  },
  us_shipping: {
    value: 'us_shipping',
    label: 'Đang vận chuyển',
    label_detail: 'Vận chuyển',
    sub: 'Đang đến kho nước ngoài'
  },
  arrived_us: {
    value: 'arrived_us',
    label: 'Đang vận chuyển',
    label_detail: 'Vận chuyển',
    sub: 'Đã đến kho nước ngoài'
  },
  clear: {
    value: 'clear',
    label: 'Đang vận chuyển',
    label_detail: 'Vận chuyển',
    sub: 'Thông quan Việt Nam'
  },
  buyngon_received: {
    value: 'buyngon_received',
    label: 'Đang vận chuyển',
    label_detail: 'Vận chuyển',
    sub: 'Đã đến kho Việt Nam'
  },
  vn_shipping: {
    value: 'vn_shipping',
    label: 'Đang giao',
    label_detail: 'Đang giao',
    sub: 'Đang giao tới bạn'
  },
  transferred: {
    value: 'transferred',
    label: 'Hoàn thành',
    label_detail: 'Hoàn thành',
    sub: 'Cảm ơn bạn đã tin dùng BuyNgon'
  },
  cancelled: {
    value: 'cancelled',
    label: 'Đã hủy',
    label_detail: 'Đã hủy',
    sub: 'Không mua được hàng'
  },
  buyngon_rejected: {
    value: 'buyngon_rejected',
    label: 'Đã hủy',
    label_detail: 'Đã hủy',
    sub: 'Đã hủy do chưa thanh toán'
  },
  customer_rejected: {
    value: 'customer_rejected',
    label: 'Đã hủy',
    label_detail: 'Đã hủy',
    sub: 'Bạn đã hủy'
  }
};

export const ORDER_LINE_STATUS = {
  customer_confirmed: { value: 'customer_confirmed', label: 'Chờ xác nhận' },
  confirmed: { value: 'confirmed', label: 'Đã xác nhận, chờ mua hàng' },
  purchasing: { value: 'purchasing', label: 'Đã xác nhận, đang mua hàng' },
  purchased: { value: 'purchased', label: 'Đã mua hàng, chờ vận chuyển ' },
  us_shipping: { value: 'us_shipping', label: 'Đã mua hàng, đang vận chuyển' },
  arrived_us: { value: 'arrived_us', label: 'Đã đến kho nước ngoài' },
  clear: { value: 'clear', label: 'Thông quan VN' },
  buyngon_received: {
    value: 'buyngon_received',
    label: 'Đã đến kho Việt Nam'
  },
  vn_shipping: { value: 'vn_shipping', label: 'Đang giao đến bạn' },
  transferred: { value: 'transferred', label: 'Giao hàng thành công' },
  cancelled: { value: 'cancelled', label: 'Không mua được hàng' },
  buyngon_rejected: {
    value: 'buyngon_rejected',
    label: 'Đã hủy'
  },
  customer_rejected: { value: 'customer_rejected', label: 'Bạn đã hủy' },
  waiting: { value: 'waiting', label: 'Chờ xác nhận' }
};

export const TRACKING_STATUS = {
  customer_confirmed: { value: 'customer_confirmed', label: 'Chờ xác nhận' },
  waiting: { value: 'waiting', label: 'Chờ xác nhận' },
  confirmed: { value: 'confirmed', label: 'Chờ mua hàng' },
  purchasing: { value: 'purchasing', label: 'Đang mua hàng' },
  purchased: { value: 'purchased', label: 'Đã mua, Chờ vận chuyển ' },

  us_shipping: { value: 'us_shipping', label: 'Đang giao đến kho tại nước ngoài' },
  purchase_partner_received: {
    value: 'purchase_partner_received',
    label: 'Đang giao tới kho tại nước ngoài'
  },
  forwarder_received: {
    value: 'forwarder_received',
    label: 'Đã đến kho nước ngoài'
  },
  felixbuy_received: { value: 'felixbuy_received', label: 'Thông quan VN' },
  packed: { value: 'packed', label: 'Đã đến kho VN - Chờ giao hàng' },
  shipping: { value: 'shipping', label: 'Đang giao đến bạn' },
  transfered: { value: 'transfered', label: 'Giao hàng thành công' },
  cancel: { value: 'cancel', label: 'Giao hàng thất bại' },
  customer_rejected: { value: 'customer_rejected', label: 'Bạn đã hủy' },
  buyngon_rejected: { value: 'buyngon_rejected', label: 'BuyNgon đã hủy' }
};

export const SUB_JOURNEY_STEP = [
  {
    key: 'created',
    label: 'Đã tạo đơn hàng'
  },
  {
    key: 'confirmed',
    label: 'Đã thanh toán'
  },
  {
    key: 'purchased',
    label: 'Đã mua hàng thành công'
  },
  {
    key: 'InTransit',
    label: 'Đang vận chuyển đến kho nước ngoài'
  },
  {
    key: 'OutForDelivery',
    label: 'Chuẩn bị nhận tại kho'
  },
  {
    key: 'arrived_us',
    label: 'Đã đến kho nước ngoài'
  },
  {
    key: 'clear',
    label: 'Đã thông quan'
  },
  {
    key: 'buyngon_received',
    label: 'Đã đóng gói'
  },
  {
    key: 'vn_shipping',
    label: 'Đang giao đến bạn'
  },
  {
    key: 'PUP',
    label: 'Tài xế lấy hàng thành công'
  },
  {
    key: 'SIP-L',
    label: 'Tài xế đang giao hàng'
  },
  {
    key: 'SOP-D',
    label: 'Tài xế đang giao hàng'
  },
  {
    key: 'transferred',
    label: 'Hoàn thành'
  }
];

export const VN_TRACKING_STATE = {
  DRAFT: {
    value: ['draft'],
    label: 'Chưa giao'
  },
  SHIPPING: {
    value: ['shipping'],
    label: 'Đang giao'
  },
  DELIVERED: {
    value: ['delivered'],
    label: 'Đã giao'
  }
};

export const SHIPPING_STATE = {
  TRACKING_ORDER: {
    value: 'tracking_order',
    label: 'Theo dõi đơn ship hộ'
  },
  US_VN: {
    value: 'us_vn',
    label: 'Bảng giá ship hộ Mỹ - VN'
  },
  ES_VN: {
    value: 'es_vn',
    label: 'Bảng giá ship hộ TBN - VN'
  }
};

export const NOTIFICATION_STATE = {
  ALL: {
    label: 'Tất cả'
  },
  // GENERAL: {
  //   label: 'Cập nhật chung'
  // },
  // PURCHASING: {
  //   label: 'Đang mua'
  // },
  ORDER: {
    label: 'Đơn hàng'
  },
  // AT_US: {
  //   label: 'Đã đến kho Mỹ'
  // },
  // CLEARANCE_VN: {
  //   label: 'Thông quan'
  // },
  DELIVERED: {
    label: 'Giao hàng'
  },
  PAYMENT: {
    label: 'Số dư'
  },
  PROMOTION: {
    label: 'Khuyến mãi'
  }
};
export const NotifyTypes = {
  RECHARGE_SUCCESS: 'RECHARGE_SUCCESS',
  WITHDRAWL_SUCCESS: 'WITHDRAWL_SUCCESS',
  RECHARGE_REQUEST_SUCCESS: 'RECHARGE_REQUEST_SUCCESS',
  WITHDRAWL_REQUEST_SUCCESS: 'WITHDRAWL_REQUEST_SUCCESS',
  CUSTOMER_CREATE_SO: 'CUSTOMER_CREATE_SO',
  CUSTOMER_REJECT_SO: 'CUSTOMER_REJECT_SO',
  SYSTEM_REJECT_SO_24H: 'SYSTEM_REJECT_SO_24H',
  ADMIN_CONFIRM_SO: 'ADMIN_CONFIRM_SO',
  ADMIN_REJECT_SO: 'ADMIN_REJECT_SO',
  SALE_CREATE_SO: 'SALE_CREATE_SO',
  PURCHASED_WATTING_TRACK: 'PURCHASED_WATTING_TRACK',
  PURCHASE_FAILED: 'PURCHASE_FAILED',
  PURCHASE_AND_CANCEL_A_PART: 'PURCHASE_AND_CANCEL_A_PART',
  PURCHASED_AND_WEB_CANCEL: 'PURCHASED_AND_WEB_CANCEL',
  RECEIVED_US_INVENTORY: 'RECEIVED_US_INVENTORY',
  CLEARANCE_VN: 'CLEARANCE_VN',
  DELIVERED: 'DELIVERED'
};

export const VN_TRACKING_STATUS = {
  draft: { value: 'draft', label: 'Chưa giao' },
  shipping: { value: 'shipping', label: 'Đang giao' },
  delivered: { value: 'delivered', label: 'Đã giao' }
};

export const LOGS_TYPE = {
  autoCancel: 'AUTO_CANCEL',
  moneyWithdrawlRequest: 'WITHDRAWL_REQUEST',
  moneyTranferRequest: 'TRANFER_REQUEST',
  phoneOTP: 'PHONE_OTP'
};

export const DEBT_TYPE = [
  {
    value: 'all',
    label: 'Loại giao dịch'
  },
  {
    value: 'order_payment',
    label: 'Thanh toán đơn hàng',
    icon: Icons.orderIcon
  },
  {
    value: 'shipping_payment',
    label: 'Thanh toán phí vận chuyển',
    icon: Icons.shippingIcon
  },
  {
    value: 'recharge',
    label: 'Nạp điểm',
    icon: Icons.rechargeIcon
  },
  {
    value: 'receive',
    label: 'Nhận điểm',
    icon: Icons.rechargeIcon
  },
  {
    value: 'withdraw',
    label: 'Rút điểm',
    icon: Icons.withdrawIcon
  },
  {
    value: 'deduction',
    label: 'Trừ điểm',
    icon: Icons.withdrawIcon
  },
  {
    value: 'refund_order',
    label: 'Hoàn điểm đơn hàng',
    icon: Icons.refundIcon
  },
  {
    value: 'refund_ship',
    label: 'Hoàn điểm vận chuyển',
    icon: Icons.refundIcon
  }
];

export const USES_CASE_CONVERT_CURRENCY = {
  positive: 'positive',
  negative: 'negative',
  default: 'default',
  all: 'all',
  hide: 'hide'
};

export const ORDER_TYPE = {
  saleOrder: 'salerorder',
  vnTracking: 'vntracking'
};

export const TRANSACTION = {
  TYPE: {
    BANKING: 'banking',
    MOMO: 'momo',
    COIN: 'coin'
  }
};

export const STEP_BUY_GUIDE = [
  { step: 1, value: 'Điền thông tin sản phẩm cần mua' },
  { step: 2, value: 'Cho vào Giỏ hàng' },
  { step: 3, value: 'Đi đến Giỏ hàng để xem giá' },
  { step: 4, value: 'Thanh toán để hoàn tất' }
];
