import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DollarIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M21.25 8.5H19.06C18.53 7.67 17.83 6.98 17 6.47V3.25C17 2.84 16.66 2.5 16.25 2.5C14.4 2.5 12.82 3.78 12.37 5.51H10.43C7.23 5.51 4.58 7.84 4.09 10.9C3.76 10.55 3.5 10 3.5 9.5V8.25C3.5 7.84 3.16 7.5 2.75 7.5C2.34 7.5 2 7.84 2 8.25V9.5C2 10.75 2.84 12.18 4.02 12.61C4.17 14.41 5.07 16.05 6.5 17.14V19.75C6.5 20.16 6.84 20.5 7.25 20.5H10.25C10.66 20.5 11 20.16 11 19.75V18.5H13V19.75C13 20.16 13.34 20.5 13.75 20.5H16.75C17.16 20.5 17.5 20.16 17.5 19.75V17.2C18.58 16.4 19.36 15.29 19.77 14H21.25C21.66 14 22 13.66 22 13.25V9.25C22 8.84 21.66 8.5 21.25 8.5ZM20.5 12.5H19.19C18.84 12.5 18.53 12.75 18.46 13.09C18.18 14.38 17.43 15.47 16.34 16.18C16.13 16.32 16 16.56 16 16.81V19H14.5V17.75C14.5 17.34 14.16 17 13.75 17H10.25C9.84 17 9.5 17.34 9.5 17.75V19H8V16.75C8 16.5 7.87 16.26 7.66 16.12C6.31 15.23 5.5 13.71 5.5 12.07V11.94C5.5 9.22 7.71 7.01 10.43 7.01H13.01C13.4 7.01 13.73 6.71 13.76 6.31C13.83 5.27 14.55 4.42 15.5 4.12V6.91C15.5 7.19 15.66 7.45 15.91 7.58C16.78 8.03 17.5 8.73 17.97 9.61C18.1 9.85 18.35 10 18.63 10H20.5V12.5Z" />
    </SvgIcon>
  );
}
