import { filterListOrderDeal } from '../../utils/helpers';
import TYPE from '../actions/TYPE';

const initialState = {
  draft: {
    state: '',
    created_at: '',
    amount: 100,
    product_deal_id: 0,
    history: {
      id: null,
      name: '',
      brand: null,
      avatar: '',
      price: 0,
      rate: 0,
      buy_step: 0,
      expired_date: '',
      time_open_sell: '',
      time_end_sell: '',
      rating: 0,
      is_promote: true,
      description_html: '',
      provider_id_id: null,
      topic_id_id: null,
      price_in_used: 0
    }
  },
  current: {},
  complete: {},
  flashSale: { list: null, filter: null },
  detail: {
    selected: {},
    backup: null
  },
  giftcardDetail: {},
  listManagement: [],
  filterListManagement: [],
  productOrder: [],
  giftcardUpdate: null,
  dealPurchaseReject: false,

  dealDetail: {}
};

const deal = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_DEAL_DETAIL_SUCCESS:
      return {
        ...state,
        dealDetail: action.payload
      };

    case TYPE.UPDATE_PRICE_IN_USED:
      return {
        ...state,
        listManagement: state.listManagement.map(item => {
          const { giftCardId, pricesGiftcard } = action.optional;
          if (item._id === giftCardId)
            return {
              ...item,
              price_in_used: item.price_in_used + pricesGiftcard.total
            };
          return item;
        })
      };

    case TYPE.LOGOUT_SUCCESS:
      return {
        ...state,
        listManagement: [],
        filterListManagement: [],
        flashSale: { list: [], filter: [] }
      };

    case TYPE.CLEAR_DETAIL_DEAL_ORDER:
      return {
        ...state,
        giftcardDetail: {}
      };
    case TYPE.FILTER_ORDER_DEAL_MANAGEMENT:
      return {
        ...state,
        filterListManagement: filterListOrderDeal(state.listManagement, action.data)
      };

    case TYPE.ADD_DEAL_QUOTATION:
      return {
        ...state,
        draft: action.data
      };

    case TYPE.CLEAR_DEAL_QUOTATION:
      return {
        ...state,
        draft: null
      };

    case TYPE.SAVE_BACKUP_DEAL_CATALOG: {
      return {
        ...state,
        detail: {
          ...state.detail,
          backup: action.payload
        }
      };
    }

    case TYPE.CLEAR_DEAL_DETAIL: {
      return {
        ...state,
        detail: {
          selected: {},
          backup: null
        }
      };
    }

    case TYPE.GET_GIFT_CARD_UPDATE: {
      return {
        ...state,
        giftcardUpdate: action.payload
      };
    }
    case TYPE.CLEAR_GIFT_CARD_UPDATE: {
      return {
        ...state,
        giftcardUpdate: null
      };
    }

    case TYPE.GET_GIFT_CARD_REJECT: {
      return {
        ...state,
        dealPurchaseReject: true
      };
    }
    case TYPE.CLEAR_DEAL_PURCHASE_REJECT: {
      return {
        ...state,
        dealPurchaseReject: false
      };
    }

    default:
      return state;
  }
};

export default deal;
