import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function CheckedIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill={theme.palette.primary.main} />
      <circle cx="12" cy="12" r="4" fill={theme.palette.common.white} />
    </SvgIcon>
  );
}
