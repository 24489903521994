import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { any, func, object, string } from 'prop-types';
import React, { Suspense } from 'react';
import { getImagesNotification } from '../../../utils/helpers';
import { typeIconAndRedirect } from '../../../utils/dynamicHelpers';
import Image from '../Image';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    border: '1px solid #E1E2E5',
    padding: theme.spacing(2),
    boxShadow: '0px 24px 56px -16px rgba(134, 135, 143, 0.3)',
    width: 459,
    // maxWidth: '30%',
    // minWidth: 459,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#F5F5F7'
    },
    [theme.breakpoints.down('sm')]: {
      // maxWidth: 'auto',
      // minWidth: '100%'
      maxWidth: 459,
      width: 'calc(100vw - 30px)'
    }
  },
  image: {
    objectFit: 'contain',
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: 12
  },
  title: {
    fontWeight: 700,
    marginBottom: 8
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  icon: {
    position: 'absolute',
    bottom: -4,
    right: -4,
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  description: {
    wordWrap: 'break-word',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    wordBreak: 'break-word'
  }
}));

export default function PopupRealtime({
  snackbarId,
  avatar,
  title,
  description,
  onDetail,
  type,
  action,
  closeSnackbar
}) {
  const classes = useStyles();

  const handleClose = event => {
    event.stopPropagation();
    closeSnackbar(snackbarId);
  };

  const { Image: ImageIcon } = typeIconAndRedirect(type, action);

  return (
    <Box className={classes.container} onClick={onDetail(snackbarId)}>
      <Box position="relative" mr="20px">
        <Image
          src={getImagesNotification(avatar || '', type)}
          alt="avatar"
          className={classes.image}
        />
        <Suspense fallback={<div />}>
          <ImageIcon className={classes.icon} />
        </Suspense>
      </Box>

      <Box width="calc(100% - 64px - 50px)">
        <Typography variant="body2" className={classes.title} noWrap>
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          className={classes.description}
        >
          {description}
        </Typography>
      </Box>

      <IconButton size="small" className={classes.close} onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

PopupRealtime.propTypes = {
  snackbarId: any.isRequired,
  closeSnackbar: func.isRequired,
  onDetail: func,
  avatar: string,
  title: string,
  description: string,
  type: string,
  action: object
};

PopupRealtime.defaultProps = {
  onDetail: () => {},
  avatar: '',
  title: '',
  description: '',
  type: '',
  action: {}
};
