import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const CustomizedIconButton = withStyles(theme => ({
  root: {
    padding: 8,
    backgroundColor: '#F5F5F7',
    [theme.breakpoints.down('sm')]: {
      height: 32,
      width: 32
    }
  }
}))(props => <IconButton {...props} />);

export default CustomizedIconButton;
