import { HOST_PRODUCT, HOST, HOST_NEST } from '../utils/domain';

export default {
  REORDER_SALE_ORDER: `${HOST_NEST}/sale-order/reorder-sale-order`,

  POST_MANUAL_SIGN_IN: `${HOST}/mp/customer/auth/account-sign-in`,

  POST_SIGN_IN: `${HOST}/mp/customer/auth/google-sign-in`,

  POST_SIGN_IN_WITH_OAUTH2: `${HOST}/mp/customer/auth/facebook-sign-in`,

  POST_SIGN_IN_ONE_TAB: `${HOST}/customer/auth/login-one-tab`,

  SIGN_UP_MANUAL: `${HOST}/customer/auth/sign-up`,

  POST_REFRESH_TOKEN: `${HOST}/customer/auth/refresh-token`,

  PUT_UPDATE_FCM_TOKEN: `${HOST}/customer/user/fcm-token`,

  POST_CREATE_SALE_ORDER: `${HOST}/customer/sale-orders/create`,

  GET_LIST_CHECKER: `${HOST_NEST}/customer/checker-profile/available`,

  PUT_UPDATE_SALE_ORDER: `${HOST}/customer/sale-orders/update`,

  PUT_UPDATE_QUOTATION: id => `${HOST}/customer/sale-orders/update/${id}`,

  GET_LIST_FAVORITE_DEAL: `${HOST_NEST}/customer/deal`,

  GET_LIST_SALE_ORDER: `${HOST}/customer/sale-orders/list`,

  GET_SALE_ORDER: `${HOST}/customer/sale-orders/detail`,

  GET_PROVINCE: `${HOST}/public/location/province`,

  GET_DISTRICT: provinceId =>
    `${HOST}/public/location/province/${provinceId}/district`,

  GET_WARD: (provinceId, districtId) =>
    `${HOST}/public/location/province/${provinceId}/district/${districtId}/ward`,

  // UPLOAD_IMAGE: `${HOST}/public/upload/image`,
  UPLOAD_IMAGE: `${HOST}/mp/customer/s3/upload/image`,

  UPLOAD_FILE: `${HOST}/public/upload/file-webapp`,

  TRACKING_LIST: `${HOST}/customer/tracking/list`,

  TRACKING_DETAIL: `${HOST}/customer/tracking/detail`,

  GET_CONFIGURATION: `${HOST}/public/configuration`,

  PUT_USER_PROFILE_PREFIX: `${HOST}/customer/user/update-prefix`,

  POST_VERIFY_EMAIL: `${HOST}/customer/user/verify-email`,

  // improvement
  GET_SELECTED_SALE_ORDER: `${HOST}/customer/sale-orders/selected`,

  GET_WEBSITE_DISABLE_US: `${HOST}/customer/website/disable`,

  GET_WEBSITE_CRAWLER: `${HOST}/customer/website/crawler`,

  FORGOT_PASSWORD: `${HOST}/customer/auth/forgot-password`,

  CHANGE_PASSWORD: `${HOST}/customer/auth/forgot-password-verify`,

  VERIFY_EMAIL_AND_AUTO_LOGIN: `${HOST}/mp/customer/auth/verify-manual-sign-up`,

  SIGN_UP_PHONE: `${HOST}/customer/auth/sign-up-phone`,

  VERIFY_PHONE: `${HOST}/mp/customer/user/verify-phone`,

  IGNORE_VERIFY_PHONE: `${HOST}/customer/auth/sign-up-phone-ignore`,

  RESEND_EMAIL: `${HOST}/customer/auth/resend-email-verify`,

  CLOSE_EXTENSION: `${HOST}/customer/user/extension-ignore`,

  GET_SHIPPING_CATEGORIES: `${HOST}/customer/sale-orders/shipping-categories`,

  // GET_LIST_PROMOTION: `${HOST}/mp/customer/user/coupon`,
  GET_LIST_PROMOTION: `${HOST_NEST}/sale-coupon-program`,

  // INPUT_PROMOTION: code => `${HOST}/customer/sale-orders/promotion-input/${code}`,
  INPUT_PROMOTION: code => `${HOST_NEST}/sale-coupon-program/reserved/${code}`,

  GET_SHIPPING_INFO: website =>
    `${HOST_PRODUCT}/stock/website/list?website=${website}`,

  POST_SCRAPER: `${HOST_PRODUCT}/scraper`,

  GET_LIST_CATALOG_LOOKING: `${HOST_PRODUCT}/stock/search`,

  GET_LIST_FOR_YOU: `${HOST_PRODUCT}/stock/product/foryou`,

  GET_DETAIL_CATALOG_AWS: slug_id => `${HOST_PRODUCT}/stock/product/${slug_id}`,

  GET_LIST_FAVORITE: `${HOST_PRODUCT}/stock/product/favorite`,

  GET_LIST_FLASH_SALE_AWS: `${HOST_PRODUCT}/stock/product/tags`,

  GET_CATEGORIES: `${HOST_PRODUCT}/stock/category/list`,

  GET_PRODUCT_BY_CATEGORY: slug_id => `${HOST_PRODUCT}/stock/category/${slug_id}`,

  UPDATE_FIRST_LOGIN: `${HOST}/mp/customer/user/update-first-login`,

  PUT_TO_LOGGED_CART: anonymous_id =>
    `${HOST_NEST}/cart-anonymous/push-to-logged-cart/${anonymous_id}`,

  GET_DETAIL_MANAGEMENT: id => `${HOST}/customer/order-tracking/detail/${id}`,

  GET_TRACKING_DETAIL: sale_order_id =>
    `${HOST}/customer/order-tracking/tracking-of-sale-order/${sale_order_id}`,

  GET_TRACKING_JOURNEY: (id, type) =>
    `${HOST}/customer/order-tracking/journeys/${id}/${type}`,

  GET_TRACKING_JOURNEY_BY_ORDER: id =>
    `${HOST}/customer/order-tracking/tracking-and-journey/${id}`,

  GET_LIST_VN_TRACKING: `${HOST}/customer/vn-tracking/list`,

  GET_VN_TRACKING_DETAIL: tracking_id =>
    `${HOST}/customer/vn-tracking/detail/${tracking_id}`,

  // CONFIRM_VN_TRACKING: `${HOST}/mp/customer/user/send-money-tranfer-request`,
  CONFIRM_VN_TRACKING: `${HOST_NEST}/customer/user/send-top-up-request`,

  DETAIL_CONFIRM_VN_TRACKING: id =>
    `${HOST}/mp/customer/user/get-money-tranfer-request/${id}`,

  GET_TRANSFER_REQUEST: `${HOST}/mp/customer/user/get-money-tranfer-request`,

  GET_NOTIFICATION: `${HOST_NEST}/customer/notification`,
  // GET_LIST_BANK: type => `${HOST}/customer/bank/${type}`,
  GET_LIST_BANK: type => `${HOST}/mp/customer/bank-transfer/detail/${type}`,
  MARK_AS_READ_NOTIFICATION: `${HOST_NEST}/customer/notification/read`,

  GET_CATEGORY_DETECT: `${HOST_PRODUCT}/stock/category/detect`,

  GET_MONEY_REQUEST: `${HOST}/customer/user/get-money-request`,

  SEND_MONEY_TRANFER_REQUEST: `${HOST_NEST}/customer/user/send-top-up-request`,

  GET_REFERRAL: `${HOST}/customer/referral`,

  GET_LIST_WEBSITE_FILTER_CATALOG: `${HOST_PRODUCT}/stock/website/list`,

  EXCHANGE_POINT: `${HOST}/customer/point/withdraw`,

  SEND_MONEY_WITHDRAW_REQUEST: `${HOST_NEST}/customer/user/send-withdraw-request`,

  GET_LIST_PRODUCT_DEAL_HOME: `${HOST_PRODUCT}/stock/category/home`,

  GET_LIST_TRANSACTION: `${HOST}/mp/customer/user/transaction`,

  GET_DETAIL_TRANSACTION: id => `${HOST}/mp/customer/user/payment/${id}`,

  // UPDATE_WAITING_SALE_ORDER: `${HOST_NEST}/sale-order/verify-sale-order`,
  UPDATE_WAITING_SALE_ORDER: `${HOST}/mp/customer/sale-order-v2/update-waiting-sale-order`,

  CHECK_LOGIN_PROVIDER: `${HOST}/mp/customer/user/login-providers`,

  CHANGE_USER_PASSWORD: `${HOST}/mp/customer/user/change-password`,

  REGISTER_SHIPPING_SERVICE: `${HOST}/mp/customer/order-shipping/register`,

  GET_LIST_ADDRESS_SHIPPING: `${HOST}/mp/customer/order-shipping/address`,

  GET_LIST_TRACKING_SHIPPING: `${HOST}/mp/customer/order-shipping/tracking`,

  CHECK_PRICE_PRODUCT: `${HOST_NEST}/cart/check-price`,

  GET_DEBT_RECOVERY: id => `${HOST}/mp/customer/debt-recovery/${id}`,

  // Move api v1 to v2
  // DELETE_QUOTATION: id => `${HOST}/mp/customer/cart/${id}`,
  DELETE_QUOTATION: id => `${HOST_NEST}/cart/${id}`,

  GET_LIST_CHECKER_PROFILE: id => `${HOST_NEST}/sale-order/checker-info/${id}`,

  GET_LIST_CHECKER_AVAILABLE: `${HOST_NEST}/customer/checker-profile/available`,

  // GET_CURRENT_SALE_ORDER: `${HOST}/mp/customer/cart`,
  GET_CURRENT_SALE_ORDER: `${HOST_NEST}/cart`,

  // POST_CONFIRM_SALE_ORDER: `${HOST}/mp/customer/sale-order-v2/confirm`,
  POST_CONFIRM_SALE_ORDER: `${HOST_NEST}/pay/confirm`,

  // POST_CONFIRM_SHOPPING_CART: `${HOST}/mp/customer/sale-order-v2/order`,
  POST_CONFIRM_SHOPPING_CART: `${HOST_NEST}/checkout/order`,

  // ADD_SHOPPING_CART: `${HOST}/mp/customer/cart`,
  ADD_SHOPPING_CART: `${HOST_NEST}/cart`,

  // EDIT_SALE_ORDER_IMPROVEMENT: id => `${HOST}/mp/customer/cart/${id}`,
  EDIT_SALE_ORDER_IMPROVEMENT: id => `${HOST_NEST}/cart/${id}`,

  // GET_PROMOTION: `${HOST}/mp/customer/store-promotion`,
  GET_PROMOTION: `${HOST_NEST}/store-promotion`,

  // GET_PROMOTION_ANONYMOUS: anonymous_id =>
  //   `${HOST}/mp/customer/store-promotion/anonymous/${anonymous_id}`,
  GET_PROMOTION_ANONYMOUS: anonymous_id =>
    `${HOST_NEST}/store-promotion/anonymous/${anonymous_id}`,

  // CREATE_NEW_PROMOTION: `${HOST}/mp/customer/store-promotion`,
  CREATE_NEW_PROMOTION: `${HOST_NEST}/store-promotion`,

  // DELETE_PROMOTION: `${HOST}/mp/customer/store-promotion/quotation`,
  DELETE_PROMOTION: `${HOST_NEST}/store-promotion`,

  // ADD_SHOPPING_CART_ANONYMOUS: `${HOST}/customer/anonymous/shopping-cart/add`,
  ADD_SHOPPING_CART_ANONYMOUS: `${HOST_NEST}/cart-anonymous`,

  // EDIT_SALE_ORDER_ANONYMOUS: id =>
  //   `${HOST}/customer/anonymous/shopping-cart/edit/${id}`,
  EDIT_SALE_ORDER_ANONYMOUS: cart_item_id =>
    `${HOST_NEST}/cart-anonymous/${cart_item_id}`,

  // GET_SHOPPING_CART_ANONYMOUS: id =>
  //   `${HOST}/customer/anonymous/shopping-cart/${id}`,
  GET_SHOPPING_CART_ANONYMOUS: anonymous_id =>
    `${HOST_NEST}/cart-anonymous/${anonymous_id}`,

  // DELETE_SHOPPING_CART_ANONYMOUS: ({ id, anonymous_id }) =>
  //   `${HOST}/customer/anonymous/shopping-cart/${anonymous_id}/${id}`,
  DELETE_SHOPPING_CART_ANONYMOUS: ({ id, anonymous_id }) =>
    `${HOST_NEST}/cart-anonymous/${anonymous_id}/${id}`,

  // PUT_UPDATE_USER_PROFILE: `${HOST}/customer/user/profile`,
  PUT_UPDATE_USER_PROFILE: `${HOST_NEST}/customer/user`,

  // GET_USER_PROFILE: `${HOST}/customer/auth/profile`,
  GET_USER_PROFILE: `${HOST_NEST}/customer/user`,

  // PUT_SELECT_ADDRESS: addressId =>
  //   `${HOST}/mp/customer/user/address-sellected/${addressId}`,

  // EDIT_DELIVERY_ADDRESS: id => `${HOST}/mp/customer/user/update-address/${id}`,

  // POST_CREATE_NEW_ADDRESS: `${HOST}/mp/customer/user/address`,

  // RESET_DELIVERY_ADDRESS: addressId =>
  //   `${HOST}/mp/customer/user/address-sellected-return-full-cart/${addressId}`,

  // DELETE_ADDRESS: addressId => `${HOST}/mp/customer/user/address/${addressId}`,

  PUT_SELECT_ADDRESS: addressId => `${HOST_NEST}/user-address/selected/${addressId}`,

  POST_CREATE_NEW_ADDRESS: `${HOST_NEST}/user-address`,

  RESET_DELIVERY_ADDRESS: addressId =>
    `${HOST_NEST}/user-address/selected-return-full-cart/${addressId}`,

  DELETE_ADDRESS: addressId => `${HOST_NEST}/user-address/${addressId}`,

  EDIT_DELIVERY_ADDRESS: addressId => `${HOST_NEST}/user-address/${addressId}`,

  GET_DEAL_DETAIL: deal_id => `${HOST_NEST}/customer/deal/${deal_id}`,

  DELETE_SELECTED_CART_ITEMS: `${HOST_NEST}/cart`,
  DELETE_SELECTED_ANONYMOUS_CART_ITEMS: `${HOST_NEST}/cart-anonymous`,

  GET_ORDER_PURCHASE_REVIEW: order_id =>
    `${HOST_NEST}/sale-order/purchase-review/${order_id}`
};
