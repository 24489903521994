import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ProfileIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M18.9 4.75998C16.96 2.91998 14.43 1.93998 11.76 1.99998C9.09004 2.06998 6.60004 3.16998 4.76004 5.09998C2.92004 7.03998 1.94004 9.56998 2.00004 12.24C2.07004 14.91 3.17004 17.4 5.10004 19.24C5.18004 19.32 5.26004 19.39 5.35004 19.46C7.19004 21.11 9.52004 22 11.99 22H12.24C14.63 21.94 16.88 21.05 18.65 19.46C18.85 19.29 19.05 19.1 19.24 18.9C21.03 17.02 22 14.58 22 11.99V11.76C21.93 9.08998 20.83 6.59998 18.9 4.75998ZM12.21 20.5C10.19 20.54 8.26004 19.89 6.71004 18.64C7.16004 17.38 8.39004 16.51 9.75004 16.51H14.25C15.61 16.51 16.84 17.38 17.29 18.64C15.85 19.81 14.09 20.45 12.21 20.5ZM18.44 17.53C17.63 16.02 16.03 15.01 14.25 15.01H9.75004C7.97004 15.01 6.36004 16.02 5.56004 17.54C4.27004 16.06 3.55004 14.2 3.50004 12.21C3.45004 9.93998 4.28004 7.77998 5.85004 6.13998C7.41004 4.48998 9.52004 3.55998 11.79 3.49998H12.01C14.2 3.49998 16.27 4.32998 17.86 5.84998C18.68 6.62998 19.33 7.54998 19.78 8.54998C20.22 9.55998 20.47 10.66 20.5 11.79C20.55 13.91 19.83 15.94 18.44 17.53Z" />
      <path d="M14.75 13.5H11.75C9.96 13.5 8.5 12.04 8.5 10.25V8.25C8.5 6.46 9.96 5 11.75 5H12.25C14.04 5 15.5 6.46 15.5 8.25V12.75C15.5 13.16 15.16 13.5 14.75 13.5ZM11.75 6.5C10.79 6.5 10 7.29 10 8.25V10.25C10 11.21 10.79 12 11.75 12H14V8.25C14 7.29 13.21 6.5 12.25 6.5H11.75Z" />
    </SvgIcon>
  );
}
