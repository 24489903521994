/* eslint-disable max-len */
import { SvgIcon, useTheme } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function ToggleCheckedIcon({ color, ...props }) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Toggle">
        <g id="Tick Square">
          <path
            id="Tick Square_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.67 2H16.34C19.73 2 22 4.38 22 7.92V16.091C22 19.62 19.73 22 16.34 22H7.67C4.28 22 2 19.62 2 16.091V7.92C2 4.38 4.28 2 7.67 2ZM11.43 14.99L16.18 10.24C16.52 9.9 16.52 9.35 16.18 9C15.84 8.66 15.28 8.66 14.94 9L10.81 13.13L9.06 11.38C8.72 11.04 8.16 11.04 7.82 11.38C7.48 11.72 7.48 12.27 7.82 12.62L10.2 14.99C10.37 15.16 10.59 15.24 10.81 15.24C11.04 15.24 11.26 15.16 11.43 14.99Z"
            fill={color || theme.palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

ToggleCheckedIcon.propTypes = {
  color: string
};

ToggleCheckedIcon.defaultProps = {
  color: ''
};
