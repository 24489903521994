import RESTFul from './Restful';
import API from './API';

export default {
  profile: {
    signIn: payload => RESTFul.post(API.POST_SIGN_IN, payload),
    signInWithOAuth2: data => RESTFul.post(API.POST_SIGN_IN_WITH_OAUTH2, data),
    signInOneTab: profile => RESTFul.post(API.POST_SIGN_IN_ONE_TAB, { profile }),
    signInManual: (username, password) =>
      RESTFul.post(API.POST_MANUAL_SIGN_IN, { username, password }),
    getUserProfile: () => RESTFul.get(API.GET_USER_PROFILE),
    updateUserProfile: userProfile =>
      RESTFul.put(API.PUT_UPDATE_USER_PROFILE, userProfile),
    updateFCMToken: userProfile =>
      RESTFul.put(API.PUT_UPDATE_FCM_TOKEN, userProfile),
    refreshToken: refreshToken =>
      RESTFul.post(API.POST_REFRESH_TOKEN, { refresh_token: refreshToken }),
    updateUserProfilePreFix: data => RESTFul.put(API.PUT_USER_PROFILE_PREFIX, data),
    verifyEmail: token => RESTFul.post(API.POST_VERIFY_EMAIL, { token }),
    editDeliveryAddress: (id, data) =>
      RESTFul.put(API.EDIT_DELIVERY_ADDRESS(id), data),
    signUpManual: body => RESTFul.post(API.SIGN_UP_MANUAL, body),
    forgotPassword: email => RESTFul.post(API.FORGOT_PASSWORD, email),
    changePassword: body => RESTFul.post(API.CHANGE_PASSWORD, body),
    verifyEmailAndAutoLogin: body =>
      RESTFul.post(API.VERIFY_EMAIL_AND_AUTO_LOGIN, body),
    signUpPhone: body => RESTFul.post(API.SIGN_UP_PHONE, body),
    verifyPhone: body => RESTFul.post(API.VERIFY_PHONE, body),
    ignoreVerifyPhone: () => RESTFul.get(API.IGNORE_VERIFY_PHONE),
    resendEmail: body => RESTFul.post(API.RESEND_EMAIL, body),
    getListTransaction: () => RESTFul.get(API.GET_LIST_TRANSACTION),
    getDetailTransaction: id => RESTFul.get(API.GET_DETAIL_TRANSACTION(id)),
    closeExtension: () => RESTFul.put(API.CLOSE_EXTENSION),
    updateFirstLogin: () => RESTFul.post(API.UPDATE_FIRST_LOGIN),
    checkLoginProvider: () => RESTFul.get(API.CHECK_LOGIN_PROVIDER),
    changeUserPassword: body => RESTFul.post(API.CHANGE_USER_PASSWORD, body)
  },
  saleOrder: {
    reorderSaleOrder: body => RESTFul.post(API.REORDER_SALE_ORDER, body),
    getListPromotion: () => RESTFul.get(API.GET_LIST_PROMOTION),
    inputPromotion: code => RESTFul.get(API.INPUT_PROMOTION(code)),
    deleteQuotation: id => RESTFul.delete(API.DELETE_QUOTATION(id)),
    getCurrentSaleOrder: () => RESTFul.get(API.GET_CURRENT_SALE_ORDER),
    createSaleOrder: () => RESTFul.post(API.POST_CREATE_SALE_ORDER),
    getListChecker: params => RESTFul.get(API.GET_LIST_CHECKER, params),
    updateSaleOrder: quotationList =>
      RESTFul.post(API.PUT_UPDATE_SALE_ORDER, {
        quotation_list: quotationList
      }),
    updateQuotation: (id, data) =>
      RESTFul.post(API.PUT_UPDATE_QUOTATION(id), { ...data }),
    confirmSaleOrder: body => RESTFul.post(API.POST_CONFIRM_SALE_ORDER, body),
    getListSaleOrder: filter => RESTFul.get(API.GET_LIST_SALE_ORDER, filter),
    getSaleOrder: data => RESTFul.get(API.GET_SALE_ORDER, data),
    confirmShoppingCart: body => RESTFul.post(API.POST_CONFIRM_SHOPPING_CART, body),
    handlePostScraper: link => RESTFul.get(API.POST_SCRAPER, { url: link }),

    getPromotion: () => RESTFul.get(API.GET_PROMOTION),
    getPromotionAnonymous: anonymous_id =>
      RESTFul.get(API.GET_PROMOTION_ANONYMOUS(anonymous_id)),
    createNewPromotion: body => RESTFul.post(API.CREATE_NEW_PROMOTION, body),
    deletePromotion: body => RESTFul.delete(API.DELETE_PROMOTION, body),

    // improvement
    addShoppingCart: body => RESTFul.post(API.ADD_SHOPPING_CART, body),
    editSaleOrderImprovement: (id, data) =>
      RESTFul.put(API.EDIT_SALE_ORDER_IMPROVEMENT(id), data),
    editSaleOrderAnonymous: (id, data) =>
      RESTFul.put(API.EDIT_SALE_ORDER_ANONYMOUS(id), data),
    getSelectedSaleOrder: () => RESTFul.get(API.GET_SELECTED_SALE_ORDER),

    // 2.0.0
    getShippingCategories: () => RESTFul.get(API.GET_SHIPPING_CATEGORIES),
    getShippingInfo: params => RESTFul.get(API.GET_SHIPPING_INFO(params)),
    addShoppingCartAnonymous: body =>
      RESTFul.post(API.ADD_SHOPPING_CART_ANONYMOUS, body),
    getShoppingCartAnonymous: id => RESTFul.get(API.GET_SHOPPING_CART_ANONYMOUS(id)),
    deleteShoppingCartAnonymous: ({ id, anonymous_id }) =>
      RESTFul.delete(API.DELETE_SHOPPING_CART_ANONYMOUS({ id, anonymous_id })),
    putToLoggedCart: anonymous_id =>
      RESTFul.post(API.PUT_TO_LOGGED_CART(anonymous_id)),
    getDetailOrderTracking: id => RESTFul.get(API.GET_DETAIL_MANAGEMENT(id)),
    getTrackingDetail: SOId => RESTFul.get(API.GET_TRACKING_DETAIL(SOId)),
    getTrackingJourney: (id, type) =>
      RESTFul.get(API.GET_TRACKING_JOURNEY(id, type)),
    getTrackingJourneyByOrder: id =>
      RESTFul.get(API.GET_TRACKING_JOURNEY_BY_ORDER(id)),
    getVnTrackingDetail: id => RESTFul.get(API.GET_VN_TRACKING_DETAIL(id)),
    updateWaitingSaleOrder: body =>
      RESTFul.post(API.UPDATE_WAITING_SALE_ORDER, body),

    deleteSelectedCartItems: body =>
      RESTFul.delete(API.DELETE_SELECTED_CART_ITEMS, body),
    deleteSelectedAnonymousCartItems: body =>
      RESTFul.delete(API.DELETE_SELECTED_ANONYMOUS_CART_ITEMS, body),
    getOrderPurchaseReview: id => RESTFul.get(API.GET_ORDER_PURCHASE_REVIEW(id))
  },
  location: {
    getProvince: () => RESTFul.get(API.GET_PROVINCE),
    getDistrict: provinceId => RESTFul.get(API.GET_DISTRICT(provinceId)),
    getWard: (provinceId, districtId) =>
      RESTFul.get(API.GET_WARD(provinceId, districtId))
  },
  user: {
    createNewAddress: data => RESTFul.post(API.POST_CREATE_NEW_ADDRESS, data),
    selectAddress: addressId => RESTFul.put(API.PUT_SELECT_ADDRESS(addressId)),
    resetDeliveryAddress: addressId =>
      RESTFul.put(API.RESET_DELIVERY_ADDRESS(addressId)),
    deleteAddress: addressId => RESTFul.delete(API.DELETE_ADDRESS(addressId))
  },
  upload: {
    image: image => RESTFul.upload(API.UPLOAD_IMAGE, image),
    files: file => RESTFul.upload(API.UPLOAD_FILE, file)
  },
  tracking: {
    list: type => RESTFul.get(API.TRACKING_LIST, { type }),
    detail: (trackingId, type) =>
      RESTFul.get(API.TRACKING_DETAIL, { type, tracking_id: trackingId }),
    getListVNTracking: () => RESTFul.get(API.GET_LIST_VN_TRACKING),
    confirmVNTracking: data => RESTFul.post(API.CONFIRM_VN_TRACKING, data),
    detailConfirmVNTracking: id => RESTFul.get(API.DETAIL_CONFIRM_VN_TRACKING(id)),
    getTransferRequest: () => RESTFul.get(API.GET_TRANSFER_REQUEST)
  },
  shipping: {
    registerShippingService: () => RESTFul.post(API.REGISTER_SHIPPING_SERVICE),
    getListAddressShipping: () => RESTFul.get(API.GET_LIST_ADDRESS_SHIPPING),
    getListTrackingShipping: params =>
      RESTFul.get(API.GET_LIST_TRACKING_SHIPPING, params)
  },

  referral: {
    getReferral: () => RESTFul.get(API.GET_REFERRAL),
    exchangePoint: data => RESTFul.post(API.EXCHANGE_POINT, data)
  },

  website: {
    getListWebsiteDisableUS: () => RESTFul.get(API.GET_WEBSITE_DISABLE_US),
    getListWebsiteCrawler: () => RESTFul.get(API.GET_WEBSITE_CRAWLER)
  },

  catalog: {
    checkPriceProduct: body => RESTFul.post(API.CHECK_PRICE_PRODUCT, body),
    getListWebsiteFilterCatalog: () =>
      RESTFul.get(API.GET_LIST_WEBSITE_FILTER_CATALOG),
    getListCatalogLooking: params =>
      RESTFul.get(API.GET_LIST_CATALOG_LOOKING, params),
    getDetailCatalog: (params, query) =>
      RESTFul.get(API.GET_DETAIL_CATALOG_AWS(params), query),
    getListFavorite: params => RESTFul.get(API.GET_LIST_FAVORITE, params),
    getListFlashSale: params => RESTFul.get(API.GET_LIST_FLASH_SALE_AWS, params),
    getCategories: params => RESTFul.get(API.GET_CATEGORIES, params),
    getProductByCategory: (params, query) =>
      RESTFul.get(API.GET_PRODUCT_BY_CATEGORY(params), query),
    getListForYou: params => RESTFul.get(API.GET_LIST_FOR_YOU, params),
    getListProductDealHome: params =>
      RESTFul.get(API.GET_LIST_PRODUCT_DEAL_HOME, params)
  },
  getConfiguration: () => RESTFul.get(API.GET_CONFIGURATION),
  notification: {
    getNotification: () => RESTFul.get(API.GET_NOTIFICATION),
    markAsReadNotification: id => RESTFul.post(API.MARK_AS_READ_NOTIFICATION, id)
  },
  bank: {
    getList: type => RESTFul.get(API.GET_LIST_BANK(type))
  },
  category: {
    detect: items => RESTFul.post(API.GET_CATEGORY_DETECT, { items })
  },
  deposit: {
    getListMoneyRequest: params => RESTFul.get(API.GET_MONEY_REQUEST, params),
    sendMoneyTranferRequest: data =>
      RESTFul.post(API.SEND_MONEY_TRANFER_REQUEST, data),
    sendMoneyWithdrawRequest: data =>
      RESTFul.post(API.SEND_MONEY_WITHDRAW_REQUEST, data)
  },
  deal: {
    getDetail: id => RESTFul.get(API.GET_DEAL_DETAIL(id)),
    getListCheckerProfile: id => RESTFul.get(API.GET_LIST_CHECKER_PROFILE(id)),
    getListCheckerAvailable: () => RESTFul.get(API.GET_LIST_CHECKER_AVAILABLE),
    getListFavoriteDeal: params => RESTFul.get(API.GET_LIST_FAVORITE_DEAL, params)
  },
  debtRecovery: {
    getDetail: id => RESTFul.get(API.GET_DEBT_RECOVERY(id))
  }
};
