/* eslint-disable react/prop-types */
import { IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { useTheme } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import useActiveAsPath from '../../../hooks/useActiveAsPath';
import CustomMenuItemChild from './CustomMenuItemChild';
import CustomMenuItemStartIcon from './CustomMenuItemStartIcon';

export default function CustomMenuItemNested(props) {
  const { routeProps, callback, openCollapse } = props;
  const { children } = routeProps || {};

  const theme = useTheme();
  const isExistChildren = Array.isArray(children);

  const { location } = window;
  const asPath = `${location.pathname}${location.search}`;
  const isActiveChild = isExistChildren
    ? children.some(child => asPath === child.href)
    : false;

  const isActive = useActiveAsPath(routeProps.href);

  const handleToggleCollapse = event => {
    event.stopPropagation();
    event.preventDefault();

    if (callback) callback(event);
  };

  return (
    <>
      <CustomMenuItemStartIcon
        {...props}
        isActive={isActive || isActiveChild}
        {...(isExistChildren && {
          endIcon: (
            <IconButton size="small" onClick={handleToggleCollapse}>
              {openCollapse ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )
        })}
        listItemTextProps={{
          style: { fontWeight: isActive || isActiveChild ? 700 : 400 }
        }}
      />

      {isExistChildren && (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            dense
            style={{
              marginLeft: 34,
              fontWeight: 400,
              borderLeft: `1px solid ${theme.palette.divider}`
            }}
          >
            {children.map((child, idx) => (
              <CustomMenuItemChild routeProps={child} key={idx} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
