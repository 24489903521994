import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { bool, element, func } from 'prop-types';
import React from 'react';
import LoadingSquare from '../LoadingSquare';

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'relative',
    backgroundColor: '#E1E2E5',
    borderRadius: theme.spacing(1.5)
  }
}));

export default function InputLoading({ children, isLoading, onClose }) {
  const classes = useStyles();

  return (
    <Box
      width={1}
      className={isLoading ? classes.loading : ''}
      onClick={() => onClose?.()}
    >
      {children}
      {isLoading && <LoadingSquare />}
    </Box>
  );
}

InputLoading.propTypes = {
  children: element.isRequired,
  isLoading: bool,
  onClose: func
};

InputLoading.defaultProps = {
  isLoading: false,
  onClose: null
};
