export const nonAccentVietnamese = str => {
  str = str.replace(/\s+/g, ' ').trim();
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const roundNumber = (number, scale) =>
  +`${Math.round(`${number}e+${scale}`)}e-${scale}`;

export function convertDeliveryAddress(address) {
  const { type_addr, city, district, ward, street } = address;

  if (type_addr === 'vietnam') {
    return [street, ward?.name, district?.name, city?.name].join(', ');
  }
  return street;
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(x => num >= x.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function getProductInfo(product) {
  const productId = product?.sale_order_line?.id || product?.id;
  const productColor = product?.sale_order_line?.color || product?.color;
  const productSize = product?.sale_order_line?.size || product?.size;
  const productName = product?.sale_order_line?.name || product?.name;
  const productImage =
    product?.sale_order_line?.inf?.image_u || product?.product_info?.image_url;
  const productLink =
    product?.sale_order_line?.inf?.website || product?.product_info?.website_url;

  const dealId = product?.sale_order_line?.dealId || product?.dealId;
  const currencyRate =
    +(product?.sale_order_line?.currency_ra || 0) || +(product?.currency_rate || 0);
  const priceUnit =
    +(product?.sale_order_line?.price_unit || 0) || +(product?.price_unit || 0);
  const priceFinal = (() => {
    const priceAfterSale = (price_unit, discount, discount_amount) =>
      price_unit - ((price_unit * discount) / 100 + discount_amount);

    const priceFinalOfOrder = priceAfterSale(
      +(product?.price_unit || 0),
      +(product?.discount || 0),
      +(product?.discount_amount || 0)
    );

    const priceFinalOfTracking = priceAfterSale(
      +(product?.sale_order_line?.price_unit || 0),
      +(product?.sale_order_line?.discount || 0),
      +(product?.sale_order_line?.discount_am || 0)
    );

    if (product?.sale_order_line) return priceFinalOfTracking;

    return priceFinalOfOrder;
  })();
  const quantity = +(product?.quantity || 0) || product?.qty;

  const isGift = product?.price_unit_vnd === 0;
  const isPromotionWebsite =
    +product?.sale_order_line?.sale_unit_price > 0 || +product.sale_unit_price > 0;

  return {
    isGift,
    isPromotionWebsite,
    quantity,
    productId,
    priceFinal,
    priceUnit,
    dealId,
    productLink,
    currencyRate,
    productColor,
    productSize,
    productName,
    productImage
  };
}
