import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function EyeSlash(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1084 7.8916L7.89172 12.1083C7.35006 11.5666 7.01672 10.8249 7.01672 9.99994C7.01672 8.34994 8.35006 7.0166 10.0001 7.0166C10.8251 7.0166 11.5667 7.34994 12.1084 7.8916Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.85 4.8084C13.3917 3.7084 11.725 3.1084 9.99999 3.1084C7.05832 3.1084 4.31666 4.84173 2.40833 7.84173C1.65833 9.01673 1.65833 10.9917 2.40833 12.1667C3.06666 13.2001 3.83332 14.0917 4.66666 14.8084"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01672 16.2751C7.96672 16.6751 8.97506 16.8917 10.0001 16.8917C12.9417 16.8917 15.6834 15.1584 17.5917 12.1584C18.3417 10.9834 18.3417 9.0084 17.5917 7.8334C17.3167 7.40007 17.0167 6.99173 16.7084 6.6084"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.925 10.5835C12.7083 11.7585 11.75 12.7168 10.575 12.9335"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89163 12.1084L1.66663 18.3334"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 1.6665L12.1083 7.8915"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
