const theme = {
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 900
    },
    h2: {
      fontSize: 24,
      fontWeight: 700
    },
    h3: {
      fontSize: 20,
      fontWeight: 900
    },
    h4: {
      fontSize: 18,
      fontWeight: 700
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700
    },
    body1: {
      fontSize: 18,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    },
    overline: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'inherit'
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Mulish', 'Plus Jakarta Sans', 'sans-serif'],
    button: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 8
    }
    // select: {
    //   fontSize: 14,
    //   fontWeight: 700,
    //   borderRadius: 8,
    //   border: '1px solid #E1E2E5'
    // }
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      },
      outlined: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      },
      rounded: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      }
    }
  },
  palette: {
    common: {
      black: '#0F172A',
      white: '#FFFFFF'
    },
    primary: {
      dark: '#2A2AC9',
      main: '#3838FF',
      light: '#D6E1FF'
    },
    secondary: {
      dark: '#0BA259',
      main: '#00CC79',
      light: '#CCF5E4'
    },
    error: {
      dark: '#FF1C2C',
      main: '#FF4040',
      light: '#FD8181'
    },
    warning: {
      dark: '#F57C00',
      main: '#FFA92B',
      light: '#FFB74D'
    },
    info: {
      dark: '#008FDD',
      main: '#2AA6FF',
      light: '#4CC8FF'
    },
    success: {
      dark: '#0BA259',
      main: '#00CC79',
      light: '#01E688'
    },
    greyScale: {
      grey01: '#F1F5F9',
      grey02: '#E2E8F0',
      grey03: '#9BA9BC',
      grey04: '#7A889D'
    }
  }
};

export default theme;
