import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ProfileIconContained(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 2H11.76C6.24002 2.14 1.87002 6.72 2.00002 12.24C2.06002 14.93 3.18002 17.35 4.96002 19.1C5.19002 19.32 5.43002 19.54 5.68002 19.74C5.93002 19.94 6.18002 20.13 6.45002 20.31C6.58002 20.4 6.72002 20.48 6.85002 20.57C7.26002 20.81 7.69002 21.03 8.14002 21.22C8.27002 21.27 8.40002 21.32 8.53002 21.38C8.55002 21.38 8.57002 21.39 8.59002 21.4C8.72002 21.45 8.86002 21.5 9.00002 21.54C9.14002 21.58 9.28002 21.62 9.42002 21.66C9.57002 21.7 9.72002 21.74 9.87002 21.77C9.92002 21.78 9.97002 21.79 10.02 21.8C10.09 21.82 10.17 21.83 10.24 21.84C10.38 21.87 10.53 21.89 10.68 21.91C11.1 21.97 11.54 22 11.98 22H12.24C12.58 21.99 12.92 21.96 13.25 21.92C13.56 21.88 13.87 21.83 14.17 21.76C14.19 21.75 14.2 21.75 14.22 21.75C14.67 21.64 15.11 21.51 15.53 21.35C15.68 21.29 15.82 21.23 15.97 21.17C16.07 21.13 16.17 21.08 16.27 21.04C16.63 20.87 16.98 20.67 17.32 20.46C17.46 20.38 17.59 20.29 17.72 20.2C17.85 20.11 17.98 20.02 18.1 19.92C18.43 19.67 18.74 19.39 19.04 19.1C20.93 17.23 22.07 14.62 22 11.76C21.87 6.32 17.42 2 12 2ZM18.17 17.08C17.26 15.82 15.8 15 14.15 15H9.84002C8.20002 15 6.74002 15.81 5.83002 17.07C4.73002 15.73 4.05002 14.03 4.00002 12.19C3.90002 7.78 7.40002 4.11 11.81 4H12C16.31 4 19.89 7.5 20 11.81C20.05 13.81 19.35 15.65 18.17 17.08Z" />
      <path d="M15 8.52V12.02C15 12.56 14.56 13 14.02 13H11.52C10.13 13 9 11.87 9 10.48V8.52C9 7.13 10.13 6 11.52 6H12.48C13.87 6 15 7.13 15 8.52Z" />
    </SvgIcon>
  );
}
