const HOST = process.env.REACT_APP_API_URL;
const HOST_PRODUCT = process.env.REACT_APP_PRODUCT_URL;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const HOST_NEST = process.env.REACT_APP_NEST_URL;
const NEST_SOCKET = process.env.REACT_APP_NEST_SOCKET_URL;

const FACEBOOK_URL = 'https://www.facebook.com/buyngon.order';
const EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/fojobeaaplbfdpoiidimjmgaphdiokhj';

export {
  HOST,
  HOST_PRODUCT,
  FACEBOOK_APP_ID,
  FACEBOOK_URL,
  HOST_NEST,
  NEST_SOCKET,
  EXTENSION_URL
};
