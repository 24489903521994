import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DollarIconContained(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M21.25 8.5H19.06C18.53 7.67 17.83 6.98 17 6.47V3.25C17 2.84 16.66 2.5 16.25 2.5C14.4 2.5 12.82 3.78 12.37 5.51H10.43C7.47002 5.51 4.98002 7.51 4.24002 10.23C4.11002 9.96 4.02002 9.65 4.02002 9.33V8C4.02002 7.45 3.57002 7 3.02002 7C2.47002 7 2.02002 7.45 2.02002 8V9.33C2.02002 10.74 2.84002 12.29 4.04002 12.81C4.04002 12.81 4.04002 12.81 4.04002 12.82C4.24002 14.53 5.13002 16.09 6.50002 17.14V20.29C6.50002 20.7 6.84002 21.04 7.25002 21.04H10.04C10.45 21.04 10.79 20.7 10.79 20.29V18.5H13.24V20.29C13.24 20.7 13.58 21.04 13.99 21.04H16.75C17.16 21.04 17.5 20.7 17.5 20.29V17.2C18.58 16.4 19.36 15.29 19.77 14H21.25C21.66 14 22 13.66 22 13.25V9.25C22 8.84 21.66 8.5 21.25 8.5ZM11.12 8.75H10.16C8.90002 8.75 7.88002 9.77 7.88002 11.03C7.88002 11.44 7.54002 11.78 7.13002 11.78C6.72002 11.78 6.38002 11.44 6.38002 11.03C6.38002 8.95 8.08002 7.25 10.16 7.25H11.12C11.53 7.25 11.87 7.59 11.87 8C11.87 8.41 11.53 8.75 11.12 8.75ZM17.22 10.24C17.2 10.32 17.18 10.4 17.15 10.47C17.12 10.55 17.08 10.62 17.03 10.69C16.99 10.76 16.94 10.82 16.88 10.88C16.65 11.11 16.32 11.24 15.99 11.24C15.91 11.24 15.83 11.24 15.75 11.22C15.67 11.2 15.59 11.18 15.52 11.15C15.44 11.12 15.37 11.08 15.3 11.03C15.23 10.99 15.17 10.94 15.11 10.88C14.88 10.65 14.74 10.32 14.74 10C14.74 9.67 14.88 9.34 15.11 9.11C15.17 9.05 15.23 9 15.3 8.96C15.37 8.91 15.44 8.87 15.52 8.84C15.59 8.81 15.67 8.78 15.75 8.77C16.15 8.69 16.59 8.82 16.88 9.11C17.11 9.34 17.24 9.67 17.24 10C17.24 10.08 17.23 10.16 17.22 10.24Z" />
    </SvgIcon>
  );
}
