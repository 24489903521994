import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Skeleton from '@material-ui/lab/Skeleton';
import { bool } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { IS_AUTH, Path } from '../../../contents/Constants';
import { convertUserName } from '../../../utils/helpers';
import ProfileIcon from '../../icons/new/improvement/ProfileIcon';
import Gifs from '../../../contents/Gifs';
import images from '../../../contents/Images';
import useRedirectToAuthPage from '../../../hooks/2.2.0/useRedirectToAuthPage';

const useStyles = makeStyles(theme => ({
  profile: {
    maxWidth: 155,
    '&:hover #expand-icon': {
      transform: 'rotate(180deg)'
    }
  },
  title: {
    fontSize: 10
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 700
  },
  subTitleDot: {
    fontWeight: 700,
    display: 'inline-flex',
    width: '100%',
    'white-space': 'nowrap',
    overflow: 'hidden !important',
    'text-overflow': 'ellipsis',
    color: ({ isAuth }) =>
      isAuth ? theme.palette.common.black : theme.palette.common.white
  },
  icon: { transition: 'transform 0.3s' },
  avatarSection: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48
  },
  avatarSectionRes: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 48
  },
  titleSection: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 48px)'
  },
  container: { padding: 0 }
}));

const StyledAvatar = withStyles(theme => ({
  root: {
    backgroundColor: '#F5F5F7',
    color: theme.palette.common.black
  }
}))(Avatar);

export default function UserProfile({ isResponsive }) {
  const theme = useTheme();
  const isAuth = localStorage.getItem(IS_AUTH);
  const classes = useStyles({ isAuth });

  const name = useSelector(state => state.profile?.name || '');
  const avatar_url = useSelector(
    state => state.profile?.avatar_url || images.userAvatar
  );
  const loading = useSelector(
    state => !!state.common.loadingImprove?.getUserProfile
  );

  const { onRedirect } = useRedirectToAuthPage(Path.SIGN_IN);

  const handleLogin = () => {
    if (!isAuth) onRedirect();
  };

  if (isResponsive) {
    return (
      <Grid
        onClick={handleLogin}
        container
        spacing={1}
        wrap="nowrap"
        component={isAuth ? 'div' : Button}
        className={classes.container}
      >
        <Grid item container className={classes.avatarSectionRes}>
          {loading ? (
            <Skeleton variant="circle" width={40} height={40} />
          ) : (
            <StyledAvatar alt={name} src={isAuth ? avatar_url : ''}>
              <img
                src={Gifs.avatar}
                style={{ height: '100%', width: '100%' }}
                alt="AVT"
              />
            </StyledAvatar>
          )}
        </Grid>

        <Grid item container className={classes.titleSection}>
          <Typography
            noWrap
            variant="body2"
            align="left"
            className={classes.title}
            style={{
              color: isAuth ? theme.palette.common.black : theme.palette.common.white
            }}
          >
            {isAuth ? 'Xin chào' : 'Nhận nhiều ưu đãi hơn khi'}
          </Typography>
          {loading ? (
            <Skeleton variant="text" width={80} />
          ) : (
            <Typography
              noWrap
              variant="body2"
              align="left"
              className={classes.subTitleDot}
            >
              {isAuth ? name : 'Đăng nhập/Đăng ký'}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item container className={classes.titleSection}>
        <Typography
          noWrap
          variant="body2"
          align={isAuth ? 'right' : 'left'}
          className={classes.title}
        >
          {isAuth ? 'Xin chào,' : 'Đăng nhập/Đăng ký'}
        </Typography>
        {loading ? (
          <Skeleton variant="text" width={80} />
        ) : (
          <Typography
            noWrap
            variant="body2"
            align={isAuth ? 'right' : 'left'}
            className={classes.subTitle}
          >
            {isAuth ? convertUserName(name) : 'Tài khoản'}

            {!isAuth && (
              <ExpandLess
                id="expand-icon"
                fontSize="small"
                className={classes.icon}
              />
            )}
          </Typography>
        )}
      </Grid>

      <Grid item container className={classes.avatarSection}>
        {loading ? (
          <Skeleton variant="circle" width={40} height={40} />
        ) : (
          <StyledAvatar alt={name} src={isAuth ? avatar_url : ''}>
            <ProfileIcon />
          </StyledAvatar>
        )}
      </Grid>
    </Grid>
  );
}

UserProfile.propTypes = {
  isResponsive: bool
};

UserProfile.defaultProps = {
  isResponsive: false
};
