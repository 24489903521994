import Grid from '@material-ui/core/Grid';
import isEqual from 'lodash/isEqual';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExtensionSnackBar from '../components/Improvements/ExtensionSnackBar';
import VerifyEmailPopup from '../components/VerifyEmailPopup';
import { IS_AUTH } from '../contents/Constants';
import useRealtimeNotify from '../hooks/2.2.0/useRealtimeNotify';
import useRedirectTransaction from '../hooks/2.2.0/useRedirectTransaction';
import useResponsive from '../hooks/useResponsive';
import actions from '../redux/actions';
import dispatch from '../utils/dispatch';

export default function MainLayout({ children }) {
  useRealtimeNotify();

  const matches = useResponsive();
  const isAuth = useSelector(state => {
    const auth = window.localStorage.getItem(IS_AUTH);
    const loading = state.common?.loadingImprove?.getUserProfile;
    return Boolean(auth || (!loading && state?.profile?._id));
  });
  const body = useRedirectTransaction(children);
  const defaultAddress = useSelector(
    state =>
      (state.profile?.address_management || []).find(item => item.default_address),
    isEqual
  );
  const defaultAddressId = defaultAddress?._id;

  useEffect(() => {
    if (isAuth) {
      if (defaultAddressId) {
        if (defaultAddress?.selected === true) {
          dispatch(actions.getCurrentSaleOrder());
        } else {
          dispatch(actions.resetDeliveryAddress());
        }
      } else {
        dispatch(actions.getCurrentSaleOrder());
      }
    } else {
      dispatch(actions.getShoppingCartAnonymous());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, defaultAddressId]);

  return (
    <>
      <Grid container>{body}</Grid>

      {!matches && <ExtensionSnackBar />}
      {isAuth && <VerifyEmailPopup />}
    </>
  );
}

MainLayout.propTypes = {
  children: any
};

MainLayout.defaultProps = {
  children: null
};
