import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function TruckDeliveryIcon(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M5.83329 15.8333C6.75377 15.8333 7.49996 15.0871 7.49996 14.1667C7.49996 13.2462 6.75377 12.5 5.83329 12.5C4.91282 12.5 4.16663 13.2462 4.16663 14.1667C4.16663 15.0871 4.91282 15.8333 5.83329 15.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 15.8333C15.0871 15.8333 15.8333 15.0871 15.8333 14.1667C15.8333 13.2462 15.0871 12.5 14.1667 12.5C13.2462 12.5 12.5 13.2462 12.5 14.1667C12.5 15.0871 13.2462 15.8333 14.1667 15.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16663 14.166H2.49996V10.8327M1.66663 4.16602H10.8333V14.166M7.49996 14.166H12.5M15.8333 14.166H17.5V9.16602H10.8333M10.8333 4.99935H15L17.5 9.16602M2.49996 7.49935H5.83329"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
