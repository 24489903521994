import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function PurchasingIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00643 1.66797C6.34254 1.66797 5.73668 2.06727 5.47485 2.67708L3.4078 7.5013H2.29289C2.03206 7.5013 1.78716 7.62258 1.62883 7.83008C1.47216 8.03674 1.41889 8.30516 1.48722 8.55599L3.62101 16.2773C3.82018 16.9973 4.48079 17.5013 5.22746 17.5013H16.0217C16.7684 17.5013 17.4282 16.9981 17.6265 16.279L19.7603 8.55762C19.8295 8.30678 19.777 8.03674 19.6204 7.83008C19.462 7.62258 19.2163 7.5013 18.9563 7.5013H17.8414L15.7727 2.67871H15.7743C15.5129 2.06607 14.905 1.66797 14.2411 1.66797H7.00643ZM7.00643 3.33464H14.2411L16.0266 7.5013H5.22258L7.00643 3.33464ZM7.29126 10.0013C7.75126 10.0013 8.12459 10.3746 8.12459 10.8346V14.168C8.12459 14.628 7.75126 15.0013 7.29126 15.0013C6.83126 15.0013 6.45793 14.628 6.45793 14.168V10.8346C6.45793 10.3746 6.83126 10.0013 7.29126 10.0013ZM10.6246 10.0013C11.0846 10.0013 11.4579 10.3746 11.4579 10.8346V14.168C11.4579 14.628 11.0846 15.0013 10.6246 15.0013C10.1646 15.0013 9.79126 14.628 9.79126 14.168V10.8346C9.79126 10.3746 10.1646 10.0013 10.6246 10.0013ZM13.9579 10.0013C14.4179 10.0013 14.7913 10.3746 14.7913 10.8346V14.168C14.7913 14.628 14.4179 15.0013 13.9579 15.0013C13.4979 15.0013 13.1246 14.628 13.1246 14.168V10.8346C13.1246 10.3746 13.4979 10.0013 13.9579 10.0013Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
}
