import { List, Paper } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MENUS } from '../../../contents/DynamicConstant';
import actions from '../../../redux/actions';
import CustomMenuItem from './CustomMenuItem';

export default function MenuLogin() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  return (
    <Paper style={{ width: 255, fontWeight: 700 }}>
      <List component="nav" dense>
        {MENUS.map((item, index) => (
          <CustomMenuItem
            key={index}
            routeProps={item}
            callback={() => {
              if (!item.href) handleLogout();
            }}
          />
        ))}
      </List>
    </Paper>
  );
}
