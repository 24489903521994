import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CloseIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#F5F5F7" />
      <path
        d="M15 9L9 15"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L9 9"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function NewCloseIcon(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M5.99007 4.99067C5.79114 4.99072 5.59674 5.0501 5.43173 5.16121C5.26672 5.27233 5.1386 5.43013 5.06374 5.61444C4.98889 5.79875 4.9707 6.0012 5.0115 6.1959C5.05231 6.39061 5.15025 6.56872 5.29281 6.70747L10.5858 12.0004L5.29281 17.2934C5.19684 17.3855 5.12021 17.4959 5.06743 17.618C5.01464 17.7402 4.98676 17.8716 4.9854 18.0046C4.98405 18.1377 5.00926 18.2697 5.05954 18.3928C5.10983 18.516 5.1842 18.6279 5.27827 18.722C5.37235 18.8161 5.48426 18.8904 5.60743 18.9407C5.73061 18.991 5.86259 19.0162 5.99563 19.0149C6.12867 19.0135 6.2601 18.9856 6.38223 18.9328C6.50436 18.8801 6.61473 18.8034 6.70687 18.7075L11.9998 13.4145L17.2928 18.7075C17.385 18.8034 17.4953 18.8801 17.6175 18.9328C17.7396 18.9856 17.871 19.0135 18.0041 19.0149C18.1371 19.0162 18.2691 18.991 18.3922 18.9407C18.5154 18.8904 18.6273 18.8161 18.7214 18.722C18.8155 18.6279 18.8899 18.516 18.9401 18.3928C18.9904 18.2697 19.0156 18.1377 19.0143 18.0047C19.0129 17.8716 18.985 17.7402 18.9323 17.618C18.8795 17.4959 18.8028 17.3855 18.7069 17.2934L13.4139 12.0004L18.7069 6.70747C18.8514 6.567 18.9501 6.38616 18.99 6.18864C19.03 5.99112 19.0093 5.78614 18.9308 5.60055C18.8523 5.41497 18.7195 5.25744 18.5499 5.14861C18.3803 5.03978 18.1817 4.98473 17.9803 4.99067C17.7205 4.99841 17.4739 5.10699 17.2928 5.2934L11.9998 10.5864L6.70687 5.2934C6.61368 5.19761 6.50224 5.12146 6.37912 5.06947C6.25601 5.01747 6.12372 4.99068 5.99007 4.99067Z" />
    </SvgIcon>
  );
}
