import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';

export default function TransferIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="19.6667"
        r="16.6667"
        fill={theme.palette.primary.main}
        stroke="#5C81FF"
        strokeWidth="3"
      />
      <path
        d="M26.6666 24.6667V26.6667H13.3333V24.6667C13.3333 24.3 13.6333 24 14 24H26C26.3666 24 26.6666 24.3 26.6666 24.6667Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 23.5007V19.834H16.1666V23.5007H15.8333Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M18.5 23.5007V19.834H18.8333V23.5007H18.5Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M21.1667 23.5007V19.834H21.5V23.5007H21.1667Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M23.8333 23.5007V19.834H24.1666V23.5007H23.8333Z"
        fill="white"
        stroke="white"
      />
      <mask id="path-7-inside-1_3552_31085" fill="white">
        <path d="M27.3334 27.166H12.6667C12.3934 27.166 12.1667 26.9393 12.1667 26.666C12.1667 26.3927 12.3934 26.166 12.6667 26.166H27.3334C27.6067 26.166 27.8334 26.3927 27.8334 26.666C27.8334 26.9393 27.6067 27.166 27.3334 27.166Z" />
      </mask>
      <path
        d="M27.3334 27.166H12.6667C12.3934 27.166 12.1667 26.9393 12.1667 26.666C12.1667 26.3927 12.3934 26.166 12.6667 26.166H27.3334C27.6067 26.166 27.8334 26.3927 27.8334 26.666C27.8334 26.9393 27.6067 27.166 27.3334 27.166Z"
        fill="white"
      />
      <path
        d="M27.3334 26.166H12.6667V28.166H27.3334V26.166ZM12.6667 26.166C12.9456 26.166 13.1667 26.3871 13.1667 26.666H11.1667C11.1667 27.4916 11.8411 28.166 12.6667 28.166V26.166ZM13.1667 26.666C13.1667 26.945 12.9456 27.166 12.6667 27.166V25.166C11.8411 25.166 11.1667 25.8404 11.1667 26.666H13.1667ZM12.6667 27.166H27.3334V25.166H12.6667V27.166ZM27.3334 27.166C27.0544 27.166 26.8334 26.945 26.8334 26.666H28.8334C28.8334 25.8404 28.159 25.166 27.3334 25.166V27.166ZM26.8334 26.666C26.8334 26.3871 27.0544 26.166 27.3334 26.166V28.166C28.159 28.166 28.8334 27.4916 28.8334 26.666H26.8334Z"
        fill="white"
        mask="url(#path-7-inside-1_3552_31085)"
      />
      <path
        d="M20.0538 13.8964C20.0599 13.8974 20.0638 13.8982 20.0657 13.8987L26.061 16.2968C26.0687 16.2999 26.0999 16.3184 26.1305 16.3635C26.1611 16.4085 26.1666 16.4441 26.1666 16.4526V18.6659C26.1666 18.7564 26.0905 18.8326 26 18.8326H14C13.9095 18.8326 13.8333 18.7564 13.8333 18.6659V16.4526C13.8333 16.4441 13.8389 16.4085 13.8694 16.3635C13.9001 16.3184 13.9313 16.2999 13.939 16.2968L19.9343 13.8987C19.9362 13.8982 19.9401 13.8974 19.9461 13.8964C19.9601 13.8942 19.9789 13.8926 20 13.8926C20.0211 13.8926 20.0398 13.8942 20.0538 13.8964ZM18.5 16.6659C18.5 17.4954 19.1705 18.1659 20 18.1659C20.8295 18.1659 21.5 17.4954 21.5 16.6659C21.5 15.8364 20.8295 15.1659 20 15.1659C19.1705 15.1659 18.5 15.8364 18.5 16.6659Z"
        fill="white"
        stroke="white"
      />
    </SvgIcon>
  );
}
