import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLocation } from 'react-router';
import { NAV_TABS } from '../../../contents/DynamicConstant';

const useStyles = makeStyles(theme => ({
  item: {
    textDecoration: 'none',
    fontWeight: 700,
    marginLeft: 32,
    whiteSpace: 'pre',
    '&:last-child': {
      marginRight: 32
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginLeft: 16,
      '&:last-child': {
        marginRight: 16
      }
    }
  }
}));

export default function NavTab() {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <Box display="flex" alignItems="center">
      {NAV_TABS.map((item, index) => (
        <item.component
          key={index}
          to={item.href}
          href={item.href}
          className={classes.item}
          style={{
            color:
              pathname === item.href
                ? 'rgba(15, 23, 42, 1)'
                : 'rgba(155, 169, 188, 1)'
          }}
        >
          {item.label}
        </item.component>
      ))}
    </Box>
  );
}
