/* eslint-disable max-len */

/**
 * @param {number} value
 * @param {Omit<Intl.NumberFormatOptions, 'style' | 'currency' | 'minimumFractionDigits' | 'maximumFractionDigits'>} [options]
 */
export const formatNumber = (value, options) => {
  if (typeof value !== 'number') {
    throw new Error(`Value type error! Current type is: ${typeof value}`);
  }

  return new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  }).format(value);
};

/**
 * @param {number} value
 * @param {"USD" | "EUR" | "VND"} currencyCode
 * @param {Omit<Intl.NumberFormatOptions, 'style' | 'currency' | 'minimumFractionDigits'>} [options]
 */
export function formatCurrency(value, currencyCode, options) {
  if (currencyCode !== 'USD' && currencyCode !== 'EUR' && currencyCode !== 'VND') {
    throw new Error(`currency is not exist! Current currency is: ${currencyCode}`);
  }

  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  }).format(+(value || 0));

  return formatter;
}

/**
 * @param {number} value
 * @param {"USA" | "ESP"} location
 */
export const formatCurrencyByLocation = (value, location) => {
  if (location === 'USA') return formatCurrency(value, 'USD');
  if (location === 'ESP') return formatCurrency(value, 'EUR');

  throw new Error('Location is not existed');
};

export const FIXED_RATE_USD = 30_000;
export const FIXED_RATE_EUR = 32_000;

/**
 * @param {number} rate
 * @param {"USD" | "EUR"} currency
 */
export const getSaveDeal = (rate, currency) => {
  let fixedRate;
  if (currency === 'USD') fixedRate = FIXED_RATE_USD;
  else if (currency === 'EUR') fixedRate = FIXED_RATE_EUR;
  else throw new Error('Currency is not existed');

  return `${Math.round(((fixedRate - rate) / fixedRate) * 100)}%`;
};
