import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BagIcon(props) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    >
      <path
        d="M6.25 6.39192V5.58358C6.25 3.70858 7.75833 1.86692 9.63333 1.69192C11.8667 1.47525 13.75 3.23358 13.75 5.42525V6.57525"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49986 18.3327H12.4999C15.8499 18.3327 16.4499 16.991 16.6249 15.3577L17.2499 10.3577C17.4749 8.32435 16.8915 6.66602 13.3332 6.66602H6.66652C3.10819 6.66602 2.52486 8.32435 2.74986 10.3577L3.37486 15.3577C3.54986 16.991 4.14986 18.3327 7.49986 18.3327Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9131 10.0007H12.9206"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.07859 10.0007H7.08608"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
