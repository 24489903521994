/* eslint-disable consistent-return */

import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { ACCESS_KEY, HTTP_CODE, MessageContent } from '../contents/Constants';
import dispatch from '../utils/dispatch';
import actions from '../redux/actions';
import API from './API';

const handle = async (method, url, data) => {
  try {
    const authorization = !includes(url, [
      API.POST_SIGN_IN,
      API.POST_SIGN_IN_ONE_TAB
    ])
      ? window.localStorage.getItem(ACCESS_KEY)
      : '';
    let queries = '';
    if (method === 'GET' && !isEmpty(data)) {
      const params = keys(data)
        .map(key => `${key}=${data[key]}`)
        .join('&');
      queries = `?${params}`;
    }
    const body =
      data && includes(['POST', 'PUT', 'DELETE'], method)
        ? JSON.stringify(data)
        : null;

    const response = await fetch(`${url}${queries}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        authorization,
        'Cache-Control': 'no-cache'
      },
      body
    });
    const result = await response.json();
    const success = result?.success;
    const message = result?.message;
    switch (response.status) {
      case HTTP_CODE.CREATED_201:
      case HTTP_CODE.SUCCESS_200:
        if (['/customer/user/update-address/'].some(i => url.includes(i))) {
          return result;
        }
        if (success === false && message) {
          dispatch(actions.alertMessageError({ message }));
        }
        return result;

      case HTTP_CODE.UNAUTHORIZED_401:
      case HTTP_CODE.FORBIDDEN_403:
        dispatch(actions.logout());
        break;

      case HTTP_CODE.METHOD_NOT_ALLOWED_405:
        dispatch(actions.logout());
        dispatch(
          actions.alertMessageError({
            message: MessageContent.authFailed
          })
        );
        return null;

      case HTTP_CODE.HAVE_NOT_ACCESS_406:
        dispatch(actions.logout());
        dispatch(
          actions.alertMessageError({
            message: MessageContent.error
          })
        );
        return null;

      default:
        if (!['/scraper'].some(path => url.includes(path))) {
          dispatch(
            actions.alertMessageError({ message: message || MessageContent.error })
          );
        }
        throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const handleUpload = async (url, file) => {
  try {
    const name = get(file, 'type', '').split('/')[0];
    const formData = new FormData();
    formData.append(name, file);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem(ACCESS_KEY)
      },
      body: formData
    });
    switch (response.status) {
      case HTTP_CODE.CREATED_201:
      case HTTP_CODE.SUCCESS_200:
        return response.json();
      case HTTP_CODE.UNAUTHORIZED_401:
      case HTTP_CODE.FORBIDDEN_403:
        dispatch(actions.logout());
        break;
      case HTTP_CODE.METHOD_NOT_ALLOWED_405:
        dispatch(actions.logout());
        dispatch(
          actions.alertMessageError({
            message: MessageContent.authFailed
          })
        );
        return null;
      default:
        dispatch(actions.alertMessageError({ message: MessageContent.error }));
        throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export default class Restful {
  static get(url, data) {
    return handle('GET', url, data);
  }

  static post(url, data) {
    return handle('POST', url, data);
  }

  static put(url, data) {
    return handle('PUT', url, data);
  }

  static delete(url, data) {
    return handle('DELETE', url, data);
  }

  static upload(url, data) {
    return handleUpload(url, data);
  }
}
