/* eslint-disable react/prop-types */
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  listItem: {
    '&:hover #list-item-label > span': {
      color: theme.palette.primary.main
    }
  }
}));

export default function CustomizedMenuItem(props) {
  const {
    routeProps,
    listItemProps,
    callback,
    startIcon,
    endIcon,
    isActive,
    listItemTextProps
  } = props;
  const { component, href, label } = routeProps;
  const { className, ...rest } = listItemProps || {};

  const classes = useStyles();

  const handleClick = event => {
    if (callback) callback(event);
  };

  return (
    <ListItem
      button
      href={href}
      to={href}
      onClick={handleClick}
      component={component}
      className={clsx(classes.listItem, className)}
      {...rest}
    >
      {startIcon}

      <ListItemText
        id="list-item-label"
        primary={label}
        primaryTypographyProps={{
          ...listItemTextProps,
          color: isActive ? 'primary' : 'textPrimary'
        }}
      />

      {endIcon}
    </ListItem>
  );
}
