import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { array } from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import StepperIcon from './StepperIcon';

const useStyles = makeStyles(theme => ({
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.25, 0)
    }
  },
  active: {
    color: '#9BA9BC !important'
  },
  label: {
    fontWeight: '600 !important',
    whiteSpace: 'nowrap',
    marginTop: '8px !important',
    fontSize: 12,
    color: '#9BA9BC',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px !important',
      fontSize: 10
    }
  },
  completed: {
    '&.MuiStepLabel-completed': {
      color: theme.palette.common.black
    }
  }
}));

const CustomConnector = withStyles(theme => ({
  alternativeLabel: {
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 32px)',
    [theme.breakpoints.down('sm')]: {
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)'
    }
  },
  active: {
    '& $line': {
      backgroundColor: '#9BA9BC'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#9BA9BC'
    }
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: '#9BA9BC'
  }
}))(StepConnector);

export default function HeaderStepper({ steps }) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  const handleChangeStep = step => () => {
    if (activeStep === steps.length) return;
    if (step + 1 < activeStep) {
      history.push(steps[step].path);
    }
  };

  useLayoutEffect(() => {
    const index = steps.findIndex(item => pathname === item.path);
    setActiveStep((index < 0 ? 2 : index) + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Stepper
      className={classes.stepper}
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
    >
      {steps.map(({ label }, index) => (
        <Step key={index}>
          <StepButton
            disabled={index !== 0}
            onClick={handleChangeStep(index)}
            style={{ padding: 8, margin: -8 }}
          >
            <StepLabel
              classes={{
                label: classes.label,
                active: classes.active,
                completed: classes.completed
              }}
              StepIconComponent={StepperIcon}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

HeaderStepper.propTypes = {
  steps: array
};

HeaderStepper.defaultProps = {
  steps: []
};
