import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { any, element, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(1.75),
    fontWeight: 400,
    color: theme.palette.common.black
  }
}));

export default function InputLabel({ label, action, children }) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {label && (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.title}>{label}</Typography>
            {action}
          </Grid>
        </Grid>
      )}
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}

InputLabel.propTypes = {
  action: any,
  children: element,
  label: string.isRequired
};

InputLabel.defaultProps = {
  action: null,
  children: null
};
