import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function VNTrackingIconContained(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M21.61 12.75L20.37 13.19C20.25 13.23 20.14 13.25 20.03 13.25C19.62 13.25 19.23 13 19.09 12.59C18.9 12.07 19.17 11.49 19.69 11.31L20.87 10.89C19.35 7.96999 16.43 5.88999 13 5.54999V6.99999C13 7.54999 12.56 7.99999 12 7.99999C11.45 7.99999 11 7.54999 11 6.99999V5.54999C7.57 5.88999 4.64 7.97999 3.13 10.9L4.28 11.31C4.79 11.49 5.07 12.07 4.88 12.59C4.74 13 4.35 13.25 3.94 13.25C3.83 13.25 3.71 13.23 3.6 13.19L2.39 12.76C2.13 13.63 2 14.55 2 15.5V17.25C2 18.21 2.79 19 3.75 19H20.25C21.21 19 22 18.21 22 17.25V15.5C22 14.55 21.87 13.62 21.61 12.75ZM13.47 15.29C13.45 15.39 13.42 15.48 13.38 15.57C13.34 15.66 13.3 15.75 13.24 15.83C13.19 15.92 13.12 15.99 13.06 16.06C12.77 16.34 12.39 16.5 12 16.5C11.6 16.5 11.21 16.34 10.94 16.06C10.87 15.99 10.8 15.92 10.75 15.83C10.69 15.76 10.66 15.67 10.62 15.59L8.67 14.92C8.15 14.74 7.88 14.17 8.05 13.65C8.23 13.12 8.8 12.84 9.33 13.02L11.29 13.69C11.85 13.4 12.6 13.48 13.06 13.94C13.33 14.22 13.5 14.6 13.5 15C13.5 15.1 13.48 15.2 13.47 15.29Z" />
    </SvgIcon>
  );
}
