/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import useActiveAsPath from '../../../hooks/useActiveAsPath';
import CustomMenuItemStartIcon from './CustomMenuItemStartIcon';

export default function CustomMenuItem(props) {
  const { href } = props.routeProps;
  const isActive = useActiveAsPath(href);

  return (
    <CustomMenuItemStartIcon
      {...props}
      isActive={isActive}
      listItemTextProps={{
        style: {
          fontWeight: isActive ? 700 : 'inherit'
        }
      }}
    />
  );
}
