import { Link } from 'react-router-dom';
import DeliveringIcon from '../components/2.2.0/Icons/DeliveringIcon';
import DollarIcon from '../components/2.2.0/Icons/DollarIcon';
import DollarIconContained from '../components/2.2.0/Icons/DollarIconContained';
import ChangePasswordIcon from '../components/2.2.0/Icons/Improve/ChangePasswordIcon';
import CarIconStep from '../components/2.2.0/Icons/OrderTrackingIcon/CartIconStep';
import DoneIcon from '../components/2.2.0/Icons/OrderTrackingIcon/DoneIcon';
import OrderCreatedIcon from '../components/2.2.0/Icons/OrderTrackingIcon/OrderCreatedIcon';
import OrderIcon from '../components/2.2.0/Icons/OrderTrackingIcon/OrderIcon';
import OrderIconContained from '../components/2.2.0/Icons/OrderTrackingIcon/OrderIconContained';
import PackageIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PackageIcon';
import PaidIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PaidIcon';
import PurchasedIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PurchasedIcon';
import SuccessIcon from '../components/2.2.0/Icons/OrderTrackingIcon/SuccessIcon';
import Uswarsehouse from '../components/2.2.0/Icons/OrderTrackingIcon/Uswarsehouse';
import VNTrackingIcon from '../components/2.2.0/Icons/OrderTrackingIcon/VNTrackingIcon';
import VNTrackingIconContained from '../components/2.2.0/Icons/OrderTrackingIcon/VNTrackingIconContained';
import PurchasingIcon from '../components/2.2.0/Icons/PurchasingIcon';
import TransferingIcon from '../components/2.2.0/Icons/TransferingIcon';
import WaitToConfirmIcon from '../components/2.2.0/Icons/WaitToConfirmIcon';
import EmailIcon from '../components/icons/new/EmailIcon';
import AccountIconHover from '../components/icons/new/improvement/animation/account-icon-hover.json';
import BanlanceIconHover from '../components/icons/new/improvement/animation/balance-icon-hover.json';
import DeliveryIconHover from '../components/icons/new/improvement/animation/delivery-icon-hover.json';
import ExtensionIconHover from '../components/icons/new/improvement/animation/extension-icon-hover.json';
import OrderIconHover from '../components/icons/new/improvement/animation/Order-icon-hover.json';
import ReferalIconHover from '../components/icons/new/improvement/animation/referal-icon-hover.json';
import ExtensionIcon from '../components/icons/new/improvement/ExtensionIcon';
import ProfileIcon from '../components/icons/new/improvement/ProfileIcon';
import ProfileIconContained from '../components/icons/new/improvement/ProfileIconContained';
import WalletIcon from '../components/icons/new/improvement/WalletIcon';
import WalletIconContained from '../components/icons/new/improvement/WalletIconContained';
import LockConfirmIcon from '../components/icons/new/LockConfirmIcon';
import LockIcon from '../components/icons/new/LockIcon';
import PersonIcon from '../components/icons/new/PersonIcon';
import PositionIcon from '../components/icons/new/PositionIcon';
import ProfileOutline from '../components/icons/new/ProfileOutline';
import SignOutIcon from '../components/icons/SignOutIcon';
import { ORDER_TRACKING_STATE, Path } from './Constants';
import { Labels } from './Labels';

export const NAV_TABS = [
  {
    label: 'Trang chủ',
    href: Path.HOME,
    component: 'a',
    target: '_self'
  },
  {
    label: 'Mua hộ',
    href: Path.QUOTATION,
    component: Link,
    target: '_self'
  },
  {
    label: 'Ship hộ',
    href: Path.SHIPPING,
    component: 'a',
    target: '_self'
  },
  {
    label: 'Blog',
    href: Path.BLOG,
    component: 'a',
    target: '_self'
  }
];

export const MENUS = [
  {
    label: 'Tài khoản của tôi',
    href: Path.USER_PROFILE,
    component: Link,
    target: '_self',
    Icon: ProfileIcon,
    ActiveIcon: ProfileIconContained,
    animation: AccountIconHover
  },
  {
    label: 'Đơn hàng của tôi',
    href: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`,
    component: Link,
    target: '_self',
    Icon: OrderIcon,
    ActiveIcon: OrderIconContained,
    animation: OrderIconHover
  },
  {
    label: 'Vận chuyển nội địa',
    href: Path.LIST_VN_TRACKING,
    component: Link,
    target: '_self',
    Icon: VNTrackingIcon,
    ActiveIcon: VNTrackingIconContained,
    animation: DeliveryIconHover
  },
  {
    label: 'Số dư của tôi',
    href: Path.USER_DEPOSIT,
    component: 'a',
    target: '_self',
    Icon: WalletIcon,
    ActiveIcon: WalletIconContained,
    animation: BanlanceIconHover
  },
  {
    label: 'Cài đặt Tiện ích BuyNgon',
    href: Path.EXTENSION_INSTALL,
    component: 'a',
    target: '_blank',
    Icon: ExtensionIcon,
    ActiveIcon: ExtensionIcon,
    animation: ExtensionIconHover
  },
  {
    label: 'Giới thiệu bạn bè',
    href: Path.USER_REFERRAL,
    component: Link,
    target: '_self',
    Icon: DollarIcon,
    ActiveIcon: DollarIconContained,
    animation: null
  },
  {
    label: Labels.Exit,
    href: '',
    component: 'li',
    target: '_self',
    Icon: SignOutIcon,
    ActiveIcon: SignOutIcon,
    animation: null
  }
];

export const SIDE_BAR = [
  {
    label: 'Tài khoản của tôi',
    href: Path.USER_PROFILE,
    component: Link,
    target: '_self',
    Icon: ProfileIcon,
    ActiveIcon: ProfileIconContained,
    animation: AccountIconHover,
    children: [
      {
        label: Labels.PersonalInformation,
        href: Path.USER_INFO,
        component: Link,
        target: '_self'
      },
      {
        label: Labels.ChangePassword,
        href: Path.CHANGE_PASSWORD,
        component: Link,
        target: '_self'
      },
      {
        label: Labels.AddressManagement,
        href: Path.USER_ADDRESS,
        component: Link,
        target: '_self'
      },
      {
        label: Labels.Notification,
        href: Path.NOTIFICATION,
        component: Link,
        target: '_self'
      }
    ]
  },
  {
    label: 'Đơn hàng của tôi',
    href: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`,
    component: Link,
    target: '_self',
    Icon: OrderIcon,
    ActiveIcon: OrderIconContained,
    animation: OrderIconHover
  },
  {
    label: 'Vận chuyển nội địa',
    href: Path.LIST_VN_TRACKING,
    component: Link,
    target: '_self',
    Icon: VNTrackingIcon,
    ActiveIcon: VNTrackingIconContained,
    animation: DeliveryIconHover
  },
  {
    label: 'Số dư của tôi',
    href: Path.USER_DEPOSIT,
    component: 'a',
    target: '_self',
    Icon: WalletIcon,
    ActiveIcon: WalletIconContained,
    animation: BanlanceIconHover
  },

  {
    label: 'Giới thiệu bạn bè',
    href: Path.USER_REFERRAL,
    component: Link,
    target: '_self',
    Icon: DollarIcon,
    ActiveIcon: DollarIconContained,
    animation: ReferalIconHover
  },
  {
    label: 'Cài đặt Tiện ích BuyNgon',
    href: Path.EXTENSION_INSTALL,
    component: 'a',
    target: '_blank',
    Icon: ExtensionIcon,
    ActiveIcon: ExtensionIcon,
    animation: ExtensionIconHover
  }
];

export const TABS_ORDER = [
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`,
    label: 'Chờ xác nhận',
    icon: WaitToConfirmIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: ORDER_TRACKING_STATE.DRAFT.value
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/purchasing`,
    label: 'Đang mua',
    icon: PurchasingIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: ORDER_TRACKING_STATE.PURCHASING.value
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/tracking`,
    label: 'Vận chuyển',
    icon: TransferingIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: ORDER_TRACKING_STATE.TRACKING.value
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/delivery`,
    label: 'Đang giao',
    icon: DeliveringIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: ORDER_TRACKING_STATE.DELIVERY.value
  }
];

export const MENU_PROFILE_RES = [
  {
    path: Path.USER_INFO,
    label: Labels.PersonalInformation,
    icon: PersonIcon
  },
  {
    path: Path.CHANGE_PASSWORD,
    label: Labels.ChangePassword,
    icon: ChangePasswordIcon
  },
  {
    path: Path.USER_ADDRESS,
    label: Labels.AddressBook,
    icon: PositionIcon
  },
  // {
  //   path: Path.USER_REFERRAL,
  //   label: Labels.Referral,
  //   icon: DollarIcon
  // },
  {
    path: Path.USER_LOGOUT,
    label: Labels.Exit,
    icon: SignOutIcon
  }
];

export const FORM_SIGN_UP = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Họ và Tên của bạn',
    Icon: ProfileOutline
  },
  // {
  //   name: 'phone',
  //   type: 'text',
  //   placeholder: 'Nhập số điện thoại của bạn*',
  //   Icon: PhoneOutline
  // },
  {
    name: 'email',
    type: 'text',
    placeholder: 'Email của bạn',
    Icon: EmailIcon
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Nhập mật khẩu*',
    Icon: LockIcon
  },
  {
    name: 'password2',
    type: 'password',
    placeholder: 'Nhập lại mật khẩu*',
    Icon: LockConfirmIcon
  }
];

export const JOURNEY_STEP = [
  {
    key: 'created',
    priority: 0,
    label: 'Đã tạo đơn hàng',
    icon: OrderCreatedIcon
  },
  {
    key: 'confirmed',
    priority: 1,
    label: 'Đã thanh toán',
    icon: PaidIcon
  },
  {
    key: 'purchased',
    priority: 2,
    label: 'Đã mua hàng',
    icon: PurchasedIcon
  },
  {
    key: 'arrived_us',
    priority: 3,
    label: 'Đã đến kho nước ngoài',
    icon: Uswarsehouse
  },
  // {
  //   key: '',
  //   priority: 4,
  //   label: 'Đang về VN',
  //   icon: PlaneIcon
  // },
  {
    key: 'clear',
    priority: 4,
    label: 'Đã thông quan',
    icon: DoneIcon
  },
  {
    key: 'buyngon_received',
    priority: 5,
    label: 'Đóng gói',
    icon: PackageIcon
  },
  {
    key: 'vn_shipping',
    priority: 6,
    label: 'Đang giao',
    icon: CarIconStep
  },
  {
    key: 'transferred',
    priority: 7,
    label: 'Hoàn thành',
    icon: SuccessIcon
  }
];
