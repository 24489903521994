import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function WalletIconContained(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M16 9C14.35 9 13 10.35 13 12C13 13.65 14.35 15 16 15H22V9H16ZM17.02 13.25C16.33 13.25 15.76 12.69 15.76 12C15.76 11.31 16.32 10.75 17.01 10.75H17.02C17.71 10.75 18.27 11.31 18.27 12C18.27 12.69 17.71 13.25 17.02 13.25Z" />
      <path d="M22 5.5V7H16C13.24 7 11 9.24 11 12C11 14.76 13.24 17 16 17H22V17.5C22 18.6 21.1 19.5 20 19.5H4C2.9 19.5 2 18.6 2 17.5V5.5C2 4.4 2.9 3.5 4 3.5H20C21.1 3.5 22 4.4 22 5.5Z" />
    </SvgIcon>
  );
}
